import Application from 'lodestar-app/src/Application'
import React from 'react'
import './App.scss'
import AboutPage from './pages/AboutPage'
import ExplorationPage from './pages/ExplorationPage'
import HomePage from './pages/HomePage'
import IntroductionPage from './pages/IntroductionPage'
import QuestionPage from './pages/QuestionPage'

const App = () => {
  return (
    <Application
      appId="beaver"
      extraRouteProps={{
        home: {
          path: '/',
          pageName: <HomePage />,
        },
        about: {
          path: '/about',
          pageName: <AboutPage />,
        },
        exploration: {
          path: '/exploration',
          pageName: <ExplorationPage />,
        },
        Introduction: {
          path: '/introduction',
          pageName: <IntroductionPage />,
        },
        CommonProblem: {
          path: '/question',
          pageName: <QuestionPage />,
        },
      }}
    />
  )
}

export default App
