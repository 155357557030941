import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'
import { SectionTitle } from './index.styled'

const StyledContainer = styled.div`
  overflow-x: hidden;
`
const StyledWrapper = styled.div`
  margin: 0 -1.25rem;

  ${desktopViewMixin(css`
    margin: 0 -1rem;
  `)}
`
const StyledMemberBlock = styled.div`
  margin-bottom: 2rem;
  padding: 0 1rem;
  max-width: 50%;
  text-align: center;

  ${desktopViewMixin(css`
    margin-bottom: 2.5rem;
    padding: 0 1.25rem;
  `)}
`
const StyledAvatar = styled.div<{ avatarUrl?: string }>`
  margin-bottom: 1rem;
  padding-top: 100%;
  width: 160px;
  max-width: 100%;
  background-image: url(${props => props.avatarUrl});
  background-size: cover;
  background-position: center;
`
const StyledTitle = styled.div`
  margin-bottom: 0.5rem;
  color: var(--gray-darker);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
`
const StyledMeta = styled.div`
  color: var(--gray-dark);
  font-size: 14px;
  letter-spacing: 0.4px;
`

const TeamMemberSection: React.FC = () => {
  return (
    <StyledContainer className="container">
      <SectionTitle className="mb-5">
        <div>海貍團隊</div>
        <sub>組織架構</sub>
      </SectionTitle>

      <StyledWrapper className="d-flex align-items-center justify-content-start flex-wrap">
        <StyledMemberBlock>
          <StyledAvatar avatarUrl="https://static.kolable.com/images/beaver/member-1.jpg" />
          <StyledTitle>楊子漠 (大貍)</StyledTitle>
          <StyledMeta>企劃長暨吉祥物</StyledMeta>
        </StyledMemberBlock>
        <StyledMemberBlock>
          <StyledAvatar avatarUrl="https://static.kolable.com/images/beaver/member-3.jpg" />
          <StyledTitle>楊奇峰 (崎貍)</StyledTitle>
          <StyledMeta>專聘講師暨專案祕書</StyledMeta>
        </StyledMemberBlock>
        <StyledMemberBlock>
          <StyledAvatar avatarUrl="https://static.kolable.com/images/beaver/member-5.jpg" />
          <StyledTitle>小豾</StyledTitle>
          <StyledMeta>專案執行暨教學顧問</StyledMeta>
        </StyledMemberBlock>
        <StyledMemberBlock>
          <StyledAvatar avatarUrl="https://static.kolable.com/images/beaver/member-2.jpg" />
          <StyledTitle>小鹿</StyledTitle>
          <StyledMeta>美術總監暨行政支援</StyledMeta>
        </StyledMemberBlock>
        <StyledMemberBlock>
          <StyledAvatar avatarUrl="https://static.kolable.com/images/beaver/member-4.jpg" />
          <StyledTitle>阿莉</StyledTitle>
          <StyledMeta>創意總監暨公關小編</StyledMeta>
        </StyledMemberBlock>
      </StyledWrapper>
    </StyledContainer>
  )
}

export default TeamMemberSection
