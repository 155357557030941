import { throttle } from 'lodash'
import DefaultLayout from 'lodestar-app/src/components/layout/DefaultLayout'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Footer from '../../components/Footer'
import DestinySection from './DestinySection'
import FeedbackSection from './FeedbackSection'
import FootprintSection from './FootprintSection'
import { SectionID, StyledSection } from './index.styled'
import IntroductionSection from './IntroductionSection'
import NavigationBar from './NavigationBar'
import TeamMemberSection from './TeamMemberSection'

const StyledCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  background-image: url('https://static.kolable.com/images/beaver/about-cover.jpg');
  background-size: cover;
  background-position: center;
`

const AboutPage: React.FC = () => {
  const introductionSectionRef = useRef<HTMLDivElement | null>(null)
  const destinySectionRef = useRef<HTMLDivElement | null>(null)
  const footprintSectionRef = useRef<HTMLDivElement | null>(null)
  const teamMemberSectionRef = useRef<HTMLDivElement | null>(null)
  const feedbackSectionRef = useRef<HTMLDivElement | null>(null)
  const [currentSectionId, setCurrentSectionId] = useState<SectionID | null>(null)

  useEffect(() => {
    const contentElem = document.querySelector('#layout-content')
    const handleScrollThrottle = throttle(() => {
      if ((feedbackSectionRef.current?.getBoundingClientRect().top ?? 100) <= 64) {
        currentSectionId !== 'feedback' && setCurrentSectionId('feedback')
      } else if ((teamMemberSectionRef.current?.getBoundingClientRect().top ?? 100) <= 64) {
        currentSectionId !== 'teamMember' && setCurrentSectionId('teamMember')
      } else if ((footprintSectionRef.current?.getBoundingClientRect().top ?? 100) <= 64) {
        currentSectionId !== 'footprint' && setCurrentSectionId('footprint')
      } else if ((destinySectionRef.current?.getBoundingClientRect().top ?? 100) <= 64) {
        currentSectionId !== 'destiny' && setCurrentSectionId('destiny')
      } else if ((introductionSectionRef.current?.getBoundingClientRect().top ?? 100) <= 64) {
        currentSectionId !== 'introduction' && setCurrentSectionId('introduction')
      } else {
        currentSectionId && setCurrentSectionId(null)
      }
    }, 200)
    const handleScroll = () => handleScrollThrottle()
    contentElem?.addEventListener('scroll', handleScroll)

    return () => {
      contentElem?.removeEventListener('scroll', handleScroll)
    }
  }, [currentSectionId])

  const handleScrollTo = (sectionId: SectionID) => {
    switch (sectionId) {
      case 'introduction':
        introductionSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'destiny':
        destinySectionRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'footprint':
        footprintSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'teamMember':
        teamMemberSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'feedback':
        feedbackSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
    }
  }

  return (
    <DefaultLayout white>
      <StyledCover>關於海貍</StyledCover>

      <NavigationBar currentSectionId={currentSectionId} onScrollTo={handleScrollTo} />
      <StyledSection ref={introductionSectionRef}>
        <IntroductionSection />
      </StyledSection>
      <StyledSection ref={destinySectionRef}>
        <DestinySection />
      </StyledSection>
      <StyledSection ref={footprintSectionRef}>
        <FootprintSection />
      </StyledSection>
      <StyledSection ref={teamMemberSectionRef}>
        <TeamMemberSection />
      </StyledSection>
      <StyledSection ref={feedbackSectionRef}>
        <FeedbackSection />
      </StyledSection>
      <Footer />
    </DefaultLayout>
  )
}

export default AboutPage
