import DefaultLayout from 'lodestar-app/src/components/layout/DefaultLayout'
import React, { useState } from 'react'
import styled from 'styled-components'
import { StringParam, useQueryParam } from 'use-query-params'
import Footer from '../../components/Footer'
import ConsultingSection from './ConsultingSection'
import EducatorSection from './EducatorSection'
import EntrepreneurSection from './EntrepreneurSection'
import { SectionID, StyledSection } from './index.styled'
import LearnerSection from './LearnerSection'
import NavigationBar from './NavigationBar'
import WantMoreSection from './WantMoreSection'

const StyledCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  background-image: url('https://static.kolable.com/images/beaver/school-cover.jpg');
  background-size: cover;
  background-position: center;
`
const StyledTitle = styled.p`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.23px;
  line-height: normal;
  text-align: center;
  color: var(--gray-darker);
  margin-bottom: 18px;
`
const ExplorationPage: React.FC = () => {
  const [section] = useQueryParam('section', StringParam)
  const [currentSectionId, setCurrentSectionId] = useState<SectionID>(section as SectionID)

  const handleChange = (sectionId: SectionID) => {
    const url = new URL(window.location.href)
    url.searchParams.delete('section')
    url.searchParams.set('section', sectionId)
    window.history.pushState({}, '', url.toString())
    setCurrentSectionId(sectionId)
  }

  const ChildrenComponent: React.FC = {
    learner: LearnerSection,
    educator: EducatorSection,
    entrepreneur: EntrepreneurSection,
  }[currentSectionId]

  return (
    <DefaultLayout white>
      <StyledCover>服務總覽</StyledCover>
      <StyledSection>
        <div className="container">
          <p>
            　　為了支持教育轉型，貍們提供給學習者、教育工作者與教育創業者各式課程，無論需要講座、工作坊、入校研習、共備協助、線上課程，無論在學期間、寒假期或是特殊營隊，貍們都能因應需求為您打造理想的課表，給您最優質的增能課程。
          </p>
        </div>
      </StyledSection>
      <StyledTitle>我是⋯⋯</StyledTitle>
      <NavigationBar currentSectionId={currentSectionId} onChange={handleChange} />
      <ChildrenComponent />
      <ConsultingSection />
      <WantMoreSection currentSectionId={currentSectionId} />
      <Footer />
    </DefaultLayout>
  )
}

export default ExplorationPage
