import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import BannerLogo from '../../images/banner-logo.svg'

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  height: 576px;
  &:before {
    display: block;
    position: absolute;
    top: 0px;
    content: '';
    width: 100%;
    height: 288px;
    background-color: #f29844;
  }
  &:after {
    display: block;
    position: absolute;
    top: 289px;
    content: '';
    width: 100%;
    height: 288px;
    background-color: #d8d8d9;
  }
`
const PolygonBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  clip-path: polygon(50% 5%, 100% 40%, 100% 65%, 50% 95%, 0% 65%, 0% 40%);
  z-index: 2;
`
const ContentBlock = styled.div`
  width: 100%;
  text-align: center;
  line-height: 50%;
  z-index: 2;
`
const StyledBannerLogo = styled.img`
  margin: 0 auto 1.5rem;
`
const StyledSubTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #585858;
  line-height: normal;
  letter-spacing: 0.2px;
`
const StyledButton = styled(Button)`
  width: 11rem;
  span {
    font-weight: 500;
  }
`

const CoverSection: React.FC = () => {
  return (
    <StyledSection className="d-flex align-items-center justify-content-center">
      <PolygonBackground></PolygonBackground>
      <ContentBlock>
        <StyledBannerLogo src={BannerLogo} alt="banner-logo" />
        <StyledSubTitle className="mb-4">未來教育先行者</StyledSubTitle>
        <Link to="/about">
          <StyledButton type="primary">關於海貍</StyledButton>
        </Link>
      </ContentBlock>
    </StyledSection>
  )
}

export default CoverSection
