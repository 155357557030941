import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SectionID, StyledSection, StyledSubtitle } from './index.styled'

const StyledMoreSection = styled(StyledSection)`
  text-align: center;
  padding: 1rem;
  margin-bottom: 80px;
`
const StyledMoreContent = styled.p`
  margin-bottom: 0 !important;
`
const StyledButton = styled(props => <Button {...props} />)`
  && {
    margin-top: 24px;
    width: 177px;
    height: 44px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #2c7eae;
    color: white;
    font-weight: 400;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &:hover {
      background-color: #2c7eae;
      color: white;
    }
  }
`
const WantMoreSection: React.VFC<{ currentSectionId?: SectionID | null }> = ({ currentSectionId }) => {
  return (
    <StyledMoreSection>
      <StyledSubtitle>想要更多</StyledSubtitle>
      {(currentSectionId === 'learner' || currentSectionId === 'educator') && (
        <>
          <StyledMoreContent>如果想搶在學校邀約之前先行體驗</StyledMoreContent>
          <StyledMoreContent>歡迎您一同參與最近的課程活動！</StyledMoreContent>
          <StyledMoreContent>點擊看更多貍想課程消息！</StyledMoreContent>
          <Link to="/programs">
            <StyledButton type="primary">追蹤現有課程</StyledButton>
          </Link>
        </>
      )}
      {currentSectionId === 'entrepreneur' && (
        <>
          <StyledMoreContent>如果以上都無法滿足您永無止盡的求知欲</StyledMoreContent>
          <StyledMoreContent>歡迎加入貍想教育創新學院，跟貍們一起打造屬於您的學習之旅。</StyledMoreContent>
          <StyledMoreContent>點擊看更多學院精彩內容！</StyledMoreContent>
          <Link to="/introduction">
            <StyledButton type="primary">追蹤現有課程</StyledButton>
          </Link>
        </>
      )}
    </StyledMoreSection>
  )
}

export default WantMoreSection
