import { desktopViewMixin } from 'lodestar-app/src/helpers'
import styled, { css } from 'styled-components'
export type SectionID = 'learner' | 'educator' | 'entrepreneur'

export const StyledSection = styled.section`
  padding: 2.5rem 0;
  .container {
    max-width: 38.75rem;
  }
  p {
    color: var(--gray-darker);
    margin-bottom: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`

export const SectionTitle = styled.div`
  margin-bottom: 1.5rem;
  padding-left: 0.75rem;
  border-left: 4px solid #2c7eae;
  color: var(--gray-darker);

  div {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.23px;
    line-height: normal;
  }
  sub {
    color: var(--gray-dark);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: var(--gray-dark);
  }
`

export const StyledSubtitle = styled.p`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: var(--gray-darker);
  margin-bottom: 24px;
`

export const StyledLabel = styled.div`
  text-align: center;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 4px;
  color: 'var(--gray-darker)'
  background-color: 'var(--gray)'
  font-size: 18px;
  font-weight: bold;
`

export const StyledContentBlock = styled.div`
  margin-bottom: 32px;
  ${desktopViewMixin(css`
    margin-bottom: 40px;
  `)}
`

export const StyledContentNote = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: var(--gray-dark);
  margin-top: 12px;
`
