import { Icon } from '@chakra-ui/icons'
import { Box, Button } from '@chakra-ui/react'
import { BREAK_POINT } from 'lodestar-app/src/components/common/Responsive'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as quoteIcon } from '../../images/quote.svg'

const StyledSection = styled.div`
  min-width: 320px;
  padding-top: 65px;
  text-align: center;
  background: #ffffff;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    background-image: url(https://static.kolable.com/images/beaver/introduction-beaver-1.jpg?t=20210126);
    background-size: cover;
    width: 160px;
    height: 160px;
    bottom: 0;
    left: 18px;
    @media (min-width: 1440px) {
      left: 30%;
    }
    @media (min-width: ${BREAK_POINT}px) and (max-width: 1439px) {
      left: 25%;
    }
    @media (max-width: 360px) {
      left: 0;
    }
  }
  ::after {
    content: '';
    position: absolute;
    background-image: url(https://static.kolable.com/images/beaver/introduction-beaver-2.jpg?t=20210126);
    background-size: cover;
    width: 160px;
    height: 160px;
    bottom: 0;
    right: 18px;
    @media (min-width: 1440px) {
      right: 30%;
    }
    @media (min-width: ${BREAK_POINT}px) and (max-width: 1439px) {
      right: 25%;
    }
    @media (max-width: 360px) {
      right: 0;
    }
  }
`
const StyledSectionTitle = styled(Box)`
  font-size: 18px;
  letter-spacing: 0.8px;
  font-weight: bold;
  padding: 24px 16px;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  width: 342px;

  @media (min-width: ${BREAK_POINT}px) {
    padding: 24px 75px;
    width: 460px;
  }
  @media (max-width: 320px) {
    width: 90%;
    font-size: 16px;
  }
`
const StyledTitleIcon = styled(Icon)`
  position: absolute;
  top: -9px;
  right: 9px;
  @media (min-width: ${BREAK_POINT}px) {
    right: 12px;
  }
`
const StyledSectionContent = styled.div`
  text-align: center;
  margin: 40px 0;
`

const StyledButton = styled(Button)`
  && {
    font-weight: 500;
    padding: 10px 56px;
    margin-bottom: 200px;
    @media (min-width: ${BREAK_POINT}px) {
      margin-bottom: 80px;
    }
  }
`

const IntroductionSection: React.FC = () => {
  return (
    <StyledSection>
      <StyledSectionTitle className="text-center" color="primary.500" bg="primary.100">
        <div>貍想教育創新學院</div>
        <div>— 臺灣首創線上教育共學生態社群 —</div>
        <StyledTitleIcon as={quoteIcon} />
      </StyledSectionTitle>

      <StyledSectionContent>
        <p>
          提供終身學習者探索新知的無盡之境，
          <br />
          在安全的線上場域進行自我增能，
          <br />
          各類跨域創新的線上活動、
          <br />
          多元豐富的知識共學共創、
          <br />
          一年兩次的實體線下窩聚，
          <br />
          每年&nbsp;
          <Box as="span" color="primary.500">
            2月
          </Box>
          、
          <Box as="span" color="primary.500">
            8月&nbsp;
          </Box>
          持續招募新夥伴，
          <br />
          遊戲機制等貍解鎖學習成就！
        </p>
      </StyledSectionContent>

      <Box as="p" className="mb-4 mt-4" color="primary.500">
        點擊看更多學院精彩內容
      </Box>

      <a
        href="https://sites.google.com/linux.ttjhs.chc.edu.tw/acrossbeavers/%E5%AD%B8%E9%99%A2%E4%BB%8B%E7%B4%B9"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledButton variant="primary">了解更多</StyledButton>
      </a>
    </StyledSection>
  )
}

export default IntroductionSection
