import React from 'react'
import { SectionTitle } from './index.styled'

const IntroductionSection: React.FC = () => {
  return (
    <div className="container">
      <SectionTitle>
        <div>海貍之名</div>
        <sub>源起＆願景</sub>
      </SectionTitle>

      <p>
        不管在哪個時代，「教育」一直都是重要的課題，因為環境的變化永遠先於教育的轉型，資訊爆炸的世代更是如此，教育轉型的腳步跟不上快速變動的社會。因此，即便人們都渴望往理想的教育前進，現在仍有三大教育問題：
      </p>
      <p className="ml-3">
        一、既有的學科內容無法滿足社會需求
        <br />
        二、現有的師資養成系統未能支應現場
        <br />
        三、好的教育政策未能真實地落地實踐
      </p>

      <p>簡而言之，我們其實無法期待上個世紀的教材教法能帶給下一代擁有面對未來的素養。</p>
      <p>
        在教育轉型「以學習者為中心」的浪潮中，我們將教師也視為需要被支持的對象，為現場教師提供「再增能」，希望不只在體制外翻滾，也能推動體制內轉型。相信改變一個教師，可以改變無數學生，進而共創未來教育的新型態。
      </p>
      <p>
        翻滾海貍工作室，以海貍為名，是由於「海貍」是一種擅長因應環境、共築居所的生物，我們希望自己能像海貍一樣，作為教育工作者溫柔的後盾，打造一個適宜共學的場域，讓學生從文學中學會覺察自我，理解他人；讓教師在專業上持續增能，獲得教學成就；陪伴每一個願意學習的人，找到屬於自己的翻滾姿態，共同推動社會前進。
      </p>
    </div>
  )
}

export default IntroductionSection
