import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'
import chart1 from '../../images/chart-01.svg'
import chart2 from '../../images/chart-02.svg'
import chart3 from '../../images/chart-03.svg'
import chart from '../../images/chart.svg'
import { SectionTitle } from './index.styled'

const StyledContainer = styled.div`
  position: relative;
  ${desktopViewMixin(css`
    &::before,
    &::after {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      content: ' ';
    }
    &::before {
      top: 0;
      right: 0;
      width: 129px;
      height: 178px;
      background-image: url(https://static.kolable.com/images/beaver/beaver-06.png);
      transform: translate(100%, -60%);
    }
    &::after {
      bottom: 0;
      left: 0;
      width: 178px;
      height: 178px;
      background-image: url(https://static.kolable.com/images/beaver/beaver-07.png);
      transform: translate(-100%, 60%);
    }
  `)}
`
const StyledWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 330px;
  max-width: 100%;
  height: 280px;
`
const ChartComponentMixin = css`
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`
const ChartTriangle = styled.div`
  ${ChartComponentMixin}
  top: 50%;
  left: 50%;
  width: 217.4px;
  height: 168.2px;
  background-image: url(${chart});
  transform: translate(-50%, -50%);
`
const Chart1 = styled.div`
  ${ChartComponentMixin}
  top: 0;
  left: 50%;
  width: 112px;
  height: 112px;
  background-image: url(${chart1});
  transform: translateX(-50%);
`
const Chart2 = styled.div`
  ${ChartComponentMixin}
  left: 0;
  bottom: 6px;
  width: 112px;
  height: 112px;
  background-image: url(${chart2});
`
const Chart3 = styled.div`
  ${ChartComponentMixin}
  right: 0;
  bottom: 6px;
  width: 112px;
  height: 112px;
  background-image: url(${chart3});
`
const StyledPopoverContent = styled(PopoverContent)`
  &&,
  &&:focus {
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
`
const StyledDivider = styled.div`
  width: 2px;
  height: 60px;
  margin: 2.5rem auto;
  background-color: #2c7eae;
`
const StyledTitle = styled.div`
  margin-bottom: 2rem;
  color: var(--gray-darker);
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.23px;
  text-align: center;
`
const StyledBlock = styled.div`
  background: linear-gradient(to bottom, transparent 25%, var(--gray-lighter) 25%);
  overflow: hidden;
  padding-bottom: 5rem;

  .container {
    max-width: 960px;
    ${desktopViewMixin(css`
      position: relative;
      ::after {
        position: absolute;
        bottom: -5rem;
        right: 0;
        width: 169px;
        height: 169px;
        background-image: url(https://static.kolable.com/images/beaver/beaver-08.png);
        background-size: contain;
        background-repeat: no-repeat;
        transform: translateX(100%);
        content: ' ';
      }
    `)}
  }
`
const CardCover = styled.div<{ coverUrl?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 2rem 0;
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  background-image: url(${props => props.coverUrl});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
`
const CardNumber = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 2rem;
`
const CardContent = styled.div`
  margin-bottom: 2.5rem;
  color: var(--gray-darker);
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: center;
`

const DestinySection: React.FC = () => {
  return (
    <>
      <StyledContainer className="container">
        <SectionTitle>
          <div>海貍使命</div>
          <sub>架構&amp;特色</sub>
        </SectionTitle>

        <p>
          作為教育工作者溫柔的後盾，翻滾海貍從文學、教學、共學三面向提供課程，致力於開拓教學想像、進行教學設計、推動教學實踐，從而推動教育轉型。
        </p>

        <StyledWrapper>
          <ChartTriangle />
          <Popover placement="top">
            <PopoverTrigger>
              <Chart1 className="cursor-pointer" />
            </PopoverTrigger>
            <StyledPopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <ul className="pl-4">
                  <li>未來教育工作坊</li>
                  <li>老派教育工作坊</li>
                  <li>怎樣教育工作坊</li>
                  <li>非關教育工作坊</li>
                  <li>青色組織工作坊</li>
                </ul>
              </PopoverBody>
            </StyledPopoverContent>
          </Popover>
          <Popover placement="top">
            <PopoverTrigger>
              <Chart2 className="cursor-pointer" />
            </PopoverTrigger>
            <StyledPopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <ul className="pl-4">
                  <li>河道式作文</li>
                  <li>築壩式閱讀</li>
                  <li>造橋式表達</li>
                  <li>竹節式評量</li>
                  <li>樹冠式思辨</li>
                </ul>
              </PopoverBody>
            </StyledPopoverContent>
          </Popover>
          <Popover placement="top">
            <PopoverTrigger>
              <Chart3 className="cursor-pointer" />
            </PopoverTrigger>
            <StyledPopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <ul className="pl-4">
                  <li>課堂型牌卡：海貍戰隊</li>
                  <li>創作型牌卡：尾巴巴呢</li>
                  <li>課堂輔具：方石籌碼板</li>
                  <li>情境推理：誰殺了海貍</li>
                  <li>特色教案：《貍想誌》</li>
                </ul>
              </PopoverBody>
            </StyledPopoverContent>
          </Popover>
        </StyledWrapper>
      </StyledContainer>

      <StyledDivider />

      <StyledBlock>
        <div className="container">
          <StyledTitle>我們的獨特創新之處</StyledTitle>
          <div className="row">
            <div className="col-12 col-lg-4">
              <CardCover coverUrl="https://static.kolable.com/images/beaver/feature-1.jpg">
                <div>
                  <CardNumber>01</CardNumber>
                  <div>推進教育範式</div>
                  <div>而非推翻既有體制</div>
                </div>
              </CardCover>
              <CardContent>
                在推動教育轉型過程，不撕裂原有教師的成就感，也不求在體制外另立門戶，希望陪伴教師就地「再師培」，從教師熟悉的教學輔具開始，漸進地帶入「思維教育」的理念，不僅在文學及教學專業上提供成長，更大力推播共學方法，推動共學社群，減少老師單打獨鬥、獨自摸索的時間。
              </CardContent>
            </div>
            <div className="col-12 col-lg-4">
              <CardCover coverUrl="https://static.kolable.com/images/beaver/feature-2.jpg">
                <div>
                  <CardNumber>02</CardNumber>
                  <div>完整思維體系</div>
                  <div>有效推進教育轉型</div>
                </div>
              </CardCover>
              <CardContent>
                透過使用系統化的教材，使現場教師能建立完整的教學脈絡，提升學生學習效率，增進教師專業知能。賦予教師設計課程的能力，協助建構自身教學系統，並給予實際回饋。以思維教育為目的，讓教師釐清「教什麼」、「如何教」、「為何而教」，審視自身教育價值及定位，增強教學自信。
              </CardContent>
            </div>
            <div className="col-12 col-lg-4">
              <CardCover coverUrl="https://static.kolable.com/images/beaver/feature-3.jpg">
                <div>
                  <CardNumber>03</CardNumber>
                  <div>組建教育生態社群</div>
                  <div>持續擴散影響力</div>
                </div>
              </CardCover>
              <CardContent>
                除了設計優良產品，翻滾海貍還發展共學社群，直接為教育工作者賦能，使之成為教育創變者。秉持「用戶即夥伴」的理念，支持用戶加入開發行列，在學習歷程中，將真實需求催生為產品，資本回流到用戶及社群上，再度促發更多學習。每位夥伴在地創造改變，同時讓社群不斷分形擴大，達到永續扶持的目標。
              </CardContent>
            </div>
          </div>
        </div>
      </StyledBlock>
    </>
  )
}

export default DestinySection
