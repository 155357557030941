import DefaultLayout from 'lodestar-app/src/components/layout/DefaultLayout'
import React from 'react'
import styled from 'styled-components'
import Footer from '../../components/Footer'
import IntroductionSection from './IntroductionSection'

const StyledCover = styled.div`
  min-height: 266px;
  background-image: url(https://static.kolable.com/images/beaver/introduction-cover.png);
  background-size: cover;
  background-position: center;
`
const IntroductionPage: React.FC = () => {
  return (
    <DefaultLayout>
      <StyledCover />
      <IntroductionSection />
      <Footer />
    </DefaultLayout>
  )
}

export default IntroductionPage
