const footprint: { [year: string]: string[] } = {
  2015: [
    '台中,沙鹿國中,2015.04.08,課堂遊戲化．桌遊融入國文教學',
    '台中,西苑國中,2015.04.15,活動課程中的讀寫結合教學',
    '台北,三峽國中,2015.04.18,卡片運用入班教學',
    '桃園,新屋國中,2015.04.19,國文桌遊研習',
    '屏東,屏東高中,2015.04.25,遊戲活動在聽說讀寫課程的運用',
    '桃園,清溪國中,2015.04.26,國文科教學示範',
    '台中,台中,2015.04.29,讀寫結合課程',
    '嘉義,協同中學,2015.09.24,課堂遊戲化',
    '嘉義,協同中學,2015.10.22,課堂卡牌實作',
    '台北,臺灣師範大學,2015.10.24,海貍教室與遊戲教育展望',
    '新竹,新竹高中,2015.10.29,觀課',
    '台中,台中輔導團,2015.10.30,教師表達力',
    '嘉義,協同中學,2015.10.31,河道作文Ｘ寫作導論',
    '新竹,新科國中,2015.11.06,創意國文教學',
    '嘉義,協同中學,2015.11.19,寫作教學概論',
    '台中,台中輔導團,2015.11.20,教師表達力',
    '台中,萬和國中,2015.11.20,觀課',
    '桃園,龍潭國中,2015.12.01,卡牌運用入班教學',
    '高雄,三民國中,2015.12.07,創意國文教學',
    '高雄,前鎮高中,2015.12.07,卡牌運用入班教學',
    '台北,內湖高中,2015.12.09,卡牌運用入班教學',
    '新竹,清華大學,2015.12.15,海貍教室與遊戲教育展望',
    '新竹,新竹科國,2015.12.15,卡牌運用入班教學',
    '嘉義,協同中學,2015.12.17,四色思考於作文應用',
    '台中,豐東國中,2015.12.18,卡牌運用入班教學暨增能分享',
    '台中,大業國中,2015.12.23,卡牌運用入班教學',
    '台中,大墩國中,2015.12.24,卡牌運用入班教學',
    '彰化,文學高中,2015.12.25,卡牌運用入班教學',
  ],
  2016: [
    '新北,福和國中,2016.03.04,卡片運用入班教學',
    '嘉義,協同中學,2016.03.13,河道作文Ｘ寫作導論',
    '嘉義,協同中學,2016.03.17,河道作文Ｘ寫作導論',
    '新北,莊敬高職,2016.03.23,遊戲活動在聽說讀寫課程的運用',
    '台中,豐東國中,2016.03.25,河道式作文教室',
    '嘉義,協同中學,2016.09.02,說話課',
    '嘉義,協同中學,2016.09.09,說話課',
    '嘉義,協同中學,2016.09.10,說話課',
    '嘉義,協同中學,2016.09.23,說話課',
    '台中,豐東國中,2016.09.24,自信表達力系列',
    '台南,台南女中,2016.09.28,寫作導論',
    '台北,大同國中,2016.09.29,一款桌遊．百搭教學',
    '台中,西苑,2016.09.30,任務型教案提問設計與實作',
    '嘉義,協同中學,2016.10.01,應試對策',
    '台南,台南女中,2016.10.05,河道．立意',
    '台中,西苑,2016.10.07,社群研習－實作（一）',
    '台中,豐東國中,2016.10.15,自信表達力系列',
    '高雄,陽明國中,2016.10.17,思維建構示例與實作',
    '新竹,尖山國中,2016.10.18,入班演示（麥帥）',
    '嘉義,協同中學,2016.10.22,河道寫作．短文拆解',
    '桃園,大成國中,2016.10.25,河道作文．立意思考',
    '屏東,屏東高中,2016.10.26,河道作文．寫作導論',
    '台中,西苑,2016.10.28,思維建構．白話韻文',
    '桃園,福豐國中,2016.11.01,教師表達力',
    '台南,台南女中,2016.11.02,遊戲',
    '台南,台南女中,2016.11.05,河道作文．文學應用',
    '台北,台科大,2016.11.08,課堂遊戲化與教育展望',
    '台南,台南女中,2016.11.09,河道作文．寫作導論',
    '台南,民德國中,2016.11.11,一款桌遊，百搭教學',
    '桃園,東興國中,2016.11.15,海貍戰隊Ｘ思維建構',
    '台南,台南女中,2016.11.16,遊戲',
    '台北,大直中學,2016.11.17,一款桌遊，百搭教學',
    '彰化,陽明國中,2016.11.18,一款桌遊，百搭教學',
    '嘉義,協同中學,2016.11.19,河道作文．組織結構',
    '新竹,尖山國中,2016.11.22,思維建構．任務設計',
    '花蓮,花蓮高工,2016.11.23,河道作文．寫作導論',
    '台北,北安國中,2016.11.24,海貍戰隊Ｘ思維建構',
    '宜蘭,東光國中,2016.11.25,教師表達力',
    '台中,亞洲大學,2016.11.26,獸足偵探．誰殺了海貍？',
    '台南,台南女中,2016.11.29,國文遊戲．設計應用',
    '台南,台南女中,2016.11.30,遊戲',
    '宜蘭,中華國中,2016.11.30,聲語魅力表達',
    '台北,台師大,2016.12.02,國文遊戲．設計應用',
    '嘉義,協同中學,2016.12.02,從門外漢到總編輯',
    '台南,大灣中學,2016.12.02,課堂桌遊．海貍戰隊',
    '嘉義,協同中學,2016.12.03,河道作文．實戰演練',
    '新竹,湖口高中-國中部,2016.12.05,一款桌遊，百搭教學',
    '台北,台師大,2016.12.06,河道作文．應用書寫',
    '新北,福營國中,2016.12.06,海貍戰隊Ｘ思維建構(對師實作)',
    '宜蘭,羅東國中,2016.12.07,海貍戰隊Ｘ思維建構(入班演示)',
    '台北,明湖國中,2016.12.08,海貍戰隊Ｘ思維建構(對師實作)',
    '新北,深坑國中,2016.12.09,一款桌遊，百搭教學',
    '台北,內湖高中,2016.12.12,演示',
    '新北,三多國中,2016.12.13,海貍戰隊．思維建構',
    '新北,三芝國中,2016.12.13,化繁為簡的簡報心法',
    '新北,清水國中,2016.12.13,海貍戰隊．思維建構',
    '新北,江翠國中,2016.12.13,一款桌遊，百搭教學',
    '台南,台南女中,2016.12.14,河道作文．文學應用',
    '台中,西苑,2016.12.16,思維建構．古典小說',
    '花蓮,慈濟高中,2016.12.17,核心古文．思辨融合',
    '屏東,屏東高中,2016.12.19,應用寫作',
    '桃園,龜山國中,2016.12.20,河道作文．寫作導論',
    '台南,台南女中,2016.12.21,遊戲',
    '台北,長安國中,2016.12.22,一款桌遊，百搭教學',
    '台北,台大,2016.12.23,課堂遊戲化與教育展望',
    '新竹,實驗中學,2016.12.23,海貍戰隊Ｘ思維建構(對師實作)',
    '花蓮,國風國中,2016.12.27,海貍戰隊．思維建構(對生實作)',
    '新北,崇光女中,2016.12.27,聲語魅力表達',
    '台南,台南女中,2016.12.28,遊戲',
    '台南,台南女中,2016.12.29,面試備審',
  ],
  2017: [
    '新北,深坑國中,2017.01.03,河道作文．寫作導論',
    '桃園,成功工商,2017.01.04,遊戲活動在國文課堂的運用',
    '台中,沙鹿國中,2017.01.06,實用表達活動主持',
    '嘉義,協同中學,2017.01.07,河道作文．成果驗收',
    '新北,石碇國中,2017.01.10,海貍戰隊Ｘ思維建構',
    '台南,台南女中,2017.01.11,遊戲',
    '台北,松山高中,2017.01.17,河道作文．寫作導論',
    '宜蘭,東光國中,2017.01.20,河道作文．立意',
    '台中,豐東國中,2017.01.23,文學營收．自信表達',
    '花蓮,慈濟高中,2017.02.13,頂尖備審企劃力',
    '台中,亞洲大學,2017.02.17,病梅館記',
    '新北,林口高中,2017.02.17,決戰面試表達力',
    '新北,尖山國中,2017.02.21,文本共備．文言散文',
    '彰化,彰藝高國中部,2017.02.24,海貍戰隊Ｘ思維建構',
    '台南,和順國中,2017.02.24,河道作文・寫作導論',
    '台北,民權國中,2017.03.02,海貍戰隊Ｘ思維建構',
    '台中,康軒中部大研習,2017.03.04,海貍戰隊Ｘ思維建構',
    '嘉義,協同中學,2017.03.04,決戰面試表達力',
    '桃園,龍岡國中,2017.03.07,海貍戰隊Ｘ思維建構',
    '新北,崇光女中,2017.03.07,教師聲語魅力表達',
    '台北,景興國中,2017.03.09,海貍戰隊Ｘ思維建構',
    '台北,中崙國中,2017.03.09,一款桌遊Ｘ百搭教學',
    '台北,大理國中,2017.03.09,海貍戰隊Ｘ思維建構',
    '花蓮,慈濟高中,2017.03.09,海貍戰隊Ｘ思維建構',
    '彰化,彰泰國中,2017.03.10,一款桌遊Ｘ百搭教學',
    '高雄,康軒南部大研習,2017.03.11,海貍戰隊Ｘ思維建構',
    '嘉義,新港國中,2017.03.13,一款桌遊Ｘ百搭教學',
    '新北,永和國中,2017.03.14,海貍戰隊Ｘ思維建構',
    '新北,竹圍國中,2017.03.14,海貍戰隊Ｘ思維建構',
    '新北,崇光女中,2017.03.14,教師聲語魅力表達',
    '台南,台南女中,2017.03.15,決戰面試表達力',
    '南投,中興國中,2017.03.15,海貍戰隊Ｘ思維建構',
    '台北,興雅國中,2017.03.16,河道作文．寫作導論',
    '台中,神岡國中,2017.03.17,河道作文．寫作導論',
    '台中,西苑中學,2017.03.17,河道作文．寫作導論',
    '新竹,建功高中國中部,2017.03.17,海貍戰隊Ｘ思維建構',
    '台南,善化國中,2017.03.17,一款桌遊Ｘ百搭教學',
    '高雄,三民國中,2017.03.20,海貍戰隊Ｘ思維建構',
    '新北,福和國中,2017.03.21,海貍戰隊Ｘ思維建構',
    '新北,樹林高中國中部,2017.03.21,海貍戰隊Ｘ思維建構',
    '台北,敦化國中,2017.03.23,河道作文Ｘ寫作導論',
    '台北,南港國中,2017.03.23,河道作文Ｘ寫作導論',
    '台南,崇明國中,2017.03.24,一款桌遊Ｘ百搭教學',
    '基隆,武崙國中,2017.03.27,河道作文Ｘ寫作導論',
    '新北,尖山國中,2017.03.28,文本共備',
    '花蓮,四維高中,2017.03.30,海貍戰隊Ｘ思維建構',
    '彰化,大同國中,2017.03.31,海貍戰隊Ｘ思維建構',
    '台中,西苑中學,2017.04.07,河道作文．立意',
    '台南,佳里國中,2017.04.07,開啟寫作故事力',
    '高雄,岡山國中,2017.04.10,未來思辨．真相不只一個',
    '高雄,國昌國中,2017.04.10,海貍戰隊Ｘ思維建構',
    '花蓮,美崙國中,2017.04.10,一款桌遊Ｘ百搭教學',
    '新北,三民高中,2017.04.13,河道作文Ｘ寫作導論',
    '嘉義,協同中學,2017.04.14,思辨入班',
    '台南,康軒南部大研習,2017.04.15,海貍戰隊Ｘ思維建構',
    '屏東,明正國中,2017.04.17,河道作文．寫作導論',
    '彰化,彰安國中,2017.04.18,思維建構學習單',
    '新北,正德國中,2017.04.18,一款桌遊Ｘ百搭教學',
    '台東,新生國中,2017.04.18,海貍戰隊Ｘ思維建構',
    '花蓮,慈濟高中,2017.04.20,海貍戰隊Ｘ思維建構',
    '花蓮,慈濟大學,2017.04.20,河道式作文（師培生）',
    '台中,居仁國中,2017.04.21,海貍戰隊Ｘ思維建構',
    '台中,光德國中,2017.04.21,教師聲語魅力',
    '台北,台大師培,2017.04.21,教師表達力',
    '高雄,中庄國中,2017.04.24,海貍戰隊Ｘ思維建構',
    '新北,碧華國中,2017.04.25,一款桌遊Ｘ百搭教學',
    '新北,新埔國中,2017.04.25,海貍戰隊Ｘ思維建構',
    '新北,中正國中,2017.04.25,海貍戰隊Ｘ思維建構',
    '宜蘭,宜蘭高商,2017.04.26,河道作文・寫作導論',
    '新竹,成德高中,2017.04.27,虛張聲勢是自信的開始',
    '嘉義,協同中學,2017.04.28,思辨入班',
    '彰化,精誠國中,2017.05.01,海貍戰隊Ｘ思維建構',
    '高雄,福山國中,2017.05.02,未來思辨．你憤怒誰的正義',
    '嘉義,嘉義高中,2017.05.02,河道作文・寫作導論',
    '宜蘭,南澳中學,2017.05.02,思辨・你有蹺課的義務(進階）',
    '屏東,恆春工商,2017.05.03,海貍戰隊Ｘ思維建構',
    '台北,東湖國中,2017.05.04,河道作文．寫作導論',
    '新北,丹鳳高中,2017.05.04,河道作文．寫作導論',
    '台中,沙鹿國中,2017.05.05,獸足偵探',
    '基隆,暖暖教師研習中心,2017.05.08,海貍戰隊Ｘ思維建構',
    '台東,東海國中,2017.05.09,海貍戰隊Ｘ思維建構',
    '台北,古亭國中,2017.05.11,河道作文．寫作導論',
    '台南,北門農工,2017.05.15,一款桌遊Ｘ百搭教學',
    '屏東,潮州高中,2017.05.15,河道作文・寫作導論',
    '南投,南投高中,2017.05.16,海貍戰隊Ｘ思維建構',
    '台北,誠正國中,2017.05.18,教師聲語魅力',
    '彰化,陽明國中,2017.05.19,共備經驗分享',
    '台南,台南女中,2017.05.22,河道作文・寫作導論',
    '桃園,自強國中,2017.05.23,海貍戰隊Ｘ思維建構',
    '新北,深坑國中,2017.05.23,文本共備．白話散文',
    '台北,桃源國中,2017.05.25,一款桌遊Ｘ百搭教學',
    '台北,大安高工,2017.05.25,教師聲語魅力',
    '新北,聖心女中,2017.05.25,海貍戰隊Ｘ思維建構',
    '桃園,育達高中,2017.05.26,河道作文・寫作導論',
    '花蓮,花蓮高工,2017.05.31,海貍戰隊Ｘ思維建構',
    '台北,松山高中,2017.06.01,河道作文Ｘ立意',
    '台北,龍山國中,2017.06.02,寫作遊戲——尾巴巴呢',
    '高雄,正義高中,2017.06.02,海貍戰隊Ｘ思維建構',
    '高雄,岡山高中,2017.06.05,河道作文・寫作導論',
    '高雄,道明中學,2017.06.05,海貍戰隊Ｘ思維建構',
    '桃園,龜山國中,2017.06.06,教師表達力',
    '新北,汐止國中,2017.06.06,河道作文．寫作導論',
    '宜蘭,羅東高工,2017.06.07,河道作文・寫作導論',
    '台中,亞洲大學,2017.06.08,自信表達',
    '台中,大雅國中,2017.06.09,河道作文．寫作導論',
    '高雄,後勁國中,2017.06.12,河道作文．寫作導論',
    '台中,新民高中,2017.06.13,海貍戰隊Ｘ思維建構',
    '彰化,彰安國中,2017.06.13,寫作遊戲——尾巴巴呢',
    '花蓮,慈濟高中,2017.06.15,海貍戰隊Ｘ思維建構',
    '台中,西苑中學,2017.06.16,河道作文共備',
    '南投,埔里高工,2017.06.16,獸足偵探',
    '新北,深坑國中,2017.06.20,文本共備．文言散文',
    '新北,北大高中國中部,2017.06.22,海貍戰隊Ｘ思維建構',
    '嘉義,協同中學,2017.06.23,思辨入班',
    '彰化,社頭國中,2017.06.23,海貍戰隊Ｘ思維建構',
    '台北,松山高中,2017.06.29,河道作文Ｘ文采',
    '彰化,大同國中,2017.07.14,寫作遊戲—尾巴巴呢',
    '台中,教研中心,2017.07.27,虛張聲勢是自信的開始',
    '台中,教研中心,2017.07.27,開始故事的洪荒之力',
    '台中,教研中心,2017.07.28,聲語魅力自信表達',
    '台中,教研中心,2017.07.28,演說品牌是自信的完成',
    '高雄,陽明國中,2017.08.23,「翻滾吧！作文！」(立意篇)',
    '台南,瀛海中學,2017.08.25,PPT簡報排版原則',
    '基隆,建德國中,2017.08.28,河道作文．寫作導論',
    '新北,永和國中,2017.08.29,河道作文．寫作導論',
    '台中,弘文中學國中部,2017.09.01,河道作文Ｘ寫作導論',
    '宜蘭,羅東國中,2017.09.04,河道作文Ｘ寫作導論',
    '桃園,內壢國中,2017.09.12,河道作文Ｘ寫作導論',
    '新北,林口高中,2017.09.13,海貍戰隊Ｘ思維建構',
    '新北,林口高中,2017.09.13,海貍戰隊Ｘ思維建構',
    '台中,亞洲大學,2017.09.15,先聲奪人魅力表達',
    '桃園,武陵高中,2017.09.15,This is water',
    '新北,頭城國中,2017.09.18,海貍戰隊Ｘ思維建構',
    '新北,深坑國中,2017.09.19,河道作文．立意',
    '花蓮,花蓮高工,2017.09.20,思維建構與提問層次設計',
    '台北,興雅國中,2017.09.21,河道作文．立意',
    '台中,西苑中學,2017.09.22,河道作文・結構',
    '新北,深坑國中,2017.09.22,社團作文',
    '台中,新社高中,2017.09.25,海貍戰隊Ｘ思維建構',
    '桃園,大園國中,2017.09.26,河道作文．寫作導論',
    '台中,中興大學,2017.09.27,先聲奪人魅力表達',
    '新北,瑞芳高工,2017.09.27,海貍老師的翻滾教室',
    '台南,崇明國中,2017.09.29,海貍戰隊Ｘ思維建構',
    '台南,聖功女中,2017.09.29,河道作文・寫作導論',
    '新北,深坑國中,2017.09.29,社團作文',
    '桃園,育達高中,2017.10.02,海貍戰隊Ｘ思維建構',
    '屏東,東港高中國中部,2017.10.02,思辨・你憤怒誰的正義',
    '桃園,大成國中,2017.10.03,河道作文・寫作導論',
    '新北,辭修高中,2017.10.03,河道作文Ｘ寫作導論(跨國高中）',
    '台北,蘭雅國中,2017.10.05,河道作文Ｘ寫作導論',
    '台中,大墩國中,2017.10.06,河道作文Ｘ寫作導論',
    '台南,大灣中學,2017.10.06,河道作文・寫作導論',
    '新北,深坑國中,2017.10.06,社團作文',
    '台中,明德高中,2017.10.11,河道作文Ｘ寫作導論',
    '屏東,屏東女中,2017.10.11,河道作文Ｘ寫作導論',
    '花蓮,慈濟高中,2017.10.12,特色共備',
    '花蓮,慈濟大學,2017.10.13,講師學一日工作坊',
    '雲林,建國中學,2017.10.13,海貍戰隊Ｘ思維建構',
    '桃園,大園國際高中,2017.10.16,河道作文Ｘ寫作導論',
    '宜蘭,羅東國中,2017.10.16,河道作文Ｘ立意',
    '花蓮,花蓮高工,2017.10.18,共備・古典散文',
    '花蓮,四維高中,2017.10.18,河道作文Ｘ寫作導論',
    '台北,建成國中,2017.10.19,一款桌遊Ｘ百搭教學',
    '台中,大業國中,2017.10.20,河道作文Ｘ寫作導論',
    '南投,埔里高工,2017.10.20,海貍戰隊Ｘ思維建構',
    '高雄,龍華國中,2017.10.23,海貍戰隊Ｘ思維建構',
    '基隆,成功國中,2017.10.23,真相不只一個',
    '桃園,楊梅國中,2017.10.24,海貍戰隊Ｘ思維建構',
    '新北,三民高中國中部,2017.10.24,海貍戰隊Ｘ思維建構',
    '台中,宜寧高工,2017.10.25,寫作遊戲Ｘ尾巴巴呢',
    '台北,大理高中,2017.10.26,一款桌遊Ｘ百搭教學',
    '台北,陽明國中,2017.10.26,河道作文Ｘ寫作導論',
    '新北,新北高中,2017.10.26,河道作文Ｘ寫作導論',
    '台中,光明國中,2017.10.27,海貍戰隊Ｘ思維建構',
    '台中,萬和國中,2017.10.27,河道作文Ｘ寫作導論',
    '新北,深坑國中,2017.10.27,社團作文',
    '台中,長億高中國中部,2017.10.28,河道作文Ｘ寫作導論',
    '新北,智光商工,2017.10.28,翻轉到翻滾:貍想的課堂風景',
    '新北,智光商工,2017.10.28,寫作遊戲Ｘ尾巴巴呢',
    '台北,松山高中,2017.10.30,河道作文Ｘ結構',
    '宜蘭,宜蘭高商,2017.10.30,河道作文Ｘ立意',
    '花蓮,花蓮女中,2017.10.30,河道作文Ｘ寫作導論',
    '桃園,楊明國中,2017.10.31,海貍戰隊Ｘ思維建構',
    '新北,柑園國中,2017.10.31,海貍戰隊Ｘ思維建構',
    '雲林,斗六家商,2017.11.01,海貍戰隊Ｘ思維建構',
    '台北,大同高中,2017.11.02,河道作文Ｘ寫作導論',
    '台北,介壽國中,2017.11.02,一款桌遊Ｘ百搭教學',
    '台北,靜心國中,2017.11.02,海貍戰隊Ｘ思維建構',
    '苗栗,苗栗高中,2017.11.02,簡報設計原則/簡報實作分享',
    '桃園,武陵高中,2017.11.03,教師表達力',
    '嘉義,民生國中,2017.11.03,河道作文Ｘ寫作導論',
    '嘉義,協同中學,2017.11.04,學生作文',
    '桃園,中壢高中,2017.11.06,河道作文Ｘ寫作導論',
    '高雄,岡山國中,2017.11.06,海貍戰隊Ｘ思維建構',
    '高雄,左營國中,2017.11.06,海貍戰隊Ｘ思維建構',
    '高雄,左營高中,2017.11.07,先聲奪人魅力表達',
    '新北,深坑國中,2017.11.07,河道作文Ｘ文采',
    '新北,大觀國中,2017.11.07,河道作文Ｘ寫作導論',
    '新北,坪林國中,2017.11.08,海貍戰隊Ｘ思維建構',
    '台北,金甌女中,2017.11.09,河道作文Ｘ寫作導論',
    '台北,大安國中,2017.11.09,河道作文Ｘ寫作導論',
    '彰化,永靖國中,2017.11.10,海貍戰隊Ｘ思維建構',
    '新北,板橋高中,2017.11.10,河道作文Ｘ寫作導論',
    '新北,深坑國中,2017.11.10,社團作文',
    '宜蘭,蘭陽女中,2017.11.11,文學營',
    '桃園,大園國際高中,2017.11.13,寫作遊戲Ｘ尾巴巴呢',
    '高雄,右昌國中,2017.11.13,海貍戰隊Ｘ思維建構',
    '新竹,照南國中,2017.11.13,河道作文Ｘ寫作導論',
    '新竹,竹北高中,2017.11.13,河道作文Ｘ寫作導論',
    '新北,義學國中,2017.11.14,寫作遊戲Ｘ尾巴巴呢',
    '新北,五峰國中,2017.11.14,河道作文Ｘ寫作導論',
    '嘉義,華南高商,2017.11.15,河道作文Ｘ寫作導論',
    '花蓮,花蓮高工,2017.11.15,共備・古典小說',
    '台北,敦化國中,2017.11.16,河道作文Ｘ立意',
    '台北,方濟中學,2017.11.16,河道作文Ｘ寫作導論',
    '台中,光正國中,2017.11.17,海貍戰隊Ｘ思維建構',
    '台中,光榮國中,2017.11.17,河道作文Ｘ寫作導論',
    '彰化,彰藝高國中部,2017.11.17,河道作文Ｘ寫作導論',
    '嘉義,協同中學,2017.11.18,學生作文',
    '高雄,瑞祥國中,2017.11.20,河道作文Ｘ寫作導論',
    '台南,興國高中,2017.11.20,河道作文Ｘ寫作導論',
    '桃園,同德國中,2017.11.21,寫作遊戲Ｘ尾巴巴呢',
    '新北,鶯歌國中,2017.11.21,河道作文Ｘ寫作導論',
    '宜蘭,南澳中學,2017.11.22,海貍戰隊Ｘ思維建構（跨領域老師）',
    '花蓮,慈濟高中,2017.11.22,十節課三日',
    '花蓮,慈濟高中,2017.11.23,特色共備',
    '苗栗,苗栗高中,2017.11.23,決戰面試表達力',
    '基隆,基隆高中,2017.11.23,河道作文Ｘ寫作導論',
    '高雄,道明中學,2017.11.25,河道作文Ｘ寫作導論',
    '台北,松山高中,2017.11.27,河道作文Ｘ取材',
    '桃園,大園國際高中,2017.11.27,海貍戰隊Ｘ思維建構',
    '台南,南光高中,2017.11.27,河道作文Ｘ寫作導論',
    '台中,亞洲大學,2017.11.28,思辨・獸足偵探《辦公室殺貍事件》',
    '桃園,育達高中,2017.11.28,思維建構與提問層次設計',
    '桃園,自強國中,2017.11.28,河道作文Ｘ寫作導論',
    '彰化,成功高中,2017.11.29,河道作文Ｘ寫作導論',
    '苗栗,興華中學,2017.11.29,河道作文Ｘ寫作導論',
    '台北,民生國中,2017.11.30,河道作文Ｘ寫作導論',
    '新北,鶯歌高職,2017.11.30,海貍戰隊Ｘ思維建構',
    '台中,西苑中學,2017.12.01,河道作文・取材',
    '新北,深坑國中,2017.12.01,社團作文',
    '新北,林口高中,2017.12.01,河道作文Ｘ寫作導論',
    '嘉義,協同中學,2017.12.02,學生作文',
    '桃園,中壢高商,2017.12.04,河道作文Ｘ寫作導論',
    '宜蘭,羅東國中,2017.12.04,河道作文Ｘ文采',
    '台北,線上,2017.12.05,河道作文Ｘ寫作導論',
    '桃園,龜山國中,2017.12.05,先聲奪人魅力表達',
    '桃園,內壢國中,2017.12.05,河道作文Ｘ立意',
    '新北,丹鳳高中,2017.12.06,河道作文Ｘ寫作導論',
    '台北,成淵高中,2017.12.07,河道作文Ｘ寫作導論',
    '新北,三民高中,2017.12.07,河道作文Ｘ立意',
    '苗栗,苗栗高中,2017.12.07,好聲音溝通力',
    '台中,台中高工,2017.12.08,河道作文Ｘ寫作導論',
    '新北,深坑國中,2017.12.08,社團作文',
    '新北,林口高中,2017.12.08,河道作文Ｘ立意',
    '高雄,中山高中國中部,2017.12.09,河道作文Ｘ寫作導論＋立意',
    '台中,嶺東高工,2017.12.11,河道作文Ｘ寫作導論',
    '台北,松山高中,2017.12.11,河道作文Ｘ應用',
    '新北,瑞坪國中,2017.12.12,寫作遊戲Ｘ尾巴巴呢',
    '新北,石碇高中,2017.12.12,共備導論',
    '嘉義,民雄農工,2017.12.13,河道作文Ｘ寫作導論',
    '屏東,屏東女中,2017.12.13,河道作文Ｘ寫作導論',
    '台北,內湖高中,2017.12.14,特色課程共備',
    '南投,埔里國中,2017.12.14,河道作文Ｘ寫作導論',
    '台南,永康國中,2017.12.15,河道作文Ｘ寫作導論',
    '台南,成大,2017.12.15,你的努力有用嗎（結合戰隊）',
    '新北,深坑國中,2017.12.15,社團作文',
    '嘉義,協同中學(調課),2017.12.16,學生作文',
    '高雄,東港高中,2017.12.18,河道作文Ｘ寫作導論',
    '高雄,岡山國中,2017.12.18,河道作文Ｘ寫作導論',
    '桃園,平南國中,2017.12.19,海貍戰隊Ｘ思維建構',
    '新北,中正國中,2017.12.19,河道作文Ｘ寫作導論',
    '宜蘭,南澳中學,2017.12.20,河道作文Ｘ寫作導論',
    '宜蘭,南澳中學,2017.12.20,河道作文Ｘ寫作導論',
    '台北,永吉國中,2017.12.21,海貍戰隊Ｘ思維建構',
    '台中,西苑中學,2017.12.22,河道作文・共備',
    '桃園,壽山高中,2017.12.25,河道作文Ｘ寫作導論',
    '彰化,彰化女中,2017.12.25,河道作文Ｘ寫作導論',
    '新北,林口國中,2017.12.25,河道作文Ｘ寫作導論',
    '桃園,平鎮國中,2017.12.26,海貍戰隊Ｘ思維建構',
    '新北,石碇高中,2017.12.26,共備(你的努力有用嗎?)',
    '台南,瀛海中學,2017.12.27,河道作文Ｘ寫作導論',
    '宜蘭,羅東高工,2017.12.27,海貍戰隊Ｘ思維建構',
    '台北,古亭國中,2017.12.28,一款桌遊Ｘ百搭教學',
    '新北,三重商工,2017.12.28,河道作文Ｘ寫作導論',
    '彰化,社頭國中,2017.12.29,海貍戰隊入班＋會考解題',
    '台南,港明國中,2017.12.29,海貍戰隊Ｘ思維建構',
  ],
  2018: [
    '桃園,內壢國中,2018.01.02,河道作文Ｘ文采',
    '台北,興雅國中,2018.01.04,河道作文．文采',
    '台中,中山國中,2018.01.05,河道作文Ｘ寫作導論',
    '台南,佳里國中,2018.01.05,河道作文Ｘ寫作導論',
    '嘉義,協同中學,2018.01.06,學生作文',
    '桃園,大溪高中,2018.01.08,河道作文Ｘ寫作導論',
    '新竹,新竹高商,2018.01.08,河道作文Ｘ寫作導論',
    '桃園,桃園國中,2018.01.09,河道作文Ｘ寫作導論',
    '花蓮,慈濟高中,2018.01.11,特色共備',
    '新北,崇光女中,2018.01.11,河道作文Ｘ寫作導論(跨國高中）',
    '台北,內湖高中,2018.01.12,特色共備',
    '台南,聖功女中,2018.01.13,簡報亮點設計課程',
    '桃園,武陵高中,2018.01.15,河道作文Ｘ寫作導論',
    '高雄,中山高中,2018.01.15,河道作文Ｘ寫作導論',
    '新北,安溪國中,2018.01.16,河道作文Ｘ寫作導論',
    '新北,安康高中國中部,2018.01.16,河道作文Ｘ寫作導論',
    '彰化,溪湖高中,2018.01.17,河道作文Ｘ寫作導論',
    '台中,益品書屋,2018.01.18,小貍師培進階',
    '新北,新店高中,2018.01.19,河道作文Ｘ寫作導論',
    '台中,清水高中,2018.01.22,河道作文Ｘ寫作導論',
    '桃園,光明國中,2018.01.23,河道作文Ｘ寫作導論',
    '屏東,大同高中,2018.01.23,河道作文Ｘ寫作導論',
    '台北,南湖高中,2018.01.25,思辨・獸足偵探《誰殺了海貍》',
    '高雄,中山大學附中,2018.01.25,河道作文Ｘ寫作導論',
    '高雄,中山大學附中,2018.01.25,河道作文Ｘ寫作導論',
    '新北,五峰國中,2018.01.25,先聲奪人魅力表達',
    '台北,南湖高中,2018.01.26,思辨・This is water',
    '宜蘭,東光國中,2018.01.29,學生營隊：一百種說故事的方法(結構)、聯想金字塔(文采)',
    '宜蘭,東光國中,2018.01.30,學生營隊：人物夢工廠(取材)、幸運草思考法(立意)',
    '宜蘭,東光國中,2018.01.31,學生營隊：海貍戰隊Ｘ思維建構<華王優劣>、延伸寫作',
    '宜蘭,東光國中,2018.02.01,學生營隊：海貍戰隊Ｘ思維建構<再見西沙>、延伸寫作',
    '宜蘭,東光國中,2018.02.02,學生營隊：思辨・番茄樹、寫作遊戲Ｘ尾巴巴呢',
    '彰化,成功高中,2018.02.05,學生營隊：1. 海貍戰隊X思維建構 （以華王優劣為例） 2. 思辨：番茄樹事件',
    '彰化,成功高中,2018.02.06,學生營隊：3. 河道導論+立意 4. 寫作遊戲X尾巴巴呢',
    '台北,中正國中,2018.02.22,海貍戰隊Ｘ思維建構',
    '台中,亞洲大學,2018.02.23,簡報亮點設計',
    '桃園,平鎮高中,2018.02.24,河道作文Ｘ立意・文采・取材',
    '桃園,平鎮高中,2018.02.25,河道作文Ｘ結構・應用',
    '高雄,前鎮國中,2018.02.26,河道作文Ｘ寫作導論',
    '台北,士林高商,2018.03.01,河道作文Ｘ寫作導論',
    '新北,三民高中,2018.03.01,河道作文・文采',
    '台中,中港高中國中部,2018.03.02,河道作文Ｘ寫作導論',
    '桃園,武陵高中,2018.03.02,多元共備導論',
    '嘉義,協同中學,2018.03.03,學生作文',
    '嘉義,南興國中,2018.03.05,海貍戰隊Ｘ思維建構',
    '彰化,員林高中,2018.03.05,河道作文Ｘ寫作導論',
    '宜蘭,羅東國中,2018.03.05,河道作文・文采',
    '桃園,青埔國中,2018.03.06,思維建構與提問層次設計',
    '桃園,大溪國中,2018.03.06,河道作文Ｘ寫作導論',
    '宜蘭,南澳中學,2018.03.07,思辨・你憤怒誰的正義',
    '台北,麗山國中,2018.03.08,河道作文Ｘ寫作導論',
    '台北,方濟中學,2018.03.08,河道作文・立意',
    '台中,黎明國中,2018.03.09,河道作文Ｘ寫作導論',
    '台中,日南國中,2018.03.09,河道作文Ｘ寫作導論',
    '新竹,實驗中學,2018.03.09,簡報美學設計',
    '彰化,大同國中,2018.03.09,河道作文Ｘ寫作導論',
    '新北,深坑國中,2018.03.09,社團作文',
    '高雄,鳳新高中,2018.03.12,河道作文Ｘ寫作導論',
    '高雄,岡山高中,2018.03.12,海貍戰隊Ｘ思維建構',
    '桃園,大園國中,2018.03.13,河道作文Ｘ寫作導論',
    '宜蘭,宜蘭高商,2018.03.14,海貍戰隊Ｘ思維建構',
    '台北,師大附中國中部,2018.03.15,河道作文Ｘ寫作導論',
    '台北,中崙國中,2018.03.15,河道作文Ｘ寫作導論',
    '台北,陽明高中國中部,2018.03.15,一款桌遊X百搭教學',
    '台中,太平國中,2018.03.16,河道作文Ｘ寫作導論',
    '台中,鹿寮國中,2018.03.16,河道作文Ｘ寫作導論',
    '新竹,實驗中學,2018.03.16,思辨．番茄樹',
    '彰化,彰安國中,2018.03.16,河道作文Ｘ寫作導論',
    '新北,深坑國中,2018.03.16,社團作文',
    '嘉義,協同中學,2018.03.17,學生作文',
    '高雄,林園高中國中部,2018.03.19,思辨・你憤怒誰的正義',
    '花蓮,宜昌國中,2018.03.19,河道作文Ｘ寫作導論',
    '桃園,振聲中學,2018.03.20,河道作文Ｘ寫作導論',
    '新北,鷺江國中,2018.03.20,河道作文Ｘ寫作導論',
    '花蓮,花蓮高工,2018.03.21,共備・古典韻文',
    '台北,師大附中國中部,2018.03.22,河道作文・立意',
    '台北,大安國中,2018.03.22,河道作文・立意',
    '台中,東山高中國中部,2018.03.23,河道作文Ｘ寫作導論',
    '台南,聖功女中,2018.03.23,先聲奪人魅力表達',
    '台南,金城國中,2018.03.23,河道作文Ｘ寫作導論',
    '新竹,自強國中,2018.03.26,思維建構 X 提問層次設計',
    '雲林,北港高中,2018.03.26,河道作文Ｘ寫作導論',
    '桃園,平興國中,2018.03.27,河道作文Ｘ寫作導論',
    '新北,永和國中,2018.03.27,河道作文・立意',
    '宜蘭,南澳中學,2018.03.28,思辨・This is water',
    '台北,師大附中國中部,2018.03.29,河道作文・文采',
    '台北,石牌國中,2018.03.29,河道作文Ｘ寫作導論',
    '基隆,基隆高中,2018.03.29,海貍戰隊Ｘ思維建構',
    '桃園,武陵高中,2018.03.30,特色課程共備',
    '新北,中和高中,2018.03.30,河道作文・立意',
    '台中,惠文高中,2018.04.02,河道作文Ｘ寫作導論',
    '桃園,復旦國中,2018.04.02,河道作文Ｘ寫作導論',
    '宜蘭,羅東國中,2018.04.02,河道作文・結構',
    '彰化,成功高中,2018.04.03,特色課程共備導論',
    '新北,辭修高中,2018.04.03,海貍戰隊Ｘ思維建構',
    '桃園,陽明高中,2018.04.09,河道作文Ｘ寫作導論',
    '高雄,橋頭國中,2018.04.09,海貍戰隊Ｘ思維建構',
    '新北,崇林國中,2018.04.09,河道作文Ｘ寫作導論',
    '台南,興國中學國中部,2018.04.10,河道作文Ｘ寫作導論',
    '台南,家政學科中心,2018.04.10,教師表達力',
    '新北,鶯歌國中,2018.04.10,河道作文Ｘ寫作導論',
    '宜蘭,南澳中學,2018.04.11,思辨・真相不只一個',
    '台北,方濟中學,2018.04.12,河道作文・文采',
    '台北,民生國中,2018.04.12,河道作文X立意',
    '高雄,瑞祥國中,2018.04.12,海貍戰隊Ｘ思維建構',
    '新北,達觀國中,2018.04.12,海貍戰隊Ｘ思維建構',
    '台中,立人國中,2018.04.13,河道作文Ｘ寫作導論',
    '台中,西苑中學,2018.04.13,簡報美學設計',
    '台南,民德國中,2018.04.13,河道作文Ｘ寫作導論',
    '新北,深坑國中,2018.04.13,社團作文',
    '台中,衛道中學,2018.04.16,河道作文Ｘ寫作導論',
    '花蓮,花蓮女中,2018.04.16,河道作文・立意',
    '花蓮,慈濟大學,2018.04.16,思辨・誰殺了海貍',
    '台北,家政學科中心,2018.04.17,教師表達力',
    '桃園,楊梅國中,2018.04.17,思維建構X提問層次設計',
    '新竹,鳳岡國中,2018.04.17,海貍戰隊Ｘ思維建構',
    '新北,南山中學,2018.04.17,選版說明會',
    '新北,辭修高中國中部,2018.04.17,海貍的應試對策',
    '彰化,溪湖高中,2018.04.18,海貍戰隊Ｘ思維建構',
    '宜蘭,南澳中學,2018.04.18,思辨・你的努力有用嗎(進階）',
    '台北,北投國中,2018.04.19,河道作文Ｘ寫作導論',
    '新北,達觀國中,2018.04.19,河道作文Ｘ寫作導論',
    '新北,三民高中,2018.04.19,河道作文・結構',
    '台中,黎明國中,2018.04.20,河道作文Ｘ寫作導論',
    '台中,光復國中,2018.04.20,河道作文Ｘ寫作導論',
    '彰化,大同國中,2018.04.20,河道作文・立意',
    '台南,佳里國中,2018.04.20,河道作文・立意',
    '台南,麻豆國中,2018.04.20,海貍戰隊Ｘ思維建構',
    '南投,埔里商工,2018.04.20,簡報美學設計',
    '嘉義,協同中學,2018.04.21,學生作文',
    '桃園,大園高中,2018.04.23,河道作文・立意',
    '高雄,高雄中學,2018.04.23,獸足偵探',
    '嘉義,北興中學,2018.04.23,思維建構與提問層次設計',
    '台南,德光中學,2018.04.23,河道作文Ｘ寫作導論',
    '新北,永和國中,2018.04.24,河道作文・文采',
    '新北,光榮國中,2018.04.24,河道作文Ｘ寫作導論',
    '花蓮,花蓮高工,2018.04.25,共備・白話散文',
    '台中,僑泰中學,2018.04.26,河道作文X寫作導論',
    '彰化,信義國中,2018.04.26,河道作文Ｘ寫作導論',
    '彰化,介壽國中,2018.04.26,河道作文Ｘ寫作導論',
    '彰化,成功高中,2018.04.26,特色課程綱要與模組',
    '台中,光榮國中,2018.04.27,河道作文・立意',
    '台中,沙鹿國中,2018.04.27,河道作文Ｘ寫作導論',
    '台中,萬和國中,2018.04.27,海貍戰隊Ｘ思維建構',
    '彰化,大同國中,2018.04.27,河道作文・文采',
    '台南,南科實驗國中部,2018.04.27,河道作文・立意',
    '新北,深坑國中,2018.04.27,社團作文',
    '桃園,大園高中,2018.04.30,下輩子別當國文老師',
    '高雄,陽明國中,2018.04.30,河道作文Ｘ寫作導論',
    '高雄,大義國中,2018.04.30,海貍戰隊Ｘ思維建構',
    '花蓮,花蓮高中,2018.04.30,思維建構與提問層次設計',
    '苗栗,竹南國中,2018.04.30,河道作文Ｘ寫作導論',
    '花蓮,慈濟中學,2018.05.01,寫作課程14堂',
    '新北,安溪國中,2018.05.01,海貍戰隊Ｘ思維建構',
    '台北,內湖高中,2018.05.03,簡報設計',
    '台北,天母國中,2018.05.03,河道作文Ｘ寫作導論',
    '基隆,聖心高中,2018.05.03,思辨・真相不只一個',
    '台中,光德國中,2018.05.04,河道作文Ｘ寫作導論',
    '台中,清水國中,2018.05.04,河道作文Ｘ寫作導論',
    '新北,深坑國中,2018.05.04,社團作文',
    '嘉義,協同中學,2018.05.05,學生作文',
    '台中,新民高中,2018.05.07,河道作文Ｘ寫作導論',
    '高雄,陽明國中,2018.05.07,河道作文・立意',
    '高雄,路竹高中國中部,2018.05.07,河道作文Ｘ寫作導論',
    '彰化,精誠中學,2018.05.07,河道作文Ｘ寫作導論',
    '台南,南光高中,2018.05.07,思辨 · 你信仰誰的神明',
    '桃園,內壢國中,2018.05.08,河道作文．結構',
    '彰化,溪湖高中,2018.05.08,河道作文・立意',
    '新北,三和國中,2018.05.08,河道作文．立意',
    '台中,嘉陽中學,2018.05.09,河道作文Ｘ寫作導論',
    '桃園,桃園農工,2018.05.09,河道作文Ｘ寫作導論',
    '高雄,高雄高工,2018.05.09,思維建構與提問層次設計',
    '台北,中崙高中,2018.05.10,河道作文Ｘ寫作導論',
    '台北,內湖高中,2018.05.10,特色課程共備',
    '台中,大雅國中,2018.05.11,河道作文Ｘ入海實戰',
    '高雄,中正高工,2018.05.11,河道作文Ｘ寫作導論',
    '彰化,大同國中,2018.05.11,河道作文・結構',
    '高雄,陽明國中,2018.05.14,河道作文・文采',
    '屏東,恆春工商,2018.05.14,河道作文Ｘ寫作導論',
    '台中,明道中學,2018.05.15,河道作文Ｘ寫作導論',
    '台中,霧峰農工,2018.05.15,河道作文Ｘ寫作導論',
    '彰化,成功高中,2018.05.15,特色課程產出與包裝',
    '新北,中興國中,2018.05.15,河道作文Ｘ寫作導論',
    '宜蘭,蘇澳海事,2018.05.16,河道作文Ｘ寫作導論',
    '花蓮,四維高中,2018.05.16,河道作文・立意',
    '台北,興雅國中,2018.05.17,河道作文・結構',
    '台北,五常國中,2018.05.18,思辨 · 番茄樹',
    '彰化,彰化高中,2018.05.18,築壩 · 地基',
    '台中,東大附中,2018.05.21,雜學校殺貍事件',
    '高雄,陽明國中,2018.05.21,河道作文・結構',
    '彰化,員林農工,2018.05.21,河道作文Ｘ寫作導論',
    '宜蘭,羅東國中,2018.05.21,河道作文・應用',
    '桃園,內壢國中,2018.05.22,河道作文．取材',
    '彰化,員林家商,2018.05.22,河道作文Ｘ寫作導論',
    '新北,竹圍國中,2018.05.22,雜學校殺貍事件',
    '台北,大理高中,2018.05.24,河道作文Ｘ寫作導論',
    '新北,三民高中,2018.05.24,河道作文・取材',
    '台中,新民高中,2018.05.25,海貍戰隊Ｘ思維建構',
    '新北,板橋高中,2018.05.25,築壩 · 地基',
    '台中,東大附中,2018.05.28,下輩子別當國文老師',
    '桃園,大園高中,2018.05.28,番茄樹',
    '桃園,育達高中,2018.05.28,共備‧古典散文',
    '高雄,陽明國中,2018.05.28,河道作文・結構',
    '新北,竹林高中,2018.05.28,教師表達力',
    '台北,永和國中,2018.05.29,河道作文・結構',
    '新北,碧華國中,2018.05.29,海貍戰隊X思維建構',
    '台北,輔仁大學,2018.05.30,教師表達力',
    '南投,暨大附中,2018.05.30,河道作文Ｘ寫作導論',
    '台北,中正國中,2018.05.31,築壩 · 地基',
    '台北,陽明國中,2018.05.31,河道作文・立意',
    '台中,西苑中學,2018.06.01,教師表達力',
    '彰化,大同國中,2018.06.01,河道作文・取材',
    '彰化,二林高中,2018.06.01,河道作文X寫作導論',
    '新北,深坑國中,2018.06.01,社團作文',
    '台中,文華高中,2018.06.04,河道作文Ｘ寫作導論',
    '新竹,光武國中,2018.06.04,河道作文Ｘ寫作導論',
    '桃園,內壢國中,2018.06.05,河道作文．應用',
    '花蓮,吉安國中,2018.06.05,海貍戰隊Ｘ思維建構',
    '花蓮,花蓮女中,2018.06.06,海貍的應試策略',
    '台北,內湖高中,2018.06.07,簡報設計',
    '台北,育成高中,2018.06.07,河道作文Ｘ寫作導論',
    '台北,靜心高中,2018.06.07,河道作文Ｘ寫作導論',
    '台北,實踐國中,2018.06.07,河道作文Ｘ寫作導論',
    '台中,大明高中,2018.06.08,河道作文Ｘ寫作導論',
    '台中,沙鹿高工,2018.06.08,雜學校殺貍事件',
    '彰化,大同國中,2018.06.08,河道作文・應用',
    '彰化,永靖國中,2018.06.08,河道作文Ｘ寫作導論',
    '彰化,正德高中,2018.06.08,思辨 · 番茄樹',
    '新北,深坑國中,2018.06.08,社團作文',
    '台中,衛道中學,2018.06.11,河道作文．立意',
    '台中,東大附中,2018.06.11,海貍戰隊Ｘ思維建構',
    '高雄,陽明國中,2018.06.11,河道作文・應用',
    '花蓮,慈濟中學,2018.06.12,思辨．番茄樹（兩堂）',
    '新北,石碇高中,2018.06.12,教師表達力',
    '彰化,永靖高工,2018.06.13,河道作文Ｘ寫作導論',
    '花蓮,慈濟中學,2018.06.13,思辨Ｘ六堂',
    '台北,內湖高中,2018.06.14,簡報美學設計（檢核作業）',
    '花蓮,慈濟中學,2018.06.14,多元選修議課',
    '基隆,八斗高中,2018.06.14,海貍戰隊Ｘ思維建構',
    '台中,大道國中,2018.06.15,河道作文Ｘ寫作導論',
    '花蓮,慈濟中學,2018.06.15,獸足偵探．誰殺了海貍（兩堂）',
    '新北,福和國中,2018.06.19,教師表達力',
    '南投,暨大附中,2018.06.19,河道作文・立意',
    '南投,暨大附中,2018.06.19,海貍戰隊Ｘ思維建構',
    '花蓮,花蓮高工,2018.06.20,共備・白話韻文',
    '台北,內湖高中,2018.06.21,思維導向閱讀教學',
    '雲林,斗六家商,2018.06.21,河道作文Ｘ寫作導論',
    '台中,台中高工,2018.06.22,築壩‧地基',
    '台中,大里高中,2018.06.25,海貍戰隊Ｘ思維建構',
    '高雄,鳳新高中,2018.06.27,思維導向閱讀教學',
    '宜蘭,羅東國中,2018.07.02,誰的履歷表／古今小說鑑賞',
    '宜蘭,羅東國中,2018.07.03,真相不只一個',
    '台北,士林高商,2018.07.04,海貍戰隊Ｘ思維建構',
    '宜蘭,東光國中,2018.07.09,海貍戰隊Ｘ思維建構 / 延伸寫作',
    '南投,暨大附中,2018.07.09,河道作文Ｘ寫作導論',
    '宜蘭,東光國中,2018.07.10,取材 · 八大項目 / 生命曲線',
    '宜蘭,東光國中,2018.07.11,讓敘事力起飛 / 雜學校殺貍事件',
    '台中,黎明國中,2018.07.12,教師表達力',
    '宜蘭,東光國中,2018.07.12,創意九宮格 / 架構分明圖解法',
    '台中,黎明國中,2018.07.13,海貍戰隊Ｘ思維建構',
    '宜蘭,東光國中,2018.07.13,三行情書 / 寫作遊戲X尾巴巴呢',
    '花蓮,花蓮女中,2018.07.14,河道作文Ｘ寫作導論/立意',
    '台中,黎明國中,2018.07.19,思維建構Ｘ提問層次設計',
    '台中,黎明國中,2018.07.20,思辨 · 番茄樹',
    '南投,暨大附中,2018.07.20,河道作文Ｘ寫作導論',
    '南投,暨大附中,2018.07.20,河道作文Ｘ寫作導論',
    '花蓮,花蓮女中,2018.07.21,河道作文Ｘ文采/結構',
    '台中,黎明國中,2018.07.23,思辨 · 真相不只一個',
    '台中,黎明國中,2018.07.24,思辨 · 你的努力有用嗎',
    '台中,黎明國中,2018.07.25,思辨 · 你憤怒誰的正義',
    '台中,黎明國中,2018.07.26,思辨 · This is water',
    '花蓮,花蓮女中,2018.07.28,河道作文Ｘ取材/應用',
    '彰化,彰化女中,2018.08.07,河道作文Ｘ寫作導論',
    '台中,向上國中,2018.08.16,河道作文．立意',
    '台中,向上國中,2018.08.16,河道作文．立意',
    '台北,康橋國際中學,2018.08.23,河道作文Ｘ寫作導論/立意',
    '高雄,新莊國小,2018.08.24,提問層次設計 X 共備 · 白話散文',
    '基隆,建德國中,2018.08.29,河道作文．立意',
    '台北,延平中學,2018.09.11,河道作文Ｘ寫作導論',
    '宜蘭,慧燈中學,2018.09.11,河道作文Ｘ寫作導論',
    '新北,豐珠國中,2018.09.11,海貍戰隊Ｘ思維建構',
    '台北,延平中學,2018.09.13,河道作文Ｘ寫作導論',
    '嘉義,嘉義大學,2018.09.13,你的努力有用嗎 / 烏克里里殺貍事件',
    '新北,清水高中,2018.09.13,教師表達力',
    '台中,益品書屋,2018.09.14,小貍師培進階',
    '南投,暨大附中,2018.09.14,大考應試策略',
    '高雄,岡山國中,2018.09.17,河道作文・立意',
    '台南,大灣高中,2018.09.17,海貍戰隊Ｘ思維建構',
    '屏東,恆春工商,2018.09.17,河道作文・立意',
    '台北,延平中學,2018.09.18,河道作文・立意',
    '桃園,大華國中,2018.09.18,河道作文Ｘ寫作導論',
    '新北,八里國中,2018.09.18,河道作文Ｘ寫作導論',
    '台北,小樹屋,2018.09.19,河道週間班',
    '花蓮,慈濟中學,2018.09.19,文本共備',
    '花蓮,花蓮高工,2018.09.19,河道作文・立意',
    '台北,延平中學,2018.09.20,河道作文・立意',
    '台北,小樹屋,2018.09.20,築壩週間班',
    '基隆,基隆海事,2018.09.20,河道作文Ｘ寫作導論',
    '台中,益品書屋,2018.09.21,小貍師培進階',
    '南投,仁愛國中,2018.09.21,河道作文Ｘ寫作導論',
    '台北,小樹屋,2018.09.26,河道週間班',
    '台北,小樹屋,2018.09.27,築壩週間班',
    '台北,桃源國中,2018.09.27,河道作文Ｘ寫作導論',
    '台北,北投國中,2018.09.27,河道作文・立意',
    '基隆,基隆女中,2018.09.27,河道作文Ｘ寫作導論',
    '台中,外埔國中,2018.09.28,河道作文・立意',
    '嘉義,協同中學,2018.09.29,河道作文Ｘ寫作導論',
    '桃園,復旦國中,2018.10.01,河道作文・立意',
    '台南,北門農工,2018.10.01,築壩 · 地基',
    '台北,延平中學,2018.10.02,河道作文・文采',
    '新北,永和國中,2018.10.02,河道作文・取材',
    '台北,小樹屋,2018.10.03,河道週間班',
    '花蓮,玉里高中,2018.10.03,河道作文Ｘ寫作導論',
    '屏東,屏東女中,2018.10.03,表達力概論',
    '台北,延平中學,2018.10.04,河道作文・文采',
    '台中,華盛頓中學,2018.10.05,河道作文・寫作導論',
    '台中,大甲國中,2018.10.05,河道作文・寫作導論',
    '台中,益品書屋,2018.10.05,小貍師培進階',
    '嘉義,協同中學,2018.10.06,河道作文・立意',
    '台中,明德中學,2018.10.08,河道作文．立意',
    '高雄,岡山國中,2018.10.08,河道作文・文采',
    '台南,南光高中,2018.10.08,教師表達力',
    '台南,復興國中,2018.10.09,河道作文Ｘ寫作導論',
    '台北,延平中學,2018.10.11,河道作文・結構',
    '新北,泰山高中,2018.10.11,海貍戰隊Ｘ思維建構',
    '雲林,斗六國中,2018.10.11,河道作文Ｘ寫作導論',
    '台中,大道國中,2018.10.12,思維建構與層次脈絡設計',
    '台中,益品書屋,2018.10.12,小貍師培進階',
    '嘉義,興華高中,2018.10.12,創作桌遊Ｘ尾巴巴呢',
    '彰化,彰興國中,2018.10.12,河道作文・寫作導論',
    '台南,長榮國中,2018.10.12,創作桌遊Ｘ尾巴巴呢',
    '新北,育達高職,2018.10.13,河道作文Ｘ寫作導論',
    '台中,明德中學,2018.10.15,河道作文．文采',
    '高雄,高雄女中,2018.10.15,超時空偵探．死了一個警察之後',
    '彰化,彰化女中,2018.10.15,教師表達力',
    '台北,延平中學,2018.10.16,河道作文・結構',
    '台北,小樹屋,2018.10.17,河道週間班',
    '花蓮,四維高中,2018.10.17,河道作文・文采',
    '花蓮,花蓮高工,2018.10.17,河道作文・文采',
    '台北,小樹屋,2018.10.18,築壩週間班',
    '台北,天母國中,2018.10.18,思維建構與層次脈絡設計',
    '台北,北投國中,2018.10.18,教師表達力',
    '台北,延平中學,2018.10.18,河道作文・取材',
    '台中,福科國中,2018.10.19,河道作文Ｘ寫作導論',
    '台中,日南國中,2018.10.19,河道作文・立意',
    '台中,益品書屋,2018.10.19,小貍師培進階',
    '彰化,彰安國中,2018.10.19,河道作文・立意',
    '台北,台北醫學大學,2018.10.20,真相不只一個',
    '台中,衛道中學,2018.10.22,河道作文．文采',
    '台中,曉明女中,2018.10.22,河道作文Ｘ寫作導論',
    '台中,明德中學,2018.10.22,河道作文．文采',
    '高雄,岡山國中,2018.10.22,河道作文・結構',
    '台北,延平中學,2018.10.23,河道作文・取材',
    '宜蘭,慧燈中學,2018.10.23,河道作文．立意',
    '新北,尖山國中,2018.10.23,河道作文Ｘ寫作導論',
    '台北,小樹屋,2018.10.24,河道週間班',
    '屏東,屏東女中,2018.10.24,表達敘事學',
    '南投,暨大附中,2018.10.24,海貍戰隊Ｘ思維建構',
    '台北,信義國中,2018.10.25,河道作文．立意',
    '台北,延平中學,2018.10.25,河道作文・應用',
    '台中,清水國中,2018.10.26,河道作文・立意',
    '台中,益品書屋,2018.10.26,小貍師培進階',
    '台北,小樹屋,2018.10.26,築壩週間班',
    '新竹,自強國中,2018.10.26,河道作文．立意',
    '南投,暨大附中,2018.10.26,簡報設計',
    '南投,暨大附中,2018.10.26,河道作文Ｘ寫作導論',
    '高雄,明華國中,2018.10.27,提問層次設計 X 共備 · 白話散文',
    '新北,育達高職,2018.10.27,河道作文．立意',
    '南投,暨大附中,2018.10.27,河道作文．立意／文采',
    '彰化,正德國中,2018.10.29,表達力進階：遊戲設計',
    '彰化,文興高中,2018.10.29,河道作文Ｘ寫作導論',
    '新北,鷺江國中,2018.10.30,河道作文．立意',
    '台北,小樹屋,2018.10.31,河道週間班',
    '嘉義,民雄農工,2018.10.31,河道作文Ｘ寫作導論',
    '花蓮,慈濟中學,2018.10.31,觀課及議課',
    '台北,小樹屋,2018.11.01,築壩週間班',
    '台北,古亭國中,2018.11.01,創作桌遊Ｘ尾巴巴呢',
    '台中,立人國中,2018.11.02,河道作文．立意',
    '台中,益品書屋,2018.11.02,小貍師培進階',
    '新北,育達高職,2018.11.03,河道作文．文采',
    '桃園,復旦國中,2018.11.05,河道作文・文采',
    '高雄,新莊高中,2018.11.05,思辨．屋頂上的番茄樹',
    '高雄,岡山國中,2018.11.05,河道作文・取材',
    '台北,延平中學,2018.11.06,河道作文・應用',
    '新北,蘆洲國中,2018.11.06,河道作文Ｘ寫作導論',
    '花蓮,玉里高中,2018.11.07,河道作文．立意',
    '南投,暨大附中,2018.11.07,你抓的是誰的寶貝',
    '台北,小樹屋,2018.11.08,築壩週間班',
    '南投,暨大附中,2018.11.10,河道作文．結構／取材',
    '台中,興大附中,2018.11.12,河道作文Ｘ寫作導論',
    '嘉義,北興國中,2018.11.12,河道作文Ｘ寫作導論',
    '屏東,東港高中,2018.11.12,海貍戰隊Ｘ思維建構',
    '屏東,恆春工商,2018.11.12,河道作文・文采',
    '新北,中山國中,2018.11.13,河道作文Ｘ寫作導論',
    '新北,淡水國中,2018.11.13,思維導向閱讀策略',
    '花蓮,慈濟中學,2018.11.14,文本共備',
    '花蓮,花蓮高工,2018.11.14,河道作文・結構',
    '苗栗,苗栗高中,2018.11.14,河道作文．寫作導論',
    '台北,小樹屋,2018.11.15,築壩週間班',
    '台北,實踐國中,2018.11.15,一款桌遊Ｘ百搭教學',
    '新北,康橋國際中學,2018.11.15,河道作文．文采／結構',
    '新北,雙溪高中,2018.11.15,河道作文Ｘ寫作導論',
    '台中,福科國中,2018.11.16,河道作文．立意',
    '台中,益品書屋,2018.11.16,小貍師培進階',
    '嘉義,協同中學,2018.11.17,河道作文．寫作導論',
    '新北,育達高職,2018.11.17,河道作文．結構',
    '台中,東山高中,2018.11.19,海貍戰隊Ｘ思維建構',
    '高雄,岡山國中,2018.11.19,河道作文・應用',
    '新北,永和國中,2018.11.20,河道作文・應用',
    '屏東,屏東女中,2018.11.21,表達溝通術',
    '屏東,屏榮高中,2018.11.21,大考應試對策',
    '台北,黑捲,2018.11.22,築壩週間班',
    '台北,中正國中,2018.11.22,河道作文Ｘ寫作導論',
    '台中,清泉國中,2018.11.23,河道作文Ｘ寫作導論',
    '台中,崇德國中,2018.11.23,海貍戰隊Ｘ思維建構',
    '台中,益品書屋,2018.11.23,小貍師培進階',
    '台北,小樹屋,2018.11.24,河道週間班',
    '新北,育達高職,2018.11.24,河道作文．取材',
    '台中,明道中學,2018.11.25,河道．源頭',
    '彰化,彰化女中,2018.11.26,築壩．地基',
    '台北,桃源國中,2018.11.28,河道作文Ｘ寫作導論',
    '屏東,屏東女中,2018.11.28,表達簡報學',
    '新北,泰山高中,2018.11.28,河道作文Ｘ寫作導論',
    '台中,沙鹿高工,2018.11.29,真相不只一個',
    '台北,黑捲,2018.11.29,築壩週間班',
    '新北,清傳高商,2018.11.29,河道作文Ｘ寫作導論',
    '新北,泰山高中,2018.11.29,你信仰誰的神明',
    '台中,清泉國中,2018.11.30,河道作文．立意',
    '台中,明道中學,2018.12.01,河道．源頭',
    '台北,小樹屋,2018.12.01,河道週間班',
    '嘉義,協同中學,2018.12.01,河道作文・文采',
    '高雄,鳳新高中,2018.12.03,河道作文．立意',
    '高雄,阿蓮國中,2018.12.03,河道作文Ｘ寫作導論',
    '宜蘭,慧燈中學,2018.12.04,河道作文．文采',
    '台中,新民高中,2018.12.05,築壩 · 地基',
    '台中,益品書屋,2018.12.06,河道週間班',
    '台北,黑捲,2018.12.06,築壩週間班',
    '台北,松山家商,2018.12.06,河道作文Ｘ寫作導論',
    '新北,泰山高中,2018.12.06,築壩 · 地基',
    '台中,霧峰國中,2018.12.07,河道作文Ｘ寫作導論',
    '台中,大道國中,2018.12.07,廚房殺貍事件',
    '新竹,自強國中,2018.12.07,河道作文．文采',
    '新北,育達高職,2018.12.08,河道作文．應用',
    '台北,華岡藝校,2018.12.10,海貍戰隊Ｘ思維建構',
    '桃園,復旦國中,2018.12.10,河道作文・結構',
    '台南,大灣高中,2018.12.10,河道作文Ｘ寫作導論',
    '宜蘭,礁溪國中,2018.12.11,海貍戰隊Ｘ思維建構',
    '花蓮,海星中學,2018.12.11,河道作文Ｘ寫作導論',
    '台中,益品書屋,2018.12.13,河道週間班',
    '台北,黑捲,2018.12.13,築壩週間班',
    '新北,雙溪高中,2018.12.13,教師表達力',
    '新北,泰山高中,2018.12.13,課堂RPG',
    '台中,華盛頓中學,2018.12.14,河道作文・立意',
    '台中,福科國中,2018.12.14,河道作文．立意',
    '台中,向上國中,2018.12.14,河道作文Ｘ寫作導論',
    '台中,益品書屋,2018.12.14,小貍師培進階',
    '台北,小樹屋,2018.12.15,河道週間班',
    '嘉義,協同中學,2018.12.15,河道作文・結構',
    '屏東,恆春工商,2018.12.17,河道作文・結構',
    '台東,台東女中,2018.12.17,河道作文Ｘ寫作導論',
    '桃園,大竹國中,2018.12.18,海貍戰隊Ｘ思維建構',
    '台南,佳里國中,2018.12.18,創作桌遊Ｘ尾巴巴呢',
    '台東,台東高中,2018.12.18,教師表達力',
    '台東,台東體中,2018.12.18,海貍戰隊Ｘ思維建構',
    '花蓮,慈濟中學,2018.12.19,大貍觀課＋議課',
    '台中,益品書屋,2018.12.20,河道週間班',
    '台北,黑捲,2018.12.20,築壩週間班',
    '台中,大道國中,2018.12.21,築壩．工程導論',
    '台北,民權國中,2018.12.21,河道作文Ｘ寫作導論',
    '桃園,大園高中,2018.12.24,超時空偵探',
    '高雄,鳳新高中,2018.12.24,河道作文．文采',
    '屏東,屏東女中,2018.12.26,表達與時事',
    '屏東,屏榮高中,2018.12.26,河道作文Ｘ寫作導論',
    '台北,民生國中,2018.12.27,一款桌遊Ｘ百搭教學',
    '台北,黑捲,2018.12.27,築壩週間班',
    '台中,益品書屋,2018.12.28,小貍師培進階',
    '台北,小樹屋,2018.12.29,河道週間班',
  ],
  2019: [
    '台中,益品書屋,2019.01.03,河道週間班',
    '台北,天母國中,2019.01.03,創作桌遊Ｘ尾巴巴呢',
    '台中,五權國中,2019.01.04,河道作文Ｘ寫作導論',
    '台中,益品書屋,2019.01.04,小貍師培進階',
    '台北,小樹屋,2019.01.05,河道週間班',
    '嘉義,協同中學,2019.01.05,河道作文・取材',
    '高雄,鳳新高中,2019.01.07,河道作文．結構',
    '嘉義,朴子國中,2019.01.07,創作桌遊Ｘ尾巴巴呢',
    '桃園,南崁國中,2019.01.08,創作桌遊Ｘ尾巴巴呢',
    '新北,中和國中,2019.01.08,海貍戰隊Ｘ思維建構',
    '苗栗,照南國中,2019.01.08,河道作文．立意',
    '屏東,屏東女中,2019.01.09,你為誰道的歉',
    '台中,益品書屋,2019.01.10,河道週間班',
    '台中,益品書屋,2019.01.11,小貍師培進階',
    '台北,小樹屋,2019.01.12,河道週間班',
    '台中,大里高中,2019.01.14,築壩 · 地基',
    '台中,益品書屋,2019.01.17,河道週間班',
    '台中,大甲國中,2019.01.21,河道作文．寫作導論',
    '新北,康橋國際中學,2019.01.21,河道作文．寫作導論／立意',
    '台中,大甲國中,2019.01.22,河道作文．立意',
    '新北,康橋國際中學,2019.01.22,河道作文．立意',
    '台中,大甲國中,2019.01.23,河道作文．文采',
    '台東,池上國中,2019.01.23,河道作文．立意',
    '台東,池上國中,2019.01.23,你的努力有用嗎',
    '台中,大甲國中,2019.01.24,河道作文．立意',
    '新北,坪林國中,2019.01.24,河道作文．立意',
    '台中,大甲國中,2019.01.25,河道作文．文采',
    '新北,坪林國中,2019.01.25,河道作文．文采／尾巴巴呢',
    '南投,仁愛國中,2019.01.25,教師表達力',
    '台北,文創中心,2019.01.26,河道實戰工作坊',
    '台北,文創中心,2019.01.26,學期認證考',
    '台南,大灣高中,2019.01.28,說話藝術',
    '新北,康橋國際中學,2019.02.15,河道作文．寫作導論／立意',
    '台中,向上國中,2019.02.16,河道作文．文采',
    '台中,向上國中,2019.02.16,河道作文．文采',
    '彰化,彰興國中,2019.02.22,河道作文・立意',
    '新北,康橋國際中學,2019.02.22,河道作文．寫作導論／立意',
    '新北,康橋國際中學,2019.02.22,河道作文．文采',
    '台中,衛道中學,2019.02.25,河道作文．結構',
    '高雄,鳳新高中,2019.02.25,河道作文．取材',
    '新北,鷺江國中,2019.02.26,河道作文．文采',
    '桃園,復旦國中,2019.03.04,河道作文・取材',
    '新北,永和國中,2019.03.05,評量之後的評量',
    '新北,清水高中國中部,2019.03.05,河道作文・寫作導論',
    '花蓮,慈濟中學,2019.03.06,評量之後的評量',
    '花蓮,慈濟大學,2019.03.06,思辨．This is water',
    '新北,尖山國中,2019.03.07,河道作文．寫作導論',
    '基隆,中正國中,2019.03.07,海貍戰隊．思維建構',
    '新竹,自強國中,2019.03.08,河道作文．結構',
    '新北,康橋國際中學,2019.03.08,河道作文．文采',
    '新北,康橋國際中學,2019.03.08,河道作文．結構',
    '高雄,三民高中,2019.03.09,河道作文．寫作導論',
    '高雄,康軒大場,2019.03.09,評量之後的評量',
    '高雄,鳳新高中,2019.03.11,河道作文．應用',
    '台北,臺灣大學,2019.03.12,教師表達力',
    '桃園,福豐國中,2019.03.12,海貍戰隊．思維建構',
    '台北,師大附中,2019.03.14,河道作文．寫作導論',
    '台北,成功高中,2019.03.14,河道作文．寫作導論',
    '台北,陽明國中,2019.03.14,海貍戰隊Ｘ思維建構',
    '新北,尖山國中,2019.03.14,河道作文．立意',
    '台中,向上國中,2019.03.15,河道作文．立意',
    '台中,新社國中,2019.03.15,河道作文．寫作導論',
    '彰化,彰安國中,2019.03.15,河道作文．文采',
    '台北,臺灣醫聯會,2019.03.16,講師表達力',
    '屏東,恆春工商,2019.03.18,河道作文・取材',
    '台東,台東女中,2019.03.18,河道作文・寫作導論',
    '新北,南山中學,2019.03.19,河道作文．寫作導論',
    '台中,中興大學,2019.03.20,你的邏輯不是你的邏輯',
    '花蓮,慈濟大學,2019.03.20,築壩．工程導論',
    '台北,方濟中學,2019.03.21,河道作文．結構',
    '台北,民生國中,2019.03.21,創作桌遊Ｘ尾巴巴呢',
    '新北,關渡國中,2019.03.21,河道作文．寫作導論',
    '新北,尖山國中,2019.03.21,河道作文．文采',
    '台中,忠明國中,2019.03.22,河道作文．寫作導論',
    '新北,康橋國際中學,2019.03.22,河道作文．結構',
    '新北,康橋國際中學,2019.03.22,河道作文．取材',
    '雲林,揚子中學,2019.03.22,河道作文．寫作導論',
    '嘉義,協同中學,2019.03.23,河道作文．應用',
    '台南,康軒大場,2019.03.23,築壩．工程導論',
    '嘉義,朴子國中,2019.03.25,海貍戰隊Ｘ思維建構',
    '南投,南投高商,2019.03.25,河道作文．寫作導論',
    '台南,崇明國中,2019.03.26,思維建構與層次脈絡設計',
    '新北,淡江中學,2019.03.26,創作桌遊Ｘ尾巴巴呢',
    '花蓮,四維高中,2019.03.27,河道作文．結構',
    '新北,泰山國中,2019.03.28,思維導向閱讀教學',
    '新北,尖山國中,2019.03.28,河道作文．結構',
    '雲林,崇德國中,2019.03.28,河道作文．寫作導論',
    '新北,康橋國際中學,2019.03.29,河道作文．文采',
    '台中,康軒大場,2019.03.30,評量之後的評量',
    '高雄,三民高中,2019.03.30,河道作文．立意',
    '高雄,國光高中,2019.04.01,築壩．工程導論',
    '高雄,國光高中,2019.04.02,築壩．教學演示',
    '新北,清水高中國中部,2019.04.02,河道作文．立意',
    '新北,自強國中,2019.04.02,河道作文．寫作導論',
    '雲林,斗六國中,2019.04.02,河道作文．立意',
    '高雄,國光高中,2019.04.03,築壩．教學演示',
    '高雄,前鎮國中,2019.04.08,海貍戰隊Ｘ思維建構',
    '高雄,陽明國中,2019.04.08,築壩．工程導論',
    '屏東,東港高中,2019.04.08,死了一個警察之後',
    '桃園,大竹國中,2019.04.09,創作桌遊Ｘ尾巴巴呢',
    '新北,五股國中,2019.04.09,森林學園殺貍事件',
    '花蓮,慈濟中學,2019.04.10,教師表達力',
    '花蓮,花蓮高工,2019.04.10,河道作文・應用',
    '花蓮,慈濟大學,2019.04.10,反情緒勒索',
    '台北,成功高中,2019.04.11,築壩．工程導論',
    '新北,林口國中,2019.04.11,海貍戰隊Ｘ思維建構',
    '新北,尖山國中,2019.04.11,河道作文．取材',
    '台中,大甲國中,2019.04.12,河道作文・立意',
    '台中,立人國中,2019.04.12,河道作文・文采',
    '台中,光正國中,2019.04.12,雜學校殺貍事件',
    '彰化,彰興國中,2019.04.12,河道作文・文采',
    '新北,康橋國際中學,2019.04.12,河道作文．結構',
    '台中,向上國中,2019.04.13,河道作文．結構',
    '台中,向上國中,2019.04.13,河道作文．結構',
    '台中,衛道中學,2019.04.15,河道作文・取材',
    '高雄,道明中學,2019.04.15,評量之外的評量',
    '台北,成功高中,2019.04.16,雜學校殺貍事件',
    '新北,永和國中,2019.04.16,築壩．工程導論',
    '台南,台南高商,2019.04.18,河道作文・寫作導論',
    '新北,三重商工,2019.04.18,屋頂上的番茄樹',
    '台中,霧峰國中,2019.04.19,海貍戰隊Ｘ思維建構',
    '彰化,彰泰國中,2019.04.19,河道作文．立意',
    '新北,康橋國際中學,2019.04.19,河道作文．取材',
    '新北,康橋國際中學,2019.04.19,河道作文．應用',
    '嘉義,協同中學,2019.04.20,河道作文．實戰（壹）',
    '嘉義,協同中學,2019.04.20,河道作文．實戰（壹）',
    '花蓮,慈濟大學,2019.04.20,教師表達力/評量之外的評量',
    '高雄,陽明國中,2019.04.22,築壩．古典散文',
    '新北,尖山國中,2019.04.22,河道作文．應用',
    '花蓮,玉里高中,2019.04.24,河道作文．文采',
    '花蓮,慈濟大學,2019.04.24,非暴力溝通',
    '新北,崇林國中,2019.04.24,進擊的表達力',
    '台北,信義國中,2019.04.25,河道作文．文采',
    '台中,爽文國中,2019.04.26,河道作文．寫作導論',
    '台中,清泉國中,2019.04.26,河道作文．立意',
    '新竹,自強國中,2019.04.26,河道作文．取材',
    '新北,康橋國際中學,2019.04.26,河道作文．取材',
    '嘉義,協同中學,2019.04.27,河道作文．實戰（貳）',
    '宜蘭,員山國中,2019.04.30,河道作文．寫作導論',
    '台中,沙鹿高工,2019.05.01,廚房殺貍事件',
    '新北,崇林國中,2019.05.01,創作桌遊Ｘ尾巴巴呢',
    '台北,成功高中,2019.05.02,真相不只一個',
    '台北,再興中學,2019.05.02,河道作文．寫作導論',
    '台中,清泉國中,2019.05.03,創作桌遊Ｘ尾巴巴呢',
    '台中,惠文中學,2019.05.03,河道作文．寫作導論',
    '新北,康橋國際中學,2019.05.03,河道作文．應用/實戰',
    '新北,康橋國際中學,2019.05.03,河道作文．實戰（壹）',
    '桃園,復旦國中,2019.05.06,河道作文・應用',
    '花蓮,海星中學,2019.05.07,河道作文・立意',
    '高雄,左營高中,2019.05.08,思維導向策略/教師表達力',
    '台北,大理國中,2019.05.09,初階簡報設計課程',
    '新北,樹人家商,2019.05.09,海貍戰隊Ｘ思維建構',
    '新北,康橋國際中學,2019.05.10,河道作文．應用/實戰',
    '台南,北門農工,2019.05.13,河道作文．寫作導論',
    '台南,大灣中學,2019.05.14,教師表達力',
    '雲林,口湖國中,2019.05.14,河道作文・寫作導論',
    '花蓮,慈濟中學,2019.05.15,思維建構與層次脈絡設計',
    '花蓮,花蓮高工,2019.05.15,河道作文・應用',
    '花蓮,慈濟大學,2019.05.15,群島後之即興課程',
    '南投,旭光高中,2019.05.15,海貍戰隊Ｘ思維建構',
    '新竹,自強國中,2019.05.17,河道作文．應用',
    '新北,深坑國中,2019.05.17,寫作革命',
    '新北,康橋國際中學,2019.05.17,河道作文．實戰（貳）',
    '屏東,恆春工商,2019.05.20,河道作文・應用',
    '新北,林口國中,2019.05.21,思維建構與層次脈絡設計',
    '台北,內湖高工,2019.05.23,教師表達力',
    '台北,大理國中,2019.05.23,進階簡報設計課程',
    '台北,北投國中,2019.05.23,海貍戰隊Ｘ思維建構',
    '台北,信義國中,2019.05.23,河道作文．結構',
    '新北,明德高中,2019.05.23,河道作文．寫作導論',
    '台中,鹿寮國中,2019.05.24,河道作文・立意',
    '台中,大道國中,2019.05.24,你抓的是誰的寶貝',
    '台南,北門農工,2019.05.27,河道作文．立意',
    '屏東,東港高中,2019.05.27,教師表達力',
    '台北,信義國中,2019.05.29,誰殺了海貍',
    '台北,民生國中,2019.05.30,河道作文・文采',
    '台中,大道國中,2019.05.31,築壩．古典散文',
    '台北,信義國中,2019.05.31,誰殺了海貍',
    '新北,康橋國際中學,2019.05.31,河道作文．實戰（參）',
    '高雄,三民高中,2019.06.01,河道作文．文采',
    '台南,大灣中學,2019.06.04,造橋式表達',
    '花蓮,花蓮高工,2019.06.05,河道作文・實戰',
    '台北,方濟國中,2019.06.06,河道作文・取材',
    '新竹,成德高中,2019.06.10,河道作文．寫作導論',
    '台北,信義國中,2019.06.11,誰殺了海貍',
    '台北,信義國中,2019.06.11,誰殺了海貍',
    '台南,永仁中學,2019.06.11,河道作文．寫作導論',
    '台北,華興中學,2019.06.12,寫作革命',
    '新北,康橋國際中學,2019.06.14,河道作文．實戰（肆）',
    '台南,大灣中學,2019.06.18,非暴力溝通',
    '台中,沙鹿高工,2019.06.19,課本不會教的古人廢話',
    '台中,崇德國中,2019.06.21,築壩．工程導論',
    '高雄,鳳新高中,2019.06.24,河道作文．實戰',
    '台南,東陽國小,2019.07.01,教師表達力',
    '台南,東陽國小,2019.07.01,問題背後的問題(PAC)',
    '台南,中山國中,2019.07.01,河道導論 / 河道立意',
    '台南,中山國中,2019.07.02,河道文采 / 河道結構',
    '台南,中山國中,2019.07.03,河道取材/ 河道應用',
    '高雄,三民高中,2019.07.12,河道作文．結構',
    '台中,大業國中,2019.07.16,海貍戰隊Ｘ思維建構',
    '台中,大業國中,2019.07.17,河道文采／尾巴巴呢',
    '新北,崇林國中,2019.07.17,進擊的表達力',
    '台中,大業國中,2019.07.18,河道結構 / 河道取材（採訪）',
    '高雄,三民高中,2019.07.19,河道作文．取材',
    '台北,文創中心,2019.07.22,學期認證考',
    '南投,大成國中,2019.07.26,河道作文．寫作導論',
    '南投,大成國中,2019.07.26,海貍戰隊Ｘ思維建構',
    '高雄,三民高中,2019.07.27,河道作文．應用',
    '南投,大成國中,2019.08.02,河道作文．寫作導論',
    '南投,大成國中,2019.08.02,海貍戰隊Ｘ思維建構',
    '高雄,三民高中,2019.08.03,河道作文．實戰（壹）',
    '高雄,三民高中,2019.08.03,河道作文．實戰（貳）',
    '台中,清泉國中,2019.08.12,河道立意 / 河道文采',
    '新竹,三民國中,2019.08.12,河道導論 / 河道立意',
    '台中,清泉國中,2019.08.13,河道結構/ 河道取材',
    '新竹,三民國中,2019.08.13,河道文采 / 河道結構',
    '台中,清泉國中,2019.08.14,河道應用/ 河道實戰',
    '新竹,三民國中,2019.08.14,河道取材 / 河道應用',
    '基隆,基隆高中,2019.08.16,進擊的表達力',
    '高雄,三民高中,2019.08.19,河道．導論',
    '高雄,三民高中,2019.08.20,河道．立意',
    '高雄,三民高中,2019.08.21,河道．文采',
    '高雄,三民高中,2019.08.22,河道．結構',
    '高雄,三民高中,2019.08.23,河道．取材',
    '高雄,左營高中,2019.08.26,翻轉到翻滾：一隻貍的形成',
    '新北,永和國中,2019.08.29,精煉故事學',
    '嘉義,協同中學,2019.09.07,河道．導論',
    '台北,延平中學,2019.09.10,河道．導論',
    '台北,延平中學,2019.09.11,河道．導論',
    '台北,延平中學,2019.09.12,河道．導論',
    '高雄,左營高中,2019.09.16,河道．導論',
    '花蓮,四維高中,2019.09.16,河道作文・取材',
    '台北,延平中學,2019.09.17,河道．立意',
    '台北,延平中學,2019.09.18,河道．立意',
    '花蓮,慈濟中學,2019.09.18,築壩．古散',
    '台北,延平中學,2019.09.19,河道．立意',
    '台中,五權國中,2019.09.20,河道．立意',
    '台北,延平中學,2019.09.24,河道．文采',
    '台北,延平中學,2019.09.25,河道．文采',
    '台北,延平中學,2019.09.26,河道．文采',
    '彰化,彰安國中,2019.09.27,河道・結構',
    '高雄,左營高中,2019.09.30,河道．立意',
    '台北,延平中學,2019.10.01,河道．結構',
    '台北,延平中學,2019.10.02,河道．結構',
    '台北,金華國中,2019.10.02,河道．立意',
    '台北,延平中學,2019.10.03,河道．結構',
    '台北,可能非學校,2019.10.03,我思故我在',
    '彰化,田中中學,2019.10.04,海貍戰隊Ｘ思維建構',
    '高雄,鳳新高中,2019.10.07,築壩 · 工程導論',
    '高雄,資優中心,2019.10.07,河道 · 寫作導論',
    '新北,永和國中,2019.10.08,築壩．古典散文',
    '南投,仁愛國中,2019.10.09,課堂RPG',
    '高雄,左營高中,2019.10.14,河道．文采',
    '新北,林口國中,2019.10.16,聽故事 · 學故事',
    '南投,普台中學,2019.10.16,河道 · 寫作導論',
    '新北,三重高中,2019.10.17,河道 · 寫作導論',
    '高雄,三民高中,2019.10.19,海貍戰隊Ｘ思維建構',
    '嘉義,協同中學,2019.10.19,河道．立意',
    '高雄,陽明國中,2019.10.21,築壩．現代散文',
    '新竹,光華國中,2019.10.21,河道．寫作導論',
    '台北,延平中學,2019.10.22,河道．取材',
    '新北,可能非學校,2019.10.22,從文字到文學/所有書寫的起點',
    '新北,自強國中,2019.10.22,河道．立意',
    '台中,光榮國中,2019.10.23,寫作革命',
    '台北,金華國中,2019.10.23,河道．文采',
    '台北,延平中學,2019.10.23,河道．取材',
    '花蓮,慈濟中學,2019.10.23,築壩．現代散文',
    '台北,延平中學,2019.10.24,河道．取材',
    '新北,可能非學校,2019.10.24,紙袋公主繪本',
    '台中,大道國中,2019.10.25,築壩．現代散文',
    '高雄,左營高中,2019.10.28,河道．結構',
    '台北,中研院文哲所,2019.10.31,真相不只一個',
    '新北,南山中學,2019.10.31,河道．寫作導論',
    '高雄,鳳新高中,2019.11.04,築壩 · 古典散文',
    '高雄,資優中心,2019.11.04,河道 · 立意',
    '台北,延平中學,2019.11.05,河道．應用',
    '台北,延平中學,2019.11.06,河道．應用',
    '台北,金華國中,2019.11.06,河道．結構',
    '台北,信義國中,2019.11.07,河道．取材',
    '台北,延平中學,2019.11.07,河道．應用',
    '台中,新社國中,2019.11.08,多元選修課程設計分享',
    '彰化,北斗國中,2019.11.08,海貍戰隊Ｘ思維建構',
    '台北,延平中學,2019.11.12,河道．實戰（壹）',
    '新北,可能非學校,2019.11.12,中學班群',
    '台北,延平中學,2019.11.13,河道．實戰（壹）',
    '花蓮,慈濟中學,2019.11.13,築壩．古典韻文',
    '花蓮,慈濟大學醫學系,2019.11.13,講師表達力',
    '南投,普台中學,2019.11.13,河道 · 立意',
    '台北,中研院文哲所,2019.11.14,雜學校殺貍事件',
    '台北,延平中學,2019.11.14,河道．實戰（壹）',
    '新北,可能非學校,2019.11.14,小學班群',
    '高雄,三民高中,2019.11.16,河道．應用',
    '高雄,陽明國中,2019.11.18,築壩．古典韻文',
    '台北,延平中學,2019.11.19,河道．實戰（貳）',
    '彰化,大同國中,2019.11.19,森林學園殺貍事件',
    '台北,延平中學,2019.11.20,河道．實戰（貳）',
    '台北,金華國中,2019.11.20,河道．取材',
    '台北,延平中學,2019.11.21,河道．實戰（貳）',
    '台中,大道國中,2019.11.22,真相不只一個',
    '台中,西苑國中,2019.11.22,下輩子別當老師',
    '台中,向上國中,2019.11.22,河道作文．文采',
    '彰化,彰安國中,2019.11.22,河道作文・取材',
    '嘉義,協同中學,2019.11.23,河道．文采',
    '高雄,左營高中,2019.11.25,河道．取材',
    '嘉義,嘉義家職,2019.11.27,海貍戰隊Ｘ思維建構',
    '新北,可能非學校,2019.11.28,中學班群',
    '高雄,三民高中,2019.11.30,你憤怒誰的正義',
    '高雄,資優中心,2019.12.02,河道 · 文采',
    '台北,華興中學,2019.12.03,寫作革命',
    '新北,自強國中,2019.12.03,河道作文．立意',
    '台北,金華國中,2019.12.04,河道．應用',
    '新北,南山中學,2019.12.05,河道作文．立意',
    '嘉義,協同中學,2019.12.07,河道．結構',
    '新竹,光華國中,2019.12.09,河道作文．立意',
    '花蓮,花蓮高中,2019.12.09,死了一個警察之後',
    '台南,六甲國中,2019.12.10,課堂RPG',
    '新北,永和國中,2019.12.10,築壩．現代散文',
    '南投,普台中學,2019.12.11,河道作文 · 文采',
    '台南,台南高商,2019.12.12,河道作文 · 文采',
    '新北,南山中學,2019.12.12,河道作文．文采',
    '台北,台大教程學會,2019.12.14,講師表達力',
    '宜蘭,國華國中,2019.12.15,河道．立意 / 河道 · 文采',
    '高雄,左營高中,2019.12.16,河道．應用',
    '新北,萬里國中,2019.12.17,海貍戰隊Ｘ思維建構',
    '台北,金華國中,2019.12.18,河道．實戰',
    '花蓮,慈濟中學,2019.12.18,築壩．現代韻文',
    '新北,南山中學,2019.12.19,河道作文．結構',
    '新北,崇光中學,2019.12.21,進擊的表達力',
    '新北,崇光中學,2019.12.21,海貍戰隊Ｘ思維建構',
    '台南,北門農工,2019.12.23,築壩 · 古典散文',
    '新北,五峰國中,2019.12.24,海貍戰隊Ｘ思維建構',
    '屏東,屏榮高中,2019.12.25,河道 · 寫作導論',
    '台中,忠明中學,2019.12.27,河道作文・立意',
    '新北,崇光中學,2019.12.27,進擊的表達力',
    '高雄,左營高中,2019.12.30,河道．實戰',
    '宜蘭,國華國中,2019.12.31,河道作文・結構',
    '嘉義,協同中學,2020.04.27,河道作文．實戰（貳）',
  ],
  2020: [
    '台中,新社國中,2020.06.19,河道作文・實戰',
    '台中,新社國中,2020.05.29,河道作文・應用',
    '台中,大道國中,2020.05.15,築壩閱讀．現代韻文',
    '台中,五權國中,2020.05.08,河道作文．文采',
    '台中,北勢國中,2020.05.07,海貍戰隊',
    '台中,圓喜工作坊,2020.05.03,單日遊戲工作坊',
    '台中,新社國中,2020.05.01,河道作文・取材',
    '台中,大道國中,2020.04.24,築壩閱讀．古典韻文',
    '台中,新社國中,2020.04.10,河道作文・結構',
    '台中,忠明中學,2020.03.27,河道作文・文采',
    '台中,新社國中,2020.03.13,河道作文・立意+文采',
    '台中,益品書屋,2020.03.06,小貍班初階',
    '台中,益品書屋,2020.02.21,小貍班初階',
    '台中,圓喜工作坊,2020.02.08,築壩工作坊',
    '台中,圓喜工作坊,2020.02.07,築壩工作坊',
    '台中,圓喜工作坊,2020.02.06,築壩工作坊',
    '台中,新社國中,2020.01.10,河道作文・導論+立意',
    '台中,至善國中,2020.01.03,河道作文・導論',
    '台北,復興中學,2020.08.25,河道寫作系列課程',
    '台北,復興中學,2020.08.25,河道寫作系列課程',
    '台北,復興中學,2020.08.24,河道寫作系列課程',
    '台北,復興中學,2020.08.18,河道寫作系列課程',
    '台北,復興中學,2020.08.18,河道寫作系列課程',
    '台北,復興中學,2020.08.18,河道寫作系列課程',
    '台北,復興中學,2020.08.17,河道寫作系列課程',
    '台北,復興中學,2020.08.17,河道寫作系列課程',
    '台北,復興中學,2020.08.17,河道寫作系列課程',
    '台北,復興中學,2020.08.11,河道寫作系列課程',
    '台北,復興中學,2020.08.11,河道寫作系列課程',
    '台北,復興中學,2020.08.11,河道寫作系列課程',
    '台北,復興中學,2020.08.10,河道寫作系列課程',
    '台北,復興中學,2020.08.10,河道寫作系列課程',
    '台北,復興中學,2020.08.10,河道寫作系列課程',
    '台北,文創中心,2020.08.07,竹節評量工作坊(2)',
    '台北,文創中心,2020.08.06,竹節評量工作坊(2)',
    '台北,文創中心,2020.08.05,竹節評量工作坊(2)',
    '台北,復興中學,2020.08.04,河道寫作系列課程',
    '台北,復興中學,2020.08.04,河道寫作系列課程',
    '台北,復興中學,2020.08.04,河道寫作系列課程',
    '台北,復興中學,2020.08.03,河道寫作系列課程',
    '台北,復興中學,2020.08.03,河道寫作系列課程',
    '台北,弘道國中,2020.07.31,河道作文・應用',
    '台北,弘道國中,2020.07.30,河道作文・取材',
    '台北,文創中心,2020.07.29,築壩奠基工作坊(7)',
    '台北,弘道國中,2020.07.29,河道作文・結構',
    '台北,文創中心,2020.07.28,築壩奠基工作坊(7)',
    '台北,復興中學,2020.07.28,河道寫作系列課程',
    '台北,復興中學,2020.07.28,河道寫作系列課程',
    '台北,復興中學,2020.07.28,河道寫作系列課程',
    '台北,弘道國中,2020.07.28,河道作文・文采',
    '台北,弘道國中,2020.07.27,河道作文．立意',
    '台北,復興中學,2020.07.27,河道寫作系列課程',
    '台北,復興中學,2020.07.27,河道寫作系列課程',
    '台北,復興中學,2020.07.27,河道寫作系列課程',
    '台北,文創中心,2020.07.27,築壩奠基工作坊(7)',
    '台北,文創中心,2020.07.24,河道源頭工作坊(8)',
    '台北,文創中心,2020.07.23,河道源頭工作坊(8)',
    '台北,文創中心,2020.07.22,河道源頭工作坊(8)',
    '台北,延平中學,2020.07.08,河道作文・取材',
    '台北,延平中學,2020.07.07,河道作文・取材',
    '台北,延平中學,2020.07.01,河道作文・結構',
    '台北,延平中學,2020.06.30,河道作文・結構',
    '台北,延平中學,2020.06.24,河道作文・文采',
    '台北,延平中學,2020.06.23,河道作文・文采',
    '台北,延平中學,2020.06.17,河道作文・立意',
    '台北,延平中學,2020.06.16,河道作文・立意',
    '台北,延平中學,2020.06.10,河道作文・導論',
    '台北,延平中學,2020.06.09,河道作文・導論',
    '台北,文創中心,2020.06.07,單日遊戲工作坊',
    '台北,復興中學,2020.05.28,河道作文 · 寫作導論',
    '台北,弘道中學,2020.05.28,河道作文・文采',
    '台北,信義國中,2020.05.14,河道作文．應用',
    '台北,金華國中,2020.05.12,你是獨一無二的存在—生活中的刻板印象',
    '台北,金華國中,2020.05.05,海岸景觀第一排—挖掘用戶需求',
    '台北,弘道中學,2020.04.30,河道作文・立意',
    '台北,金華國中,2020.04.28,有意思，有藝式—如何說明產品功能',
    '台北,中正國中,2020.04.23,教師表達力',
    '台北,金華國中,2020.04.21,怪奇物語—物件與情感連結',
    '台北,金華國中,2020.03.31,你是獨一無二的存在—生活中的刻板印象',
    '台北,弘道中學,2020.03.26,河道作文・寫作導論',
    '台北,金華國中,2020.03.24,我的使用手冊—如何掌握表達要點',
    '台北,中研院,2020.02.14,教育新範式+講師表達力',
    '台南,文創園區,2020.08.21,竹節評量工作坊(2)',
    '台南,文創園區,2020.08.20,竹節評量工作坊(2)',
    '台南,文創園區,2020.08.19,竹節評量工作坊(2)',
    '台南,文創園區,2020.08.16,築壩奠基工作坊(7)',
    '台南,文創園區,2020.08.15,築壩奠基工作坊(7)',
    '台南,文創園區,2020.08.14,築壩奠基工作坊(7)',
    '台南,文創園區,2020.08.12,河道源頭工作坊(8)',
    '台南,文創園區,2020.08.11,河道源頭工作坊(8)',
    '台南,文創園區,2020.08.10,河道源頭工作坊(8)',
    '台南,港明中學,2020.05.27,河道作文・寫作導論',
    '台南,六甲國中,2020.05.26,評量之外的評量',
    '台南,六甲國中,2020.05.19,思維導向閱讀策略',
    '台南,沙崙國中,2020.04.28,河道作文・寫作導論',
    '台南,六甲國中,2020.04.14,校園殺貍事件',
    '台南,六甲國中,2020.03.24,海貍戰隊',
    '台南,六甲國中,2020.03.10,你憤怒誰的正義',
    '台南,佳興國中,2020.02.19,簡報美學',
    '台南,大灣中學,2020.01.21,進擊的表達力+設計思考',
    '台南,北門農工,2020.01.13,築壩閱讀．現代散文',
    '宜蘭,國華國中,2020.05.12,教師表達力',
    '宜蘭,國華國中,2019.04.21,河道作文・實戰',
    '宜蘭,國華國中,2019.03.31,河道作文・應用',
    '宜蘭,國華國中,2019.03.24,河道作文・取材',
    '花蓮,慈濟小學,2020.08.21,主題讀寫工作坊',
    '花蓮,慈濟小學,2020.08.20,主題讀寫工作坊',
    '花蓮,慈濟中學,2020.06.17,微築壩閱讀．實作',
    '花蓮,慈濟中學,2020.04.15,築壩閱讀．古今小說',
    '花蓮,慈濟大學,2020.04.15,非暴力溝通',
    '花蓮,慈濟大學,2020.03.30,淺談教育新範式',
    '南投,普台中學,2020.03.04,河道作文・結構+應用',
    '南投,普台中學,2020.01.15,河道作文・結構+取材',
    '屏東,中正國中,2020.04.20,河道作文・寫作導論',
    '桃園,龜山國中,2020.08.04,會考應試策略分析',
    '桃園,龜山國中,2020.08.04,河道作文 · 寫作導論',
    '桃園,龍潭高中,2020.07.01,河道作文・取材',
    '桃園,龍潭高中,2020.06.24,河道作文・結構',
    '桃園,龍潭高中,2020.06.10,河道作文・文采',
    '桃園,草漯國中,2020.06.09,河道作文・導論',
    '桃園,龍潭高中,2020.06.03,河道作文・導論+立意',
    '桃園,光啟高中,2020.03.25,海貍戰隊',
    '高雄,瑞祥高中,2020.07.16,河道寫作營隊',
    '高雄,瑞祥高中,2020.07.15,河道寫作營隊',
    '高雄,左營高中,2020.07.03,高一閱讀成果發表評審',
    '高雄,資優中心（三民國中）,2020.06.29,河道作文・應用',
    '高雄,陽明國中,2020.06.22,築壩閱讀．古今小說',
    '高雄,資優中心（三民國中）,2020.06.01,河道作文・取材',
    '高雄,三民高中,2020.05.23,築壩式閱讀',
    '高雄,鳳新高中,2020.05.11,築壩閱讀．古今小說',
    '高雄,資優中心（三民國中）,2020.05.11,河道作文・結構',
    '高雄,鳳新高中,2020.04.13,築壩閱讀．現代韻文',
    '高雄,陽明國中,2020.04.06,築壩閱讀．現代韻文',
    '高雄,福誠高中,2020.03.16,河道作文・寫作導論',
    '高雄,鳳新高中,2020.03.09,築壩閱讀．古典韻文',
    '高雄,鳳新高中,2020.01.06,築壩閱讀．現代散文',
    '基隆,八斗高中,2020.05.07,教師表達力',
    '新北,滬江高中,2020.07.02,教師表達力',
    '新北,坪林國中,2020.07.01,聯想金字塔+創意九宮格',
    '新北,重慶國中,2020.06.16,海貍戰隊',
    '新北,樹林中學,2020.06.15,海貍戰隊',
    '新北,重慶國中,2020.06.12,海貍戰隊',
    '新北,重慶國中,2020.06.11,海貍戰隊',
    '新北,樹林中學,2020.06.08,海貍戰隊',
    '新北,重慶國中,2020.06.08,海貍戰隊',
    '新北,重慶國中,2020.06.05,海貍戰隊',
    '新北,樹林中學,2020.06.04,海貍戰隊',
    '新北,樹林中學,2020.06.04,海貍戰隊',
    '新北,樹林中學,2020.06.03,海貍戰隊',
    '新北,樹林中學,2020.06.02,海貍戰隊',
    '新北,重慶國中,2020.06.02,海貍戰隊',
    '新北,樹林中學,2020.06.01,海貍戰隊',
    '新北,樹林中學,2020.05.29,海貍戰隊',
    '新北,重慶國中,2020.05.28,海貍戰隊',
    '新北,重慶國中,2020.05.27,海貍戰隊',
    '新北,重慶國中,2020.05.27,海貍戰隊',
    '新北,重慶國中,2020.05.26,海貍戰隊',
    '新北,溪崑國中,2020.05.21,海貍戰隊',
    '新北,溪崑國中,2020.05.21,海貍戰隊',
    '新北,溪崑國中,2020.05.20,海貍戰隊',
    '新北,南山中學,2020.04.30,河道作文・實戰 4',
    '新北,南山中學,2020.04.23,河道作文・實戰 3',
    '新北,南山中學,2020.04.16,河道作文・實戰 2',
    '新北,達觀國中,2020.04.14,河道作文・立意',
    '新北,南山中學,2020.04.09,河道作文・實戰 1',
    '新北,輔仁大學,2020.04.07,淺談教育新範式',
    '新北,南山中學,2020.03.19,河道作文・應用',
    '新北,達觀國中,2020.03.17,河道作文・寫作導論',
    '新北,坪林國中,2020.03.11,人物夢工廠+幸運草思考法',
    '新北,南山中學,2020.01.02,河道取材',
    '新竹,三民國中,2020.07.30,主題讀寫營隊',
    '新竹,三民國中,2020.07.29,主題讀寫營隊',
    '新竹,三民國中,2020.07.28,主題讀寫營隊',
    '新竹,竹東高中,2020.07.06,思辨 · 番茄樹',
    '新竹,竹東高中,2020.06.29,思維導向閱讀教學',
    '新竹,博愛國小,2020.06.10,翻滾吧！作文',
    '新竹,光華國中,2020.06.08,河道作文・結構',
    '新竹,輔導團,2020.05.25,築壩閱讀．導論',
    '新竹,光華國中,2020.04.06,河道作文・文采',
    '新竹,建功國中,2019.03.30,河道作文・寫作導論',
    '嘉義,竹崎國中,2020.04.27,河道作文・寫作導論',
    '彰化,彰興國中,2020.04.24,河道作文・結構',
    '澎湖,馬公國中,2020.07.24,主題讀寫營隊',
    '澎湖,馬公國中,2020.07.23,主題讀寫營隊',
    '澎湖,馬公國中,2020.07.22,主題讀寫營隊',
    '澎湖,馬公國中,2020.05.30,河道作文・結構+取材',
    '澎湖,馬公國中,2020.04.18,河道作文・立意+文采',
  ],
}

export default footprint
