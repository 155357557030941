import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'
import { StyledContentBlock, StyledContentNote, StyledLabel, StyledSection, StyledSubtitle } from './index.styled'

const primaryColor = '#bf804a'

const StyledEducatorSection = styled(StyledSection)`
  text-align: center;
`
const StyledEducatorLabel = styled(StyledLabel)`
  color: ${primaryColor};
  background-color: ${primaryColor}1a;
`
const StyledEducatorContentsBlock = styled.div`
  margin-bottom: 40px;
`
const StyledEducatorContents = styled.div`
  p {
    text-align: center;
  }
  ${desktopViewMixin(css`
    p {
      text-align: left;
    }
  `)}
`
const StyledEducatorContent = styled.div`
  font-weight: 500;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: justify;
  color: var(--gray-darker);
  text-align: center;
  p {
    margin-bottom: 0.25rem;
  }
`
const StyledContentSubtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  color: var(--gray-darker);
  justify-content: center;
  ${desktopViewMixin(css`
    justify-content: start;
  `)}
`
const StyledSubtitleDecoration = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${primaryColor};
  margin-right: 8px;
`

const EducatorSection: React.FC = () => {
  return (
    <div>
      <StyledEducatorSection>
        <div className="container">
          <p>
            迎向新課綱的年代，一個教育工作者需要的增能課程，海貍都為你準備好了！舉凡閱讀寫作、教材編組、溝通表達、批判思考、評量設計、遊戲應用乃至於課程發展⋯⋯，進入海貍迷人的解構建構世界，豐足教學系統，讓你優雅面對多變的教學環境！
          </p>
        </div>
      </StyledEducatorSection>
      <StyledSubtitle>教師研習</StyledSubtitle>
      <StyledEducatorContentsBlock className="container d-flex flex-wrap px-0">
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>思維工程</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>海貍戰隊．思維建構 * </p>
            <p>讀寫結合．鷹架解構 * </p>
            <p>教學設計．提問設計與層次脈絡</p>
          </StyledEducatorContent>
          <StyledContentNote>*標註代表依文本為主</StyledContentNote>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>河道源頭</StyledEducatorLabel>
          <StyledEducatorContents>
            <StyledEducatorContent className="d-flex justify-content-around flex-wrap">
              <div>
                <p>導論 </p>
                <p>立意 </p>
                <p>文采</p>
                <p>結構</p>
              </div>
              <div>
                <p>取材</p>
                <p>應用 </p>
                <p>實戰</p>
              </div>
            </StyledEducatorContent>
          </StyledEducatorContents>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>河道逕流</StyledEducatorLabel>
          <StyledEducatorContent className="d-flex justify-content-around flex-wrap">
            <div>
              <p>導論 </p>
              <p>文氣 </p>
              <p>批閱</p>
            </div>
            <div>
              <p>製題</p>
              <p>評量 </p>
              <p>實戰</p>
            </div>
          </StyledEducatorContent>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>河道湖海</StyledEducatorLabel>
          <StyledEducatorContent className="d-flex justify-content-around flex-wrap">
            <div>
              <p>文類概說 </p>
              <p>主題文學 * </p>
              <p>熱點解析</p>
            </div>
            <div>
              <p>專題設計</p>
              <p>發表回饋 </p>
              <p>實戰</p>
            </div>
          </StyledEducatorContent>
          <StyledContentNote>*標註代表依專題為主</StyledContentNote>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>築壩奠基</StyledEducatorLabel>
          <StyledEducatorContents>
            <StyledEducatorContent className="d-flex justify-content-around flex-wrap">
              <div>
                <p>工程導論 </p>
                <p>古典散文 </p>
                <p>現代散文</p>
              </div>
              <div>
                <p>古典韻文</p>
                <p>現代韻文 </p>
                <p>古今小說</p>
              </div>
            </StyledEducatorContent>
          </StyledEducatorContents>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>微．築壩</StyledEducatorLabel>
          <StyledEducatorContents>
            <StyledEducatorContent className="d-flex justify-content-around flex-wrap">
              <div>
                <p>古典散文</p>
                <p>現代散文</p>
                <p>古典韻文</p>
              </div>
              <div>
                <p>現代韻文</p>
                <p>古今小說</p>
                <p>跨域解構</p>
              </div>
            </StyledEducatorContent>
          </StyledEducatorContents>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>竹節評量</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>從學習到評量</p>
            <p>好的評量</p>
            <p>創新後的限制</p>
            <p>限制下的創新</p>
            <p>創新後的限制</p>
            <p>好的回饋</p>
            <p>從評量到學習</p>
          </StyledEducatorContent>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>造橋表達</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>教學表達</p>
            <p>國學罐頭語文活動</p>
            <p>遊戲融入</p>
            <p>綜合演練</p>
            <p>愛在衝突時（NVC）</p>
            <p>人際溝通理論分析（PAC）</p>
          </StyledEducatorContent>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>樹冠思辨</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>THIS IS WATER</p>
            <p>屋頂上番茄樹</p>
            <p>兩兄弟</p>
            <p>真相不只一個</p>
            <p>你信仰誰的神明</p>
            <p>你憤怒誰的正義</p>
            <p>你抓的是誰的寶貝</p>
            <p>你的努力有用嗎</p>
            <p>你為誰道的歉</p>
            <p>你有蹺課的義務</p>
            <p>人生成績單</p>
            <p>生而為人的這些那些（儒家篇）</p>
          </StyledEducatorContent>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-lg-9">
          <StyledEducatorLabel>遊戲</StyledEducatorLabel>
          <StyledEducatorContents className="d-flex flex-wrap">
            <StyledEducatorContent className="col-12 col-sm-4 mb-3">
              <StyledContentSubtitle className="d-flex align-items-center">
                <StyledSubtitleDecoration />
                尾巴巴呢
              </StyledContentSubtitle>
              <p>趣味寫作的多元應用</p>
            </StyledEducatorContent>
            <StyledEducatorContent className="col-12 col-sm-4 mb-3">
              <StyledContentSubtitle className="d-flex align-items-center">
                <StyledSubtitleDecoration />
                誰殺了海貍
              </StyledContentSubtitle>
              <p>雜學校殺貍事件</p>
              <p>辦公室殺貍事件</p>
              <p>烏克麗麗殺貍事件</p>
              <p>廚房殺貍事件</p>
              <p>森林學園殺貍事件</p>
              <p>畢業季殺貍事件</p>
              <p>LINE@殺貍事件</p>
            </StyledEducatorContent>
            <StyledEducatorContent className="col-12 col-sm-4">
              <StyledContentSubtitle className="d-flex align-items-center">
                <StyledSubtitleDecoration />
                超時空偵探
              </StyledContentSubtitle>
              <p>死了一個警察之後</p>
              <p>死了一個酒鬼之後</p>
              <p>走丟了一個老人之後</p>
              <p>LINE@遊戲設計</p>
            </StyledEducatorContent>
          </StyledEducatorContents>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>課程調理專題探討</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>有趣</p>
            <p>有用</p>
            <p>有感</p>
          </StyledEducatorContent>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>社群經營課程共備</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>診斷與共識</p>
            <p>模組與原型</p>
            <p>包裝與評量</p>
            <p>回饋與迭代</p>
          </StyledEducatorContent>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>特色課程跨域結合</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>簡報設計</p>
            <p>設計思考</p>
            <p>創業課程</p>
            <p>思維導向的閱讀策略</p>
          </StyledEducatorContent>
        </StyledContentBlock>
        <StyledContentBlock className="col-12 col-sm-6 col-lg-3">
          <StyledEducatorLabel>專題講座</StyledEducatorLabel>
          <StyledEducatorContent>
            <p>古典新解．下輩子別當國文老師</p>
            <p>教學應用．君子堂寫作實務</p>
            <p>生活美學．道在屎溺中</p>
            <p>親子教養．阿德勒</p>
            <p>桌遊教學．一款桌遊百搭教學</p>
            <p>邏輯思維．你的邏輯不是你的邏輯</p>
            <p>思維可視．看見無界限</p>
          </StyledEducatorContent>
        </StyledContentBlock>
      </StyledEducatorContentsBlock>
    </div>
  )
}

export default EducatorSection
