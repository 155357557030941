import { Button } from 'antd'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const StyledSection = styled.section`
  position: relative;
  padding: 7.5rem 1rem 5rem;
  background: white;
  @media screen and (max-width: 768px) {
    padding-top: 3rem;
  }
  ::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110px;
    height: 155px;
    background-image: url(https://static.kolable.com/images/beaver/beaver-04.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: ' ';
  }
  ::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 119px;
    height: 145px;
    background-image: url(https://static.kolable.com/images/beaver/beaver-05.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: ' ';
  }

  ${desktopViewMixin(css`
    ::before {
      width: 207px;
      height: 292px;
      left: calc(50% - 14rem);
      transform: translateX(-70%);
    }
    ::after {
      width: 210px;
      height: 294px;
      left: calc(50% + 14rem);
      transform: translateX(-30%);
    }
  `)}
`
const ContentBox = styled.div`
  margin: 0 auto;
  padding: 3.5rem 0;
  max-width: 28rem;
  background: white;
  text-align: center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
`
const StyledMeta = styled.div`
  font-size: 16px;
  color: #f29844;
  font-weight: bold;
  letter-spacing: 0.2px;
`
const StyledDescription = styled.div`
  color: var(--gray-darker);
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.2px;
`
const StyledButton = styled(Button)`
  padding: 10px 56px;
  span {
    line-height: 1;
  }
`

const FindOutMoreSection: React.FC = () => {
  return (
    <StyledSection>
      <ContentBox>
        <StyledMeta className="mb-1">懷抱著熱忱</StyledMeta>
        <StyledDescription>讓我們一起改變教育</StyledDescription>
        <Link to="/projects/e8316ede-9e0c-49e8-9302-90c286f0c493">
          <StyledButton type="primary">了解方案</StyledButton>
        </Link>
      </ContentBox>
    </StyledSection>
  )
}

export default FindOutMoreSection
