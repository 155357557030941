import styled from 'styled-components'

export type SectionID = 'introduction' | 'destiny' | 'footprint' | 'teamMember' | 'feedback'

export const StyledSection = styled.section`
  padding: 2.5rem 0;
  .container {
    max-width: 38.75rem;
  }
  p {
    color: var(--gray-darker);
    margin-bottom: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`

export const SectionTitle = styled.div`
  margin-bottom: 1.5rem;
  padding-left: 0.75rem;
  border-left: 4px solid #2c7eae;
  color: var(--gray-darker);

  div {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.23px;
    line-height: normal;
  }
  sub {
    color: var(--gray-dark);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: var(--gray-dark);
  }
`
