import { BREAK_POINT } from 'lodestar-app/src/components/common/Responsive'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import { ReactComponent as AngleLeftIcon } from 'lodestar-app/src/images/angle-left.svg'
import { ReactComponent as AngleRightIcon } from 'lodestar-app/src/images/angle-right.svg'
import React from 'react'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import { SectionTitle } from './index.styled'

const StyledSection = styled.section`
  padding: 5rem;
  background: white;
  @media screen and (max-width: 768px) {
    padding: 3rem 1rem 1rem 1rem;
  }
  ${desktopViewMixin(css`
    padding: 7.5rem;
  `)}
`
const StyledSlider = styled(Slider)`
  .slick-dots {
    li button::before {
      font-size: 12px;
      color: var(--gray);
    }
    li.slick-active button::before {
      font-size: 16px;
      color: #f29844;
    }
  }
`
const StyledItem = styled.div`
  width: 380px;
  padding: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const StyledItemTitle = styled.div`
  color: var(--gray-darker);
  font-weight: bold;
  letter-spacing: 0.2px;
  height: 56px;
`
const StyledItemDescription = styled.p`
  font-size: 16px;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
const StyledDialogBlock = styled.div`
  position: relative;
  margin-bottom: 60px;
  background: white;
  font-size: 16px;
  line-height: 1.69;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  &::after {
    position: absolute;
    left: -10px;
    bottom: 0;
    width: 60px;
    height: 60px;
    content: url(https://static.kolable.com/images/xuemi/dialog-pionter.svg);
    transform: translateY(99%);
  }

  p {
    color: #9b9b9b;
    white-space: pre-wrap;
  }

  a {
    color: #f29844;
  }
`
const DialogCover = styled.div<{ coverUrl?: string }>`
  padding-top: calc(21400% / 380);
  background-image: url('${props => props.coverUrl}');
  background-size: cover;
  background-position: center;
`
const StyledUserBlock = styled.div`
  display: flex;
  align-items: center;
  color: #585858;
  font-size: 14px;

  img {
    margin-right: 24px;
    width: 64px;
  }
  span {
    padding-bottom: 8px;
  }
`
const StyledArrow = styled.div<{ variant: 'left' | 'right' }>`
  && {
    top: 40%;
    ${props => (props.variant === 'left' ? 'left: -140px;' : 'right: -140px;')}
    transform: ${props => (props.variant === 'left' ? 'translateX(100%)' : 'translate(-100%)')};
    background-color: #fdf5ec;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    font-size: 64px;
    text-align: center;
    line-height: unset;
    z-index: 1;

    ::before {
      content: '';
    }

    @media screen and (max-width: 768px) {
      ${props => (props.variant === 'left' ? 'left: -72px;' : 'right: -72px;')}
    }

    & svg {
      height: 32px;
      width: 32px;
      transform: ${props => (props.variant === 'left' ? 'translate(10px,12px)' : 'translate(14px,12px)')};
      path {
        fill: #f29844;
      }
    }
  }
`

const SliderArrow: React.FC<{
  variant: 'left' | 'right'
}> = props => {
  return <StyledArrow {...props}>{props.variant === 'left' ? <AngleLeftIcon /> : <AngleRightIcon />}</StyledArrow>
}

const reviews: {
  coverUrl: string
  title: string
  description: string
  link: string
  author: string
}[] = [
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/01.jpg',
    title: '教育創新100｜翻滾海貍工作室，圈出鐵粉老師包課研習',
    description:
      '不同於以往研習動輒數十、上百人，且內容偏向單向講授，學習效果往往受限。翻滾海貍的課程特色是小班制教學，著重雙向互動的全流程體驗，手把手陪伴學員共同成長。',
    link: 'https://reurl.cc/9XQR1X',
    author: '親子天下報導',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/02.jpg',
    title: '跨域、跨世代的教育者交流，共創迪士尼遊樂園的學習體驗',
    description:
      '除了以遊戲引發學習動機，翻滾海貍也著力於師資培育與設計教材教法，推廣思維教育。翻滾海貍推出的「河道式作文」、「築壩式閱讀」等教材教法，吸引許多國文老師學習。',
    link: 'https://reurl.cc/bRylEr',
    author: '現場新手教師',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/03.jpg',
    title: '教育共學生態社群．貍想教育創新學院',
    description:
      '現場教師的「再增能」，絕對是促成教育轉型的關鍵。海貍們希望能一改專注於「教」的狀態，更重視建構一個「學」的場合。也許看起來「不教」，卻更能從中「學著如何學習」，讓他即使離開教學環境，依然能自主學習。',
    link: 'https://reurl.cc/N6LZV6',
    author: '遠見天下報導',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/04.jpg',
    title: '有意識地翻轉意識——專訪翻滾海貍工作室創辦人楊子漠',
    description:
      '如果說海貍的翻滾教室是一個社團，總舵主楊子漠無疑是個很理工腦的寫作教師。海貍的河道式作文是閱讀思辨寫作表達遊戲五大區塊之一，她說國文課就是大量解、大量建，從中導向思考的兩條脈絡。',
    link: 'https://reurl.cc/6leQE5',
    author: '明道中學報導',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/05.jpg',
    title: '【2018翻滾海貍・怎樣教育】參與後記',
    description:
      '未來教育的思辨談的是「寬容」，教育是為了使我們擁有的思考，得以寬容的面對生活；老派教育的思辨，是在慎思明辨的過程中，培養寬容有情的目光；怎樣教育的思辨，是思考思考本身，釐清思考形狀，建立以求真求善為前提的思考歷程。',
    link: 'https://reurl.cc/3LZejR',
    author: 'Tequila 工作室報導',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/06.jpg',
    title: '教學是思維的解構與重建　翻轉國文教育的海貍教師們',
    description:
      '「深入是涵養，淺出是功夫。卡牌只是包裝，不能誤以為用遊戲，學生就應該要喜歡你的教學。」作為第一線教育工作者，海貍老師說：「我們必須先引起學生的學習動機，才有辦法增進學生的學習能力，進而提高學習的續航力。」',
    link: 'https://reurl.cc/N6Eypm',
    author: '雜學校報導',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/07.jpg',
    title: '【青色組織三日工作坊記錄】',
    description:
      '「如果三天能創建一個青色團隊，三個月能否改造一所學校？三個季度能否帶動社會氛圍的變化？三年能否推進一個時代的教育範式？……」',
    link: 'https://reurl.cc/6leQZk',
    author: '魚水教育工作坊報導',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/08.jpg',
    title: '【教學黃磚路】我的第二個實習人生',
    description:
      '用第一性原理觀看海貍體系，可以隱約體察海貍傾靠懷德海的「教育韻律論」、杜威的「實踐中學習」，也可以了解從行為主義、經驗主義出發的海貍，為何提出「教育運動機能學」的思考，更可以理解，海貍必然走向充滿共學精神的「教育3.0」之路，也與快速響應變化的「敏捷開發」、自主管理的「青色組織」、嵌套式團隊的「合弄制」等等方法高度合拍。',
    link: 'https://reurl.cc/odq513',
    author: '翻滾吧！小豾 部落格紀錄',
  },
  {
    coverUrl: 'https://static.kolable.com/images/beaver/reviews/09.png',
    title: '貍想教育創新學院的前世今生',
    description:
      '教育創新不是為了推翻這個世代，而是為了推進這個時代。\n教育讓我們更好地成為自己，更好的教育是你我讓它來到。',
    link: 'https://www.acrossbeavers.com/posts/6b674571-dae2-49fd-97c3-cd7f609cc37e',
    author: '翻滾海貍工作室',
  },
]

const ReviewSection: React.FC = () => {
  return (
    <StyledSection>
      <SectionTitle>各方回饋</SectionTitle>

      <StyledSlider
        prevArrow={<SliderArrow variant="left" />}
        nextArrow={<SliderArrow variant="right" />}
        dots
        autoplay
        autoplaySpeed={5000}
        slidesToShow={3}
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: BREAK_POINT,
            settings: {
              variableWidth: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {reviews.map(review => (
          <StyledItem key={review.title}>
            <StyledDialogBlock>
              <DialogCover coverUrl={review.coverUrl} />
              <div className="p-4">
                <StyledItemTitle className="mb-3">{review.title}</StyledItemTitle>
                <StyledItemDescription className="mb-3">{review.description}</StyledItemDescription>
                <a href={review.link} target="_blank" rel="noopener noreferrer">
                  查看更多
                </a>
              </div>
            </StyledDialogBlock>
            <StyledUserBlock>
              <span>{review.author}</span>
            </StyledUserBlock>
          </StyledItem>
        ))}
      </StyledSlider>
    </StyledSection>
  )
}

export default ReviewSection
