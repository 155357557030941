import styled from 'styled-components'

export const SectionTitle = styled.h2`
  margin-bottom: 2.5rem;
  color: var(--gray-darker);
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.23px;
`
