import { Modal } from 'antd'
import { throttle } from 'lodash'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React, { useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup'
import styled, { css, keyframes } from 'styled-components'
import FootprintInfo1 from '../../images/footprint-info-01.svg'
import FootprintInfo2 from '../../images/footprint-info-02.svg'
import FootprintInfo3 from '../../images/footprint-info-03.svg'
import FootprintInfo4 from '../../images/footprint-info-04.svg'
import { StyledContainer, StyledSection } from './ActivitySection'
import footprint from './footprint'
import { SectionTitle } from './index.styled'

const StyledFootprintSection = styled(StyledSection)`
  padding-bottom: 2rem;
`
const StyledInfoSection = styled(StyledSection)`
  padding-top: 2rem;
  padding-bottom: 15rem;
  @media screen and (max-width: 768px) {
    padding-bottom: 5rem;
    .footprint-info:not(:first-of-type) {
      margin-top: 24px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    .footprint-info:nth-of-type(3),
    .footprint-info:nth-of-type(4) {
      margin-top: 24px;
    }
  }
`
const IntroductionBox = styled.div`
  position: relative;
  padding: 2rem 2rem 5rem;
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  text-align: left;

  ${desktopViewMixin(css`
    ::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 170px;
      height: 170px;
      background-image: url(https://static.kolable.com/images/beaver/beaver-01.png);
      background-size: contain;
      background-repeat: no-repeat;
      content: ' ';
      transform: translate(-75%, 50%);
    }
  `)}
`
const IntroductionTitle = styled.h1`
  color: var(--gray-darker);
  font-size: 24px;
  letter-spacing: 0.3px;
`
const IntroductionSubTitle = styled.div`
  margin-bottom: 0.75rem;
  color: #f29844;
  font-weight: bold;
  letter-spacing: 0.2px;
`
const IntroductionContent = styled.div`
  color: var(--gray-darker);
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: justify;
`
const MapBlock = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 100%;
`
const StyledMap = styled.img`
  width: 100%;
`
const BounceTop = keyframes`
  4.0% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }
  6.5% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  8.2% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  9.3% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  0%,
  2.5%,
  5.5%,
  7.5%,
  8.7%,
  10.0%,
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }
`
const StyledMarker = styled.div<{ size?: number; top?: number; left?: number }>`
  ${props =>
    typeof props.top === 'number' || typeof props.left === 'number'
      ? css`
          position: absolute;
          top: ${props.top}%;
          left: ${props.left}%;
        `
      : ''}
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-image: url(https://static.kolable.com/images/beaver/map-marker.svg);
  background-size: contain;
  background-repeat: no-repeat;

  &.bounce-top {
    animation: ${BounceTop} 9s both infinite;
  }
`
const ModalTitle = styled.div`
  color: var(--gray-darker);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
`
const ModalSubTitle = styled.div`
  color: var(--gray-darker);
  font-weight: bold;
  letter-spacing: 0.2px;
`
const ModalContent = styled.div`
  margin-bottom: 0.5rem;
  color: var(--gray-darker);
  font-size: 14px;
  letter-spacing: 0.4px;

  > :nth-child(1) {
    width: 8rem;
    flex-shrink: 0;
  }
  > :nth-child(2) {
    width: 6rem;
    flex-shrink: 0;
  }
  > :nth-child(3) {
    flex-grow: 1;
  }

  ${desktopViewMixin(
    css`
      display: flex;
      margin-bottom: 0;
    `,
  )}
`
const FootprintInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`
const FootprintInfoIcon = styled.img`
  margin-bottom: 1.5rem;
`
const FootprintInfoTitle = styled.div`
  color: var(--gray-darker);
  font-size: 40px;
  line-height: 1.1;
  margin-bottom: 0.75rem;
`
const FootprintInfoDescription = styled.div`
  color: var(--gray-darker);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.69;
  letter-spacing: 0.2px;
`

const cityNames = {
  Kaohsiung: '高雄',
  NewTaipei: '新北',
  Taichung: '台中',
  Tainan: '台南',
  Taipei: '台北',
  Taoyuan: '桃園',
  Changhua: '彰化',
  Chiayi: '嘉義',
  Hsinchu: '新竹',
  Hualien: '花蓮',
  Miaoli: '苗栗',
  Nantou: '南投',
  Pingtung: '屏東',
  Taitung: '台東',
  Yilan: '宜蘭',
  Yunlin: '雲林',
  Keelung: '基隆',
  Penghu: '澎湖',
}

const markerPositions: {
  city: keyof typeof cityNames
  top: number
  left: number
  delay?: number
}[] = [
  {
    city: 'Keelung',
    top: 1,
    left: 86.5,
  },
  {
    city: 'Taipei',
    top: -1,
    left: 78,
  },
  {
    city: 'NewTaipei',
    top: 7,
    left: 77,
  },
  {
    city: 'Taoyuan',
    top: 5,
    left: 66,
  },
  {
    city: 'Hsinchu',
    top: 12,
    left: 59,
  },
  {
    city: 'Miaoli',
    top: 20,
    left: 60,
  },
  {
    city: 'Taichung',
    top: 28,
    left: 48,
  },
  {
    city: 'Nantou',
    top: 39,
    left: 59,
  },
  {
    city: 'Changhua',
    top: 35,
    left: 37,
  },
  {
    city: 'Yunlin',
    top: 42,
    left: 31,
  },
  {
    city: 'Chiayi',
    top: 49,
    left: 45,
  },
  {
    city: 'Tainan',
    top: 59,
    left: 35,
  },
  {
    city: 'Kaohsiung',
    top: 60,
    left: 52,
  },
  {
    city: 'Pingtung',
    top: 75,
    left: 49,
  },
  {
    city: 'Taitung',
    top: 64,
    left: 67,
  },
  {
    city: 'Hualien',
    top: 39,
    left: 78,
  },
  {
    city: 'Yilan',
    top: 18,
    left: 84,
  },
  {
    city: 'Penghu',
    top: 45,
    left: 3,
  },
]
markerPositions.forEach(marker => {
  marker.delay = Math.random() + 0.1
})

export const useAnimation = (options?: { delay?: number; isInfinite?: boolean; infiniteDelay?: number }) => {
  const ref = useRef<HTMLImageElement>(null)
  const [activated, setActivated] = useState(false)

  useEffect(() => {
    if (ref.current === null || activated) {
      return
    }

    const element = ref.current
    const observer = new IntersectionObserver(entries =>
      entries.forEach(v => {
        if (v.isIntersecting) {
          setTimeout(() => {
            setActivated(true)
            options?.isInfinite && setTimeout(() => setActivated(false), options?.infiniteDelay || 1000)
          }, options?.delay || 0)
        }
      }),
    )
    observer.observe(element)

    return () => observer.unobserve(element)
  }, [activated])

  return { ref, activated }
}

const FootprintSection: React.VFC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [selectedCity, setSelectedCity] = useState<keyof typeof cityNames | null>(null)
  const [isAnimationActive, setIsAnimationActive] = useState(false)

  useEffect(() => {
    const layoutContentElem = document.querySelector('#layout-content')
    const handleScrollThrottle = throttle(() => {
      const currentScrollBottom = (layoutContentElem?.scrollTop || 0) + (layoutContentElem?.clientHeight || 0)
      const containerBottom = (containerRef.current?.offsetTop || 0) + (containerRef.current?.clientHeight || 0)

      if (currentScrollBottom > containerBottom) {
        setIsAnimationActive(true)
      }
    }, 200)
    const handleScroll = () => handleScrollThrottle()

    if (isAnimationActive) {
      layoutContentElem?.removeEventListener('scroll', handleScroll)
    } else {
      layoutContentElem?.addEventListener('scroll', handleScroll)
    }

    return () => {
      layoutContentElem?.removeEventListener('scroll', handleScroll)
    }
  }, [isAnimationActive])

  const { ref: footprintRef, activated: isCountUpActive } = useAnimation({ delay: 800 })

  return (
    <>
      <StyledFootprintSection>
        <SectionTitle>海貍足跡</SectionTitle>
        <StyledContainer className="container" ref={containerRef}>
          <div className="row">
            <div className="col-12 col-lg-6 mb-4">
              <IntroductionBox>
                <IntroductionTitle className="mb-3">海貍的每一步，伴隨教育前進</IntroductionTitle>
                <IntroductionSubTitle>不單在教室外引領學習，更期望在體制中帶動改變</IntroductionSubTitle>
                <IntroductionContent>
                  我們走遍全台700多所學校，提供超過1500場研習，與20000名以上的教育工作者建立共學社群，共創實踐理想的課堂風景。
                </IntroductionContent>
              </IntroductionBox>
            </div>
            <div className="col-12 col-lg-6">
              <MapBlock>
                <StyledMap src="https://static.kolable.com/images/beaver/introduction-map.svg" alt="introduction map" />
                {markerPositions.map(marker => (
                  <StyledMarker
                    key={marker.city}
                    size={30}
                    top={marker.top}
                    left={marker.left}
                    // onClick={() => setSelectedCity(marker.city)}
                    className={isAnimationActive ? 'bounce-top' : ''}
                    style={{ animationDelay: `${marker.delay}s` }}
                  />
                ))}
              </MapBlock>
            </div>
          </div>
        </StyledContainer>

        <Modal
          visible={!!selectedCity}
          title={null}
          footer={null}
          destroyOnClose
          onCancel={() => setSelectedCity(null)}
        >
          <ModalTitle className="d-flex align-items-center mb-4">
            <StyledMarker size={20} className="d-inline-block mr-2" />
            {selectedCity ? cityNames[selectedCity] : 'null'}
          </ModalTitle>

          {Object.keys(footprint).map(year => {
            const footprints = footprint[year]
              .filter(v => selectedCity && v.includes(`${cityNames[selectedCity]},`))
              .sort((a, b) => {
                const dateA = new Date(a.split(',')[0])
                const dateB = new Date(b.split(',')[0])

                return dateA.getTime() - dateB.getTime()
              })

            if (footprints.length === 0) {
              return null
            }

            return (
              <div key={year} className="mb-4">
                <ModalSubTitle className="mb-2">{year}</ModalSubTitle>

                {footprints.map((v, index) => {
                  const record = v.split(',')

                  return (
                    <ModalContent key={index}>
                      <div>{record[1]}</div>
                      <div>{record[2]}</div>
                      <div>{record[3]}</div>
                    </ModalContent>
                  )
                })}
              </div>
            )
          })}
        </Modal>
      </StyledFootprintSection>
      <StyledInfoSection>
        <StyledContainer className="container" ref={footprintRef}>
          <div className="row">
            <FootprintInfo className="col-12 col-lg-3 col-md-6 col-sm-12 footprint-info">
              <FootprintInfoIcon src={FootprintInfo1} alt="footprint-info-1" />
              <FootprintInfoTitle>
                <CountUp end={isCountUpActive ? 18 : 0} start={0} duration={3} delay={5} /> 縣市
              </FootprintInfoTitle>
              <FootprintInfoDescription>海貍足跡遍佈全臺</FootprintInfoDescription>
            </FootprintInfo>
            <FootprintInfo className="col-12 col-lg-3 col-md-6 col-sm-12 footprint-info">
              <FootprintInfoIcon src={FootprintInfo2} alt="footprint-info-1" />
              <FootprintInfoTitle>
                <CountUp end={isCountUpActive ? 1877 : 0} start={0} duration={3} delay={5} /> 場
              </FootprintInfoTitle>
              <FootprintInfoDescription>研習課程累計場次</FootprintInfoDescription>
            </FootprintInfo>
            <FootprintInfo className="col-12 col-lg-3 col-md-6 col-sm-12 footprint-info">
              <FootprintInfoIcon src={FootprintInfo3} alt="footprint-info-1" />
              <FootprintInfoTitle>
                <CountUp end={isCountUpActive ? 20000 : 0} start={0} duration={3} delay={5} />
                +教師
              </FootprintInfoTitle>
              <FootprintInfoDescription>線上與實體共學</FootprintInfoDescription>
            </FootprintInfo>
            <FootprintInfo className="col-12 col-lg-3 col-md-6 col-sm-12 footprint-info">
              <FootprintInfoIcon src={FootprintInfo4} alt="footprint-info-1" />
              <FootprintInfoTitle>接軌國際</FootprintInfoTitle>
              <FootprintInfoDescription>自辦教育節及年會</FootprintInfoDescription>
            </FootprintInfo>
          </div>
        </StyledContainer>
      </StyledInfoSection>
    </>
  )
}

export default FootprintSection
