import '@fullcalendar/react/dist/vdom'
import DefaultLayout from 'lodestar-app/src/components/layout/DefaultLayout'
import React from 'react'
import ActivitySection from './ActivitySection'
import CoverSection from './CoverSection'
import ExplorationSection from './ExplorationSection'
import FindOutMoreSection from './FindOutMoreSection'
import FootprintSection from './FootprintSection'
import PartnerSection from './PartnerSection'
import ReviewSection from './ReviewSection'
import VideoSliderSection from './VideoSliderSection'

const HomePage: React.FC = () => {
  return (
    <DefaultLayout>
      <CoverSection />
      <ActivitySection />
      <VideoSliderSection />
      <FootprintSection />
      <ExplorationSection />
      <ReviewSection />
      <PartnerSection />
      <FindOutMoreSection />
    </DefaultLayout>
  )
}

export default HomePage
