import { BREAK_POINT } from 'lodestar-app/src/components/common/Responsive'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import { SectionTitle } from './index.styled'

const StyledSlider = styled(Slider)`
  .slick-dots {
    li button::before {
      font-size: 12px;
      color: var(--gray);
    }
    li.slick-active button::before {
      font-size: 16px;
      color: #2c7eae;
    }
  }
`
const StyledItem = styled.div`
  max-width: 25rem;
  padding: 1rem;
`
const StyledDialogBlock = styled.div`
  position: relative;
  margin-bottom: 60px;
  padding: 2rem;
  background: white;
  font-size: 16px;
  line-height: 1.69;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  ${desktopViewMixin(
    css`
      padding: 2.5rem;
    `,
  )}

  &::after {
    position: absolute;
    left: -10px;
    bottom: 0;
    width: 60px;
    height: 60px;
    content: url(https://static.kolable.com/images/xuemi/dialog-pionter.svg);
    transform: translateY(99%);
  }

  p {
    color: #9b9b9b;
    white-space: pre-wrap;
  }

  a {
    color: #2c7eae;
  }
`
const StyledUserBlock = styled.div`
  display: flex;
  align-items: center;
  color: #585858;
  font-size: 14px;

  img {
    margin-right: 24px;
    width: 64px;
  }
  span {
    padding-bottom: 8px;
  }
`

const feedbacks: {
  description: string
  author: string
}[] = [
  {
    description:
      '教師支持上知道如何不斷迭代自己的教學，又得到溫暖的支持。語文教學上可以開展學生的思維，發揮文本的最大值。學院讓成員找到自己的目標，又在共學中得到需要的資源，更好的成為自己！讓教學者也成為學習者，可以不斷共學成長，創造有用丶有感丶有趣的學習。',
    author: '北市國中18年教師',
  },
  {
    description:
      '在語文教學上有系統有架構，也能兼顧學習動機，真正做到有趣、有用、有感。在組織運營上，青色組織的共同成長體驗，每次的挑戰都讓自己成長! 能開拓教育新視野、引領教育新範式，在這裡可以開展你想的教育，成就貍想的教育。',
    author: '北部國中15年教師',
  },
  {
    description:
      '貍想創新學院的創建，讓我們得以在共學的過程中實踐與修正；而且在自由、自在的氛圍中，隨時拋出的情緒張力與困惑難點都能得到支持與回饋，能在安全的環境中試錯，很安心。線上工具的使用，讓大家得以打破時空的限制；許多商管觀念的導入，有助於會議及組織推進的效率。',
    author: '北部學校16年教師',
  },
  {
    description:
      '不認識海貍前，自己對教育的想像是很有限的，只能遵循前人的步調走，也時常獨自在有限的思考框架裡碰壁；在認識海貍之後，找到了可以一起共學的夥伴，而且在每次的討論裡，看見自己不同的可能性，也發現教育的多元性，更可貴的是能經由每次的活動，看見理想中的教育是如何被實踐的。',
    author: '南部高職15年教師',
  },
  {
    description:
      '我把貍院的營運方式和心法，帶到目前的班級經營上，如：在參與每次貍想教育學院的任務後，會有一次覆盤，讓我們不僅能思考這次任務的優劣，也能對於未來做出下一步行動。現在我也會帶學生做覆盤，讓他們在每次活動後，能思考現階段的優劣，且展望未來。',
    author: '南部學校15年教師',
  },
  {
    description:
      '貍想學院的支援很是細膩。有各式各樣支持教師的學習資源，如組內的課程討論推進及時間管理的tutor課程。在我忙碌的生活中，可以在零碎的時間中，拼湊出學習的樣貌，且對生活很有幫助。',
    author: '北部國中15年教師',
  },
  {
    description:
      '我從海貍的課程學到:\n1.系統思考，讓我在閱讀及寫作教學上不再捨本逐末\n2.思維教育，學習語文的目的是在釐清及建立心智模型\n3.敏捷精神，得以正視自己的快思，修磨自己的慢想\n4.合弄共學，在與他人的共創中感受集體智慧的激盪\n為所有學習者打造自由的未來教育樣貌，這是我待過最知行合一的教育單位!',
    author: '北部高中20年教師',
  },
  {
    description:
      '海貍從根本的基礎搭建更有意識的鷹架和思考進程，讓文本在現今和古代之間更直接的交流，讓看似深難的古文更符合現今的公民教育。在其中，我看到具體成果是每天一篇一篇慢慢累積的成果，讓人覺得不可思議。感動在一群老師辛勞教學的背後，有海貍強大的支持。',
    author: '中部高中3年教師',
  },
  {
    description:
      '教育應該讓孩子成為他們自己本身最自然的樣子。學院的理念與運營方式讓學員能深刻體會到這一點，進而帶入各自的教學現場。在這裡從學到做中學，無論是對孩子們還是老師們幾乎可說是最莫大的燈塔與祝福。',
    author: '中部作文教師4年',
  },
  {
    description:
      '目前擔任高三國文老師，任教的學校為男校，男生很需要結構性強的東西來輔助學習，河道作文就成為我和學生在作文寫作上很好的溝通工具。河道作文將寫作分為取材、結構、文采、立意、文氣，讓我可以帶著學生分階段、擬定具體目標的刻意練習，教起來很有方向，學起來也很有成就感。',
    author: '北部高中10年教師',
  },
  {
    description:
      '在學院習得了青色組織的概念和運作方式後，我直接帶到班級經營使用，以往，身為一個班導，我常常陷入疲於管教、拖拉孩子往前走的困境，引入青色組織後，幹部有自覺，同學對公共事務有參與熱情，班級成員和我都成為生命的共學員。',
    author: '北部學校10年教師',
  },
  {
    description:
      '從築壩閱讀的文本分析到河道作文的分項實作，再到結合解建的讀寫學習單，讓我對「從讀到寫」會經歷的過程有更具體地了解，幫助我在自製上課講義時能更清楚的知道該如何設計更貼近學生需求的內容。',
    author: '北部補教教師16年',
  },
  {
    description:
      '海貍是推進台灣下一代課綱的教育實踐家!語文教學我最有感的是學會提取上層概念，海貍以一對一的指導進行教育顧問的工作，所倡行的教育3.0共學制度，讓我覺得我不會被丟掉!',
    author: '桃園高中3年教師',
  },
  {
    description:
      '貍以文本為核心，帶出對文本的透視解讀，讓老師們明白閱讀文本可以去蕪存菁地提取課設目標的重點，聚焦我們的教學核心，也精準了學生的能力向度。（如：思維建構與心智圖梳理，都讓現場師生們在教學試煉上，有機會先嘗試走一回，在因應現場做增修微調，完成課程調理。）',
    author: '南部學校21年教師',
  },
  {
    description:
      '海貍的語文教材思維建構學習單幫助備課，入校的築壩閱讀研習讓同事改變教學，教學問題提問，老師提供詳盡回答。參加貍想教育節，更可以深刻了解，例如：了解青色組織可以帶回學校與班級，漸漸改變教育現場。',
    author: '中部學校20年教師',
  },
  {
    description:
      '海貍的語文教材能確實讓學習者覺察盲點，並提升語文分析與習作能力，教學則引導第一線老師，統整最適宜的有效教學策略。身處在這個有教育理念且不斷進步前行的優質團隊，永遠有一個能自由自在呼吸的安心空間!',
    author: '中部高中9年教師',
  },
  {
    description:
      '將全臺灣亟不滿足當今「語文等教學」的教育工作者，透過「完整的線上課程」及「自由的線上討論及表述」，形成雖無法經常碰面，但可以隨時安心討論、善意回應的系統；既可以理性討論，又能夠得到感情支持，既是「專業支持」，也是「溫馨支柱」。',
    author: '南部高中21年教師',
  },
  {
    description:
      '閱讀生活與文本，紀錄自我與他人，翻滾海貍工作室為師生們帶來的具體成效：將文本所學與生活所感，在「以終為始」的課設脈絡裡，透過讀聽的「解構」與說寫的「建構」，完整一系列讀寫合一的脈絡教與學。',
    author: '南部國中21年教師',
  },
  {
    description:
      '貍想教育創新學院，讓大家自主湧現、共創，選擇自己有興趣的、依照自己的心力，進行各種知識、技能以及人際互動學習。具體展現全人關懷。在狀況不好的時候沒有被否定，狀況好的時候也被支持作自己想做的事情。有想法提出會被支持，有問題提出可以討論或是有一個探索的方向。',
    author: '北部高中教師8年',
  },
  {
    description:
      '打通經脈：將「範文」、「文教」、「作文」、「表達」教學及「評量」方式，各自的邏輯徹底分析、重新組合，形成「教師可操作的有機系統」：並融會綜合成一比較完整的「語文」的訓練，架構「學生主動思考」的能力。',
    author: '南部高中21年教師',
  },
  {
    description:
      '海貍讓我長腦！在海貍中有很多學習，學組織運行、學課程發展、學學習單製作、學美編加工、學表述自己、學文案推播、學拓展知識，作為一個教師，在裡面可以安心的充實自己的能力，超棒的！我學了，會逐漸影響到我的學生，像是我有成長性思維，就會把這個當作目標，以終為始的替學生搭鷹架，開展教學脈絡。',
    author: '中部學校5年教師',
  },
]

const FeedbackSection: React.FC = () => {
  return (
    <div className="pb-5">
      <div className="container">
        <SectionTitle className="mb-5">
          <div>現場迴響</div>
          <sub>來自現場教育的真心回饋</sub>
        </SectionTitle>
      </div>

      <StyledSlider
        arrows={false}
        dots
        centerMode
        variableWidth
        autoplay
        autoplaySpeed={5000}
        responsive={[
          {
            breakpoint: BREAK_POINT,
            settings: {
              variableWidth: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {feedbacks.map(feedback => (
          <StyledItem key={feedback.author}>
            <StyledDialogBlock>
              <p className="mb-3">{feedback.description}</p>
            </StyledDialogBlock>
            <StyledUserBlock>
              <span>{feedback.author}</span>
            </StyledUserBlock>
          </StyledItem>
        ))}
      </StyledSlider>
    </div>
  )
}

export default FeedbackSection
