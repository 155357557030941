import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'
import { StyledContentBlock, StyledLabel, StyledSection, StyledSubtitle } from './index.styled'

const primaryColor = '#ffbe1e'
const StyledEntrepreneurSection = styled(StyledSection)`
  text-align: center;
`
const StyledEntrepreneurLabel = styled(StyledLabel)`
  color: ${primaryColor};
  background-color: ${primaryColor}1a;
`
const StyledEntrepreneurContentsBlock = styled.div`
  margin-bottom: 40px;
`
const StyledEntrepreneurContents = styled.div`
  ${desktopViewMixin(css`
    text-align: center;
  `)}
`
const StyledEntrepreneurContent = styled.div`
  font-weight: 500;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: justify;
  color: var(--gray-darker);
  margin-bottom: 0.25rem;
`

const EntrepreneurSection: React.FC = () => {
  return (
    <div>
      <StyledEntrepreneurSection>
        <div className="container">
          <p>
            曾有人說「未來最重要的三種能力是：學會開創、建立社群、
            接受失敗。」，而這些統合起來，便是創業精神。翻滾海貍首創，將商務思維融入教育領域，讓每個對教育有所倡議且想行動的人，都能透過實際的組織運作步驟，組建團隊、協同開發，深得共學的樂趣，培養創業精神，讓美好的教育願景得以落地實踐，為自己的教學現場及未來的教育創造改變！
          </p>
        </div>
      </StyledEntrepreneurSection>
      <StyledSubtitle>組織運作</StyledSubtitle>

      <StyledEntrepreneurContentsBlock className="container d-flex justify-content-between flex-wrap px-0">
        <StyledContentBlock className="col-12">
          <StyledEntrepreneurLabel>教育創新</StyledEntrepreneurLabel>
          <StyledEntrepreneurContents>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="col-12 col-lg-4 pl-lg-0 mb-3 mb-lg-0">
                {[
                  '重塑組織．組織進化論',
                  '從1.0到3.0．範式進化論',
                  '非大會的大會．教育創新觀影會',
                  '貍想教育創新學院．教育創新展示會',
                  '共學會議怎麼開．議事規則',
                  '共學會議怎麼開．整合式決策',
                  '共學會議怎麼開．批判性思考',
                  'NVC非暴力溝通在教育現場的應用．張力回應',
                ].map(v => (
                  <StyledEntrepreneurContent>{v}</StyledEntrepreneurContent>
                ))}
              </div>
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                {[
                  '比領導力更該學的共學力．洞察',
                  '比領導力更該學的共學力．提問',
                  '比領導力更該學的共學力．配盤',
                  '比領導力更該學的共學力．回饋',
                  '從產品到課程到人的根本設計法則．星河',
                  'SWOT在教育現場的應用．打造一所面向未來的學校',
                  'Scrum在教育現場的應用．班級管理的看板應用',
                ].map(v => (
                  <StyledEntrepreneurContent>{v}</StyledEntrepreneurContent>
                ))}
              </div>
              <div className="col-12 col-lg-4 pr-lg-0">
                {[
                  'Scrum在教育現場的應用．自主學習的看板應用',
                  'Tots系統在教育現場的應用．圈層管理的班級經營',
                  'D-thinking在教育現場的應用．課程設計',
                  'D-thinking在教育現場的應用．議題討論',
                  'D-thinking在教育現場的應用．產品開發',
                ].map(v => (
                  <StyledEntrepreneurContent>{v}</StyledEntrepreneurContent>
                ))}
              </div>
            </div>
          </StyledEntrepreneurContents>
        </StyledContentBlock>
      </StyledEntrepreneurContentsBlock>
    </div>
  )
}

export default EntrepreneurSection
