import { BREAK_POINT } from 'lodestar-app/src/components/common/Responsive'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import { SectionTitle } from './index.styled'

const StyledSection = styled.section`
  padding-top: 3.5rem;
  background: white;
`
const StyledPartnerLogo = styled.img`
  ${desktopViewMixin(css`
    margin: 0 1rem;
  `)}
`
const StyledItem = styled.div<{ width?: number }>`
  padding: 0 1.5rem;
  width: calc(${props => props.width}px + 3rem);
  height: 8rem;

  @media (min-width: 768px) {
    padding: 0 0.5rem;
    width: calc(${props => props.width}px + 4rem);
  }
`

const partners: {
  id: string
  src: string
  alt: string
  width: number
  height: number
}[] = [
  {
    id: 'partner-4',
    src: 'https://static.kolable.com/images/beaver/partner-4.png',
    alt: '三民書局',
    width: 196,
    height: 36,
  },
  {
    id: 'partner-1',
    src: 'https://static.kolable.com/images/beaver/partner-1.png',
    alt: '康軒文教事業',
    width: 184,
    height: 29,
  },
  {
    id: 'partner-2',
    src: 'https://static.kolable.com/images/beaver/partner-2.png',
    alt: '可能非學校',
    width: 165,
    height: 36,
  },
  {
    id: 'partner-3',
    src: 'https://static.kolable.com/images/beaver/partner-3.png',
    alt: '即興派',
    width: 98,
    height: 93,
  },
  {
    id: 'partner-5',
    src: 'https://static.kolable.com/images/beaver/partner-5.png',
    alt: '魚水',
    width: 148,
    height: 74,
  },
  {
    id: 'partner-6',
    src: 'https://static.kolable.com/images/beaver/partner-6.png',
    alt: '島島阿學',
    width: 146,
    height: 68,
  },
  {
    id: 'partner-7',
    src: 'https://static.kolable.com/images/beaver/partner-7.png',
    alt: '奇德王國',
    width: 145,
    height: 104,
  },
  {
    id: 'partner-8',
    src: 'https://static.kolable.com/images/beaver/partner-8.png',
    alt: 'partner 8',
    width: 94,
    height: 93,
  },
]

const PartnerSection: React.FC = () => {
  return (
    <StyledSection>
      <SectionTitle>合作夥伴</SectionTitle>
      <Slider
        infinite
        autoplay
        centerMode
        speed={5000}
        slidesToShow={3}
        autoplaySpeed={0}
        slidesToScroll={0.1}
        variableWidth={true}
        cssEase="linear"
        responsive={[
          {
            breakpoint: BREAK_POINT,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        {partners.map(partner => (
          <StyledItem
            key={partner.id}
            width={partner.width}
            className="d-flex align-items-center justify-content-center"
          >
            <StyledPartnerLogo src={partner.src} alt={partner.alt} width={partner.width} height={partner.height} />
          </StyledItem>
        ))}
      </Slider>
    </StyledSection>
  )
}

export default PartnerSection
