import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'
import { StyledContentBlock, StyledContentNote, StyledLabel, StyledSection, StyledSubtitle } from './index.styled'

const primaryColor = '#2c7eae'

const StyledLearnerSection = styled(StyledSection)`
  text-align: center;
`
const StyledLearnerLabel = styled(StyledLabel)`
  color: ${primaryColor};
  background-color: ${primaryColor}1a;
`
const StyledLearnerContents = styled.div`
  ${desktopViewMixin(css`
    text-align: center;
  `)}
`
const StyledLearnerContent = styled.div`
  font-weight: 500;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: justify;
  color: var(--gray-darker);
  margin-bottom: 0.25rem;
  text-align: center;
`
const StyledContentSubtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  color: var(--gray-darker);
`
const StyledSubtitleDecoration = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${primaryColor};
  margin-right: 8px;
`
const learnerContents: {
  title: string
  notes?: string
  blockClass?: string
  items?: string[]
  nestItems?: { subtitle: string; blockClass?: string; contents: string[] }[]
}[] = [
  { title: '應試策略', items: ['會考解析', '學測解析', '統測解析', '會考寫作', '國寫寫作', '統測寫作'] },
  { title: '閱讀策略', items: ['古文文法', '話語分析', '層次重劃', '思維可視'] },
  {
    title: '經典閱讀',
    notes: '*以上皆為學員自選文本',
    items: ['古典散文', '現代散文', '古典韻文', '現代韻文', '古今小說', '跨域解構'],
  },
  { title: '入海實戰', notes: '*以上皆依題目為主', items: ['會考寫作', '國寫知性', '國寫感性', '統測寫作'] },
  { title: '應用寫作', notes: '*以上皆依文類為主', items: ['立意', '文采', '結構', '取材', '演練', '文學賞析'] },
  { title: '說話藝術', items: ['肢體篇', '聲語篇', '故事篇', '協商篇', '面試篇'] },
  {
    title: '生而為人的這些那些',
    items: [
      '儒家篇之一',
      '儒家篇之二',
      '儒家篇之三',
      '儒家篇之四',
      '儒家篇之五',
      '儒家篇之六',
      '儒家篇之七',
      '儒家篇之八',
      '儒家篇之九',
    ],
  },
  { title: '思維工程', notes: '*以上皆依文本為主', items: ['讀寫結合', '海貍戰隊'] },
  {
    title: '主題讀寫',
    blockClass: 'col-12',
    nestItems: [
      {
        subtitle: '自我成長',
        contents: ['你的努力有用嗎？', '勇氣能吃嗎？', '堅持的相反不是放棄', '承諾其實是自己的事', '獨立，從現在開始'],
      },
      {
        subtitle: '人際關係',
        contents: [
          '同在屋簷下的那個人',
          '坐我旁邊的那個人',
          '站在講臺的那個人',
          '記憶中的那個人',
          '我是他眼中的那個人',
        ],
      },
      {
        subtitle: '社會關懷',
        contents: ['手心外的天空', '和我不一樣的那群人', '沉默之聲', '舉手之勞，不作環保', '從我到我們'],
      },
      { subtitle: '美感經驗', contents: ['觀．賞', '聽．聞', '觸．感', '嚐．品', '想．享'] },
      {
        subtitle: '角色扮演',
        contents: ['近在眼前的她', '遠在天邊的他', '有口難言的牠', '默默無語的它', '照看一切的祂'],
      },
      {
        subtitle: '思考論辨',
        contents: ['快樂Song', '怪美的', '改變的魔法', '那些名人教我的事', 'TO BE OR NOT TO BE'],
      },
      {
        subtitle: '日常對話',
        contents: ['我的使用手冊', '你是獨一無二的存在', '沉默的語言', '當情緒來的時候', '別輕易說懂'],
      },
      {
        subtitle: '廣告文學',
        contents: ['氣氛也能當飯吃', '洗滌的不只是心靈', '海岸景觀第一排', '不只是速度', '那些沒注意到的事'],
      },
      {
        subtitle: '劇本敘事',
        contents: ['心碎的時刻', '哭著哭著就笑了', '怪奇物語', '那些幽微的細節', '真相只有一個'],
      },
      { subtitle: '未知探索', contents: ['最陌生的自己', '那些人，那些事', '千年之約', '生命起源', '下一站，行星'] },
      {
        subtitle: '寰宇采風',
        contents: [
          '我所知道的文明',
          '看見與看不見',
          '拉丁美洲的永恆旅人',
          '大航海時代的輝煌與歲月靜好的老靈魂',
          '那些帶不走的',
        ],
      },
      {
        subtitle: '社會觀察',
        contents: ['和我作鄰居好嗎？', '你今天被騙過了嗎？', '豆子的大小事', '健身房，健心房', '不想一個人'],
      },
    ],
  },
]

const LearnerSection: React.FC = () => {
  return (
    <div>
      <StyledLearnerSection>
        <div className="container">
          <p>
            想因應升學考試或是增進思考表述能力，海貍團隊準備了全系列國語文學習內容。從解題分析到思維訓練；從理解他人到悅納自己，國小到高中、閱讀到寫作，讓學習者透過有趣、有用和有感的課程，將知識化作素養，更好的成就自我。
          </p>
        </div>
      </StyledLearnerSection>
      <StyledSubtitle>對生課程</StyledSubtitle>

      <div className="container d-flex justify-content-between flex-wrap px-0">
        {learnerContents.map(content => (
          <StyledContentBlock className={content?.blockClass || 'col-12 col-sm-6 col-lg-3'}>
            <StyledLearnerLabel>{content.title}</StyledLearnerLabel>
            <StyledLearnerContents>
              {content.items?.length ? (
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="col-6">
                    {content.items.slice(0, Math.round(content.items.length / 2)).map(item => (
                      <StyledLearnerContent>{item}</StyledLearnerContent>
                    ))}
                  </div>
                  <div className="col-6">
                    {content.items.slice(Math.round(content.items.length / 2)).map(item => (
                      <StyledLearnerContent>{item}</StyledLearnerContent>
                    ))}
                  </div>
                </div>
              ) : (
                <div>{content.items?.[0]}</div>
              )}
              {content.nestItems?.length && (
                <div className="d-flex justify-content-between flex-wrap">
                  {content.nestItems.map(nestItem => (
                    <StyledContentBlock className="col-6 col-lg-3 px-0 pr-1 pr-md-3" style={{ textAlign: 'left' }}>
                      <StyledContentSubtitle className="d-flex align-items-center">
                        <StyledSubtitleDecoration />
                        {nestItem.subtitle}
                      </StyledContentSubtitle>
                      {nestItem.contents.map(content => (
                        <div className="mb-2" style={{ fontWeight: 500 }}>
                          {content}
                        </div>
                      ))}
                    </StyledContentBlock>
                  ))}
                </div>
              )}
            </StyledLearnerContents>
            {content?.notes && <StyledContentNote>{content?.notes}</StyledContentNote>}
          </StyledContentBlock>
        ))}
      </div>
    </div>
  )
}

export default LearnerSection
