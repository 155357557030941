import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'
import BeaverStep1Icon from '../../images/beaver-step-1.svg'
import BeaverStep2Icon from '../../images/beaver-step-2.svg'
import BeaverStep3Icon from '../../images/beaver-step-3.svg'
import { SectionTitle } from './index.styled'

const StyledContainer = styled.div`
  position: relative;
`
const BeaverStep = styled.div<{ backgroundUrl?: string }>`
  display: none;
  position: absolute;
  width: 45px;
  height: 45px;
  background-image: url(${props => props.backgroundUrl});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  ${desktopViewMixin(css`
    display: block;
  `)}
`
const TimelineBlock = styled.div`
  position: relative;
  margin-left: 3rem;
  padding-top: 0.25rem;
  padding-left: 5rem;
  padding-bottom: 2.5rem;
  border-left: 2px solid rgba(44, 126, 174, 0.2);

  &:last-child {
    border-color: transparent;
  }

  li {
    margin-bottom: 0.75rem;
    color: var(--gray-darker);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: justify;
  }
`
const TimelineLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: #2c7eae;
  color: white;
  letter-spacing: 0.2px;
  transform: translateX(-50%);
`
const KeywordTrigger = styled.span`
  color: #2c7eae;
  cursor: pointer;
`
const StyledPopoverContent = styled(PopoverContent)`
  &&,
  &&:focus {
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
`

const Keyword: React.FC<{
  title: string
}> = ({ title, children }) => {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <KeywordTrigger>{title}</KeywordTrigger>
      </PopoverTrigger>
      <StyledPopoverContent>
        <PopoverArrow />
        <PopoverBody>{children}</PopoverBody>
      </StyledPopoverContent>
    </Popover>
  )
}

const FootprintSection: React.FC = () => {
  return (
    <StyledContainer className="container">
      <BeaverStep backgroundUrl={BeaverStep1Icon} style={{ top: '146px', left: '-12px' }} />
      <BeaverStep backgroundUrl={BeaverStep2Icon} style={{ top: '300px', left: '-48px' }} />
      <BeaverStep backgroundUrl={BeaverStep2Icon} style={{ top: '640px', left: '72px' }} />
      <BeaverStep backgroundUrl={BeaverStep3Icon} style={{ top: '800px', left: '-30px' }} />
      <BeaverStep backgroundUrl={BeaverStep3Icon} style={{ bottom: '30px', left: '10px' }} />

      <SectionTitle className="mb-5">
        <div>海貍足跡</div>
        <sub>翻滾海貍大事紀</sub>
      </SectionTitle>

      <TimelineBlock>
        <TimelineLabel>2014</TimelineLabel>
        <ul>
          <li>
            「
            <Keyword title="海貍老師的翻滾教室">
              以「遊戲教育」為形式、「啟發教育」為內容，重視「價值涵養」，設計相關的課程活動、拍攝趣味影片，製作課堂桌遊《海貍戰隊》，並陸續至學校演講分享、入班演示、觀課議課，亦在國文快遞分享課堂風景。
            </Keyword>
            」形成。
          </li>
        </ul>
      </TimelineBlock>

      <TimelineBlock>
        <TimelineLabel>2015</TimelineLabel>
        <ul>
          <li>
            2015年07月29日「
            <Keyword title="翻滾海貍工作室">
              翻滾海貍藉由首創的思維教育改變傳統語文教學，讓體制內的教師以更科學且系統化的方式看待文學與教學，並在共學協作的過程，培養響應變化的即興智慧，以及勇於試錯，快速迭代的敏捷開發能力。
            </Keyword>
            」正式成立。
          </li>
        </ul>
      </TimelineBlock>

      <TimelineBlock>
        <TimelineLabel>2016</TimelineLabel>
        <ul>
          <li>開辦「未來教育」年度趨勢教育工作坊。</li>
          <li>
            與<Keyword title="三民東大、康軒文教">出版各式學習單與教材輔具，影響力覆蓋臺灣三分之一國、高中生。</Keyword>
            合作出版教材。
          </li>
          <li>推出「河道式作文」系列課程、「誰殺了海貍」思辨推理課程。</li>
        </ul>
      </TimelineBlock>

      <TimelineBlock>
        <TimelineLabel>2017</TimelineLabel>
        <ul>
          <li>開辦「老派教育」年度趨勢教育工作坊。</li>
          <li>開啟「初胚計劃」，扶持現場教師實踐心中的教學藍圖。</li>
          <li>推出第一套初創作型桌遊《尾巴巴呢》，有效運用於寫作教學上，佳評如潮。</li>
          <li>推出《河道式作文寫作指南》專書，至今已逾四刷。</li>
          <li>協助出版《下輩子別當國文老師》古典新解散文集，引起廣大迴響。</li>
        </ul>
      </TimelineBlock>

      <TimelineBlock>
        <TimelineLabel>2018</TimelineLabel>
        <ul>
          <li>
            參展於亞洲創新教育展「
            <Keyword title="雜學校">
              連續兩年以國文科創新教育團隊參展於亞洲創新教育展「雜學校」，並在展場發表了《誰殺了海貍》與《超時空偵探》推理遊戲，完美結合遊戲與閱讀。
            </Keyword>
            」。
          </li>
          <li>協助內湖高中、慈濟中學等各級學校設計多元選修、校本等特色課程發展。</li>
          <li>暑期籌組「怎樣教育」年度趨勢教育工作坊。</li>
          <li>獲選「親子天下教育創新100 」。</li>
        </ul>
      </TimelineBlock>

      <TimelineBlock>
        <TimelineLabel>2019</TimelineLabel>
        <ul>
          <li>籌辦「非關教育」年度趨勢教育工作坊。</li>
          <li>與中港台澳20個教育創新團隊合辦「群島大會」。</li>
          <li>
            成立共學社群「
            <Keyword title="貍想教育創新學院">
              支持所有參與學院的夥伴，自由開展教育想像，找到自己的價值與方向，意識到自己能夠影響下一代與整個社會，成為具使命感與行動力的教育創變者，為社會帶來有意義的改變。
            </Keyword>
            」。
          </li>
          <li>獲選為「遠見天下未來教育臺灣100」。</li>
        </ul>
      </TimelineBlock>

      <TimelineBlock>
        <TimelineLabel>2020</TimelineLabel>
        <ul>
          <li>
            推動訂閱專案「
            <Keyword title="築壩月讀共建計畫">
              年初，將經典文本的意義重新釋讀，提倡思維導向的教學設計，除了將思辨系列課程推廣入校，同時推動「築壩式閱讀」集資計劃，取得眾多國文教育工作者的支持，最後募資金額遠超於預設目標四倍。
            </Keyword>
            」。
          </li>
          <li>
            7月8日至7月11日成功辦理首次「
            <Keyword title="貍想教育節">
              有別於多數的教育展會都是上而下的推動、單一講師的分享，「貍想教育節」是一個由與會者自主籌辦、自發湧現、自由進化的教育慶典，既是學院成員的成果發表，也是臺灣教育典範成果的年度呈獻。
            </Keyword>
            」。
          </li>
        </ul>
      </TimelineBlock>
    </StyledContainer>
  )
}

export default FootprintSection
