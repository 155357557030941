import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'

const StyledConsultingSection = styled.div`
  margin-bottom: 80px;
`
const StyledConsultingCard = styled.div`
  width: 100%;
  margin: auto;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  position: relative;
  flex-wrap: wrap;
`
const StyledBeaver = styled.div`
  display: none;
  ${desktopViewMixin(css`
    display: block;
    width: 160px;
    height: 160px;
    position: absolute;
    bottom: -50px;
    right: -3vw;
    background-image: url(https://static.kolable.com/images/beaver/beaver-09.png);
    background-size: cover;
  `)}
`
const StyledConsultantBlock = styled.div`
  margin-top: 24px;
  padding: 0;
  ${desktopViewMixin(css`
    margin-top: 0;
    padding-left: 56px;
  `)}
`
const StyledConsultingLabel = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0.77px;
  color: #2c7eae;
  margin-bottom: 12px;
`
const StyledConsultant = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: var(--gray-darker);
  margin-bottom: 4px;
`
const ConsultingSection: React.FC = () => {
  return (
    <StyledConsultingSection className="container">
      <StyledConsultingCard className="d-flex">
        <div className="col-12 col-lg-8 p-0">
          <p>
            翻滾海貍的研習足跡遍及全臺，以超過千場的研習經驗及能量，打造最符合現場教師需求的課程地圖，無論是國文專科研習、跨域多元增能、組織社群運營、對生常態課程及寒暑期營隊，以「人」為本的課程設計，絕對實用度爆表、含金量最高！
          </p>
          <br />
          <p>
            翻滾海貍將每年優化課程地圖，並致力於結合課綱變化及最新教育趨勢，如果您需要專貍為您進行課程解說，或需針對您的增能需求，進行客製化的課程設計，請洽翻滾海貍課程窗口。
          </p>
        </div>
        <StyledConsultantBlock className="col-12 col-lg-4">
          <StyledConsultingLabel>客製化課程設計洽詢</StyledConsultingLabel>
          <StyledConsultant>阿莉</StyledConsultant>
          <p>聯絡電話：0972-952-022 </p>
          <p>LINE ID：lilyhuang1022</p>
        </StyledConsultantBlock>
        <StyledBeaver />
      </StyledConsultingCard>
    </StyledConsultingSection>
  )
}

export default ConsultingSection
