import { Icon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import { BREAK_POINT } from 'lodestar-app/src/components/common/Responsive'
import { ReactComponent as FacebookIcon } from 'lodestar-app/src/images/facebook-icon.svg'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as EmailIcon } from '../images/email-icon.svg'
import { ReactComponent as LineIcon } from '../images/line-icon.svg'

const StyledFooter = styled.footer`
  text-align: center;
  background-color: #f9f8f9;
  color: var(--gray-darker);
  font-size: 18px;
  font-weight: 500;
  padding: 32px 0;
  @media (min-width: ${BREAK_POINT}px) {
    text-align: left;
  }
`
const StyledSocialBlock = styled.div`
  margin-top: 20px;
  justify-content: center;
  @media (min-width: ${BREAK_POINT}px) {
    margin-top: 0;
    justify-content: start;
  }
`
const StyledSocialAnchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: solid 1px var(--gray-dark);
  border-radius: 50%;
  color: #585858;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-left: 0.75rem;
  }
  @media (min-width: ${BREAK_POINT}px) {
    margin-bottom: 12px;
  }
`
const StyledLinkBlock = styled.div`
  font-size: 14px;
`

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <div className="container d-flex align-items-center justify-content-between flex-wrap">
        <div className="col-12 col-lg-9">
          <p>親愛的海貍夥伴您好</p>
          <p>若您有任何意見或疑問，我們誠摯地邀請您直接聯繫</p>
        </div>
        <StyledLinkBlock className="col-12 col-lg-3 p-0">
          <StyledSocialBlock className="d-flex align-items-center">
            <StyledSocialAnchor href="mailto:acrosschinese@gmail.com">
              <Icon as={EmailIcon} />
            </StyledSocialAnchor>
            <StyledSocialAnchor href="https://www.facebook.com/acrossbeavers" target="_blank" rel="noopener noreferrer">
              <Icon as={FacebookIcon} />
            </StyledSocialAnchor>
            <StyledSocialAnchor
              href="https://line.me/R/ti/p/%40acrossbeavers"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon as={LineIcon} />
            </StyledSocialAnchor>
          </StyledSocialBlock>
          <Box letterSpacing="0.18px">
            <a href="tel:0972-952-022">課程邀約：0972-952-022（Lily）</a>
          </Box>
          <Box letterSpacing="0.18px">
            <a href="https://bit.ly/3hDqC7z" target="_blank" rel="noopener noreferrer">
              課程紀錄：https://bit.ly/3hDqC7z
            </a>
          </Box>
        </StyledLinkBlock>
      </div>
    </StyledFooter>
  )
}

export default Footer
