import { ReactComponent as AngleLeftIcon } from 'lodestar-app/src/images/angle-thin-left.svg'
import { ReactComponent as AngleRightIcon } from 'lodestar-app/src/images/angle-thin-right.svg'
import React from 'react'
import ReactPlayer from 'react-player'
import Slider from 'react-slick'
import styled from 'styled-components'
import WaveImage from '../../images/wave.svg'

const StyledSection = styled.section`
  position: relative;
  padding: 7rem 0 10rem;
  /* padding-bottom: 10rem; */
  overflow: hidden;
  /* background-color: #f6f6f6; */
  /* &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
    background: white;
    content: ' ';
  }
  &::after {
    position: absolute;
    top: 50px;
    padding-top: calc(12200% / 1440);
    width: 100%;
    background-color: white;
    background-image: url('${WaveImage}');
    background-size: cover;
    background-position: center;
    content: ' ';
    z-index: 0;
  } */
`
const StyledContainer = styled.div`
  position: relative;
  max-width: 760px;
  z-index: 1;

  .slick-dots {
    bottom: -65px;

    li button::before {
      font-size: 12px;
      color: var(--gray);
    }
    li.slick-active button::before {
      font-size: 16px;
      color: #f29844;
    }
  }
`
const StyledTitle = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 1.5rem;
  padding: 8px 12px;
  background-color: var(--gray-darker);
  color: white;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
  border-radius: 4px;

  &::before {
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 10px;
    height: 10px;
    transform: translateX(-50%) rotate(45deg);
    background-color: var(--gray-darker);
    content: ' ';
  }
`
const StyledVideoWrapper = styled.div`
  position: relative;
  padding-top: calc(900% / 16);
  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`
const StyledArrow = styled.div<{ variant: 'left' | 'right' }>`
  && {
    ${props => (props.variant === 'left' ? 'left: -80px;' : 'right: -80px;')}
    height: auto;
    width: auto;
    font-size: 64px;
    color: #ececec;
    z-index: 1;

    ::before {
      content: '';
    }
  }
`

const SliderArrow: React.FC<{
  variant: 'left' | 'right'
}> = props => {
  return <StyledArrow {...props}>{props.variant === 'left' ? <AngleLeftIcon /> : <AngleRightIcon />}</StyledArrow>
}

const videos: {
  title: string
  url: string
}[] = [
  { title: '翻滾海貍介紹 Ver2018', url: 'https://youtu.be/r87eFGeDiw0' },
  { title: '築壩閱讀之必要', url: 'https://youtu.be/X2zl2LiraK8' },
  { title: '經典閱讀課程實況節選', url: 'https://youtu.be/F1JDUBsLrRI' },
  { title: '非關教育-愛在衝突時', url: 'https://youtu.be/NEqxrnZ_xf4' },
  { title: '2020貍想教育節', url: 'https://youtu.be/ZErJfbw30WQ' },
  { title: '貍想創新教育學院簡介', url: 'https://youtu.be/LUebKtQ3KfQ' },
]

const VideoSliderSection: React.FC = () => {
  return (
    <StyledSection>
      <StyledContainer className="container">
        <div className="row">
          <div className="col-12">
            <Slider dots prevArrow={<SliderArrow variant="left" />} nextArrow={<SliderArrow variant="right" />}>
              {videos.map(video => (
                <div key={video.title} className="text-center">
                  <StyledTitle>{video.title}</StyledTitle>
                  <StyledVideoWrapper>
                    <ReactPlayer url={video.url} controls width="100%" height="100%" />
                  </StyledVideoWrapper>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </StyledContainer>
    </StyledSection>
  )
}

export default VideoSliderSection
