import zhTWLocale from '@fullcalendar/core/locales/zh-tw'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import React from 'react'
import styled, { css } from 'styled-components'
import ProjectBanner from './ProjectBanner'

export const StyledContainer = styled.div`
  ${desktopViewMixin(css`
    .row > *:last-child {
      text-align: right;
    }
  `)}
`

export const StyledSection = styled.section`
  padding: 4rem 0 7.5rem;
  background: white;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding-bottom: 2rem;
  }
`

const CalendarWrapper = styled.div`
  height: 492px;
  .fc,
  .fc-event {
    overflow-x: hidden;
    height: 100%;
  }
  .fc-toolbar-title {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    .fc-toolbar-chunk {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .fc-toolbar-chunk:first-of-type {
      width: 40%;
    }
    .fc .fc-button {
      font-size: 1em;
      padding: 0.4em 0.8em;
      margin-bottom: 0.5rem;
    }
  }
`

const ActivitySection: React.VFC = () => {
  return (
    <StyledContainer>
      <StyledSection>
        <div className="d-lg-flex flex-lg-row-reverse mb-5 mx-lg-4">
          <div className="col-lg-6 pl-lg-3">
            <ProjectBanner />
          </div>
          <CalendarWrapper className="col-lg-6 mb-lg-0 pr-lg-3">
            <FullCalendar
              plugins={[dayGridPlugin, listPlugin, googleCalendarPlugin]}
              locales={[zhTWLocale]}
              googleCalendarApiKey="AIzaSyCOmMyvrucpOAQF6WEbKrxxRi7fKkcxkbs"
              events={{
                googleCalendarId: 'acrosschinese@gmail.com',
              }}
              initialView="listWeek"
              headerToolbar={
                window.screen.width < 768
                  ? {
                      start: 'title',
                      end: 'dayGridDay listWeek today prev next',
                    }
                  : {
                      start: 'title',
                      end: 'dayGridDay dayGridWeek dayGridMonth listWeek today prev next',
                    }
              }
            />
          </CalendarWrapper>
        </div>
      </StyledSection>
    </StyledContainer>
  )
}

export default ActivitySection
