import DefaultLayout from 'lodestar-app/src/components/layout/DefaultLayout'
import React from 'react'
import styled from 'styled-components'

const StyledSection = styled.section`
  .container {
    max-width: 38.75rem;
  }
  p {
    color: var(--gray-darker);
    margin-bottom: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`
const StyledCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  background-image: url('https://static.kolable.com/images/beaver/question-cover.jpg');
  background-size: cover;
  background-position: center;
  margin-bottom: 2.5rem;
`
const StyledBlock = styled.div`
  margin-bottom: 5rem;
`
const SectionTitle = styled.div`
  margin-bottom: 1.5rem;
  padding-left: 0.75rem;
  border-left: 4px solid #2c7eae;
  color: var(--gray-darker);

  div {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.23px;
    color: var(--gray-darker);
  }
`
const StyledQABlock = styled.div`
  margin-bottom: 1.75rem;
`
const StyledQuestion = styled.div`
  margin-bottom: 0.75rem;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #2c7eae;
`
const StyledAnswer = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: var(--gray-darker);
`

const QuestionPage: React.FC = () => {
  return (
    <DefaultLayout white>
      <StyledSection>
        <StyledCover>常見問題</StyledCover>
        <div className="container">
          <StyledBlock>
            <SectionTitle>
              <div>研習相關</div>
            </SectionTitle>
            <StyledQABlock>
              <StyledQuestion>Q. 如何邀請海貍老師辦理研習？</StyledQuestion>
              <StyledAnswer>
                請點選「聯絡我們」，讓貍們知道課程需求、研習人數以及希望辦理的時間，後續將由專貍與您聯絡，如果您有急需的話，請直接聯繫滾貍課程窗口Lily，即刻撥打研習專線0972-952-022，或加入LINE
                ID: lilyhuang1022，期待為您服務唷！
              </StyledAnswer>
            </StyledQABlock>
            <StyledQABlock>
              <StyledQuestion>Q. 參與培育可以申請教師研習時數嗎？</StyledQuestion>
              <StyledAnswer>
                目前還不行唷！貍們目前在朝這個方向努力中，未來若能申請研習時數，將隆重公告親貍好友，如果您有可協助的資源或管道，也請跟貍們聯絡，幫幫貍們。
              </StyledAnswer>
            </StyledQABlock>
            <StyledQABlock>
              <StyledQuestion>Q. 我無法參加完三天連續課程，那我還可以報名嗎？</StyledQuestion>
              <StyledAnswer>
                建議全程參與學習成效會比較好唷！貍們在課程設計上，三天的內容將呈現系統性的教學脈絡，並且都會配搭固定分組實作來進行，像是拼圖一樣，少了一塊會很可惜的喔～QAQ
              </StyledAnswer>
            </StyledQABlock>
            <StyledQABlock>
              <StyledQuestion>Q. 我有五位夥伴想一起報四人揪團方案，第五個人也適用優惠嗎？</StyledQuestion>
              <StyledAnswer>
                因官網收費系統自動化，四人方案需要符合一定人數才可以唷！不過偷偷告訴您，依據往年的經驗，在臉書留言揪團的成功機率很高唷！快點起身再揪三個夥伴～
              </StyledAnswer>
            </StyledQABlock>
            <StyledQABlock>
              <StyledQuestion>Q. 同一個課程但不同地點也可以用揪團方案報名嗎？</StyledQuestion>
              <StyledAnswer>
                不同地點的話會視為不同場次唷！建議大家能呼朋引伴至同一個地點上課，滾貍的課程場場超值，搭高鐵來都划算，如需各地推薦住宿的話也歡迎跟貍們聯絡唷：）
              </StyledAnswer>
            </StyledQABlock>
            <StyledQABlock>
              <StyledQuestion>Q. 我不喜歡實作，可以坐在角落旁聽就好嗎？</StyledQuestion>
              <StyledAnswer>
                在滾貍可是沒有邊緣人的唷！比起單向的教學知能接收，貍們更期待大家能在實作中驗證學習啟發。就讓貍們為來研習的夥伴，搭建共學的橋樑吧！
                如果參與實作有感受到不適之處，也可以在現場反應，貍們會盡全力為你排解，絕對可以安心增能唷！
              </StyledAnswer>
            </StyledQABlock>
          </StyledBlock>
          <StyledBlock>
            <SectionTitle>
              <div>商品付款及寄送相關</div>
            </SectionTitle>
            <StyledQABlock>
              <StyledQuestion>Q. 付費方式只接受線上刷卡嗎？</StyledQuestion>
              <StyledAnswer>
                除了線上刷卡外，還能選擇線上轉帳、或是給予虛擬帳號做為ATM轉帳用唷！想要來位於南港的工作室面交，順便摸摸貓貓也可以，選擇多多，只怕您不買XD
              </StyledAnswer>
            </StyledQABlock>
            <StyledQABlock>
              <StyledQuestion>Q. 產品訂購後大約多久可以收到呢？</StyledQuestion>
              <StyledAnswer>
                現貨商品在收到款項後將於三個工作天內寄出，如有特殊需求請事先告知，預購商品出貨日期請參閱該商品的說明，若遲未收到訂購商品也請與貍們聯絡，貍們將在第一時間為您查詢物流資訊唷！
              </StyledAnswer>
            </StyledQABlock>
          </StyledBlock>
          <StyledBlock>
            <SectionTitle>
              <div>其他</div>
            </SectionTitle>
            <StyledQABlock>
              <StyledQuestion>Q. 我不是教師，也可以參與貍想教育創新學院嗎？</StyledQuestion>
              <StyledAnswer>
                當然可以呀！目前有越來越多非教育工作者參與，歡迎對教育創變有興趣、想成為終身學習者的夥伴加入，如果您想獲得學院的詳細資訊，可隨時與貍們聯繫，將有專貍與您一對一說明。每年的二月、九月將在官網公告相關訊息，有意參與的夥伴也可以留意FB粉專及官方LINE，等您一起共學成長，共創貍想的生活唷！
              </StyledAnswer>
            </StyledQABlock>
          </StyledBlock>
        </div>
      </StyledSection>
    </DefaultLayout>
  )
}

export default QuestionPage
