import React from 'react'
import styled from 'styled-components'
import { SectionID } from './index.styled'

const StyledBar = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 48px;
  overflow: hidden;
  background: white;
  text-align: center;
  z-index: 1;
`
const StyledWrapper = styled.div<{ position?: 'right' }>`
  position: absolute;
  width: auto;
  ${props => (props.position === 'right' ? 'right: 0;' : 'left: 0;')}
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  @media (min-width: 424px) {
    position: relative;
    margin: 0 auto;
  }
`
const StyledItem = styled.div<{ variant?: 'active' }>`
  display: inline-block;
  padding: 12px 10px;
  color: ${props => (props.variant === 'active' ? '#2c7eae' : 'var(--gray-darker)')};
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #2c7eae;
  }
`

const sections: {
  id: SectionID
  title: string
}[] = [
  {
    id: 'introduction',
    title: '海貍之名',
  },
  {
    id: 'destiny',
    title: '海貍使命',
  },
  {
    id: 'footprint',
    title: '海貍足跡',
  },
  {
    id: 'teamMember',
    title: '海貍團隊',
  },
  {
    id: 'feedback',
    title: '現場迴響',
  },
]

const NavigationBar: React.FC<{
  currentSectionId?: SectionID | null
  onScrollTo?: (sectionId: SectionID) => void
}> = ({ currentSectionId, onScrollTo }) => {
  return (
    <StyledBar>
      <StyledWrapper
        position={currentSectionId === 'teamMember' || currentSectionId === 'feedback' ? 'right' : undefined}
      >
        {sections.map(section => (
          <StyledItem
            key={section.id}
            variant={currentSectionId === section.id ? 'active' : undefined}
            onClick={() => onScrollTo?.(section.id)}
          >
            {section.title}
          </StyledItem>
        ))}
      </StyledWrapper>
    </StyledBar>
  )
}

export default NavigationBar
