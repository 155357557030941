import { Icon } from '@chakra-ui/react'
import { BREAK_POINT } from 'lodestar-app/src/components/common/Responsive'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as EducatorIcon } from '../../images/exploration-educator.svg'
import { ReactComponent as EntrepreneurIcon } from '../../images/exploration-entrepreneur.svg'
import { ReactComponent as LearnerIcon } from '../../images/exploration-learner.svg'
import { SectionID } from './index.styled'

const StyledBarWrapper = styled.div`
  min-height: 104px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 410px;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledIcon = styled(Icon)<{ variant?: string | null }>`
  g {
    transition: all 0.2s ease-in-out;
    ${props => props.variant === 'active' && `fill:white`}
  }
  margin-bottom: 5px;
`
const StyledBar = styled.div<{ variant?: 'active'; color: string }>`
  align-self: stretch;
  border-radius: 4px;
  background-color: ${props => (props.variant === 'active' ? props.color : '#f9f8f9')};
  padding: 16px 21px;
  color: ${props => (props.variant === 'active' ? 'white' : 'var(--gray-darker)')};
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.2px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
  flex: 0 0 31%;
  ${props =>
    props.variant !== 'active' &&
    `
  &:hover {
    ${StyledIcon} {
      g {
        fill: ${props.color};
      }
    }
    color: ${props.color};
  }
  `};
  @media (min-width: ${BREAK_POINT}px) {
    flex: 0 0 33.333%;
    &:not(:first-child) {
      margin-left: 0.75rem;
    }
  }
`

const sections: {
  id: SectionID
  title: string
  icon: React.FunctionComponent
  color: string
}[] = [
  {
    id: 'learner',
    title: '學習者',
    icon: LearnerIcon,
    color: '#2c7eae',
  },
  {
    id: 'educator',
    title: '教育工作者',
    icon: EducatorIcon,
    color: '#bf804a',
  },
  {
    id: 'entrepreneur',
    title: '教育創業者',
    icon: EntrepreneurIcon,
    color: '#ffbe1e',
  },
]

const NavigationBar: React.FC<{
  currentSectionId?: SectionID | null
  onChange?: (sectionId: SectionID) => void
}> = ({ currentSectionId, onChange }) => {
  return (
    <StyledBarWrapper>
      {sections.map(section => (
        <StyledBar
          key={section.id}
          variant={currentSectionId === section.id ? 'active' : undefined}
          color={section.color}
          onClick={() => onChange?.(section.id)}
        >
          <div className="d-flex justify-content-center">
            <StyledIcon as={section.icon} variant={currentSectionId === section.id ? 'active' : undefined} />
          </div>
          {section.title}
        </StyledBar>
      ))}
    </StyledBarWrapper>
  )
}

export default NavigationBar
