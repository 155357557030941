import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { throttle } from 'lodash'
import { desktopViewMixin, rgba } from 'lodestar-app/src/helpers'
import { ReactComponent as TimesIcon } from 'lodestar-app/src/images/times.svg'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ReactComponent as Arrow1 } from '../../images/arrow-1.svg'
import { ReactComponent as Arrow2 } from '../../images/arrow-2.svg'
import { ReactComponent as Arrow3 } from '../../images/arrow-3.svg'
import { ReactComponent as BookIcon } from '../../images/book.svg'
import { ReactComponent as GrowthIcon } from '../../images/growth.svg'
import { ReactComponent as ShareIcon } from '../../images/share.svg'
import { SectionTitle } from './index.styled'

const scaleInCenter = (transformStyle?: string) => keyframes`
 0% {
    transform: ${transformStyle} scale(0);
   opacity: 1;
 }
 100% {
    transform: ${transformStyle} scale(1);
   opacity: 1;
  }
`
const StyledSection = styled.section`
  position: relative;
  padding: 5rem 0 0 0;
  background: #fdf5ec;
`
const StyledContainer = styled.div`
  max-width: 760px;
`
const StyledSectionTitle = styled(SectionTitle)`
  ${desktopViewMixin(css`
    text-align: left;
  `)}
`
const StyledDescription = styled.div`
  margin-bottom: 2rem;
  color: var(--gray-darker);
  font-weight: 500;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: justify;
`
const StyledWrapper = styled.div<{ scale?: number }>`
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 512px;
    height: 403px;
    transform-origin: top left;
    ${props => (props.scale ? `transform: scale(${props.scale})` : '')}
  }
`
const StyledCircle = styled.div<{ variant: 'book' | 'growth' | 'share'; transformStyle?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 148px;
  height: 148px;
  border-radius: 50%;
  background-color: ${props =>
    props.variant === 'book' ? '#bf804a' : props.variant === 'growth' ? '#ffbe1e' : '#f29844'};
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: center;
  cursor: pointer;
  box-shadow: 1px 3px 9px 0
    ${props =>
      props.variant === 'book'
        ? rgba('#bf804a', 0.2)
        : props.variant === 'growth'
        ? rgba('#ffbe1e', 0.2)
        : rgba('#2c7eae', 0.2)};
  &.scale-in-center {
    animation: ${props => scaleInCenter(props.transformStyle)} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
`
const StyledArrow = styled.div<{ position: 'left' | 'bottom' | 'right' }>`
  position: absolute;
  ${props =>
    props.position === 'left'
      ? css`
          top: 142px;
          left: 122px;
        `
      : props.position === 'bottom'
      ? css`
          bottom: 64px;
          left: 50%;
          transform: translateX(-50%);
        `
      : css`
          top: 142px;
          right: 122px;
        `}
`
const StyledSlogan = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--gray-darker);
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.23px;
  text-align: center;
`
const StyledModalContent = styled(ModalContent)`
  && {
    position: relative;
    margin: 0 1rem;
    padding: 5rem 2rem 2rem;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  ${StyledCircle} {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
const StyledModalBody = styled(ModalBody)`
  color: #3a3f47;
  line-height: 1.69;
  letter-spacing: 0.2px;
`
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: #cdcdcd;
  font-size: 20px;
  cursor: pointer;
`
const OverflowBeaver = styled.img`
  width: 260px;
  position: absolute;
  top: -180px;
  right: 92px;
  @media screen and (max-width: 768px) {
    width: 160px;
    top: -112px;
    right: 0;
  }
`

const ExplorationSection: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [scale, setScale] = useState(1)
  const [selectedModal, setSelectedModal] = useState<'book' | 'growth' | 'share' | null>(null)
  const [isAnimationActive, setIsAnimationActive] = useState(false)

  useEffect(() => {
    wrapperRef.current && setScale(wrapperRef.current.clientWidth / 512)
    const handleResizeThrottle = throttle(() => {
      wrapperRef.current && setScale(wrapperRef.current.clientWidth / 512)
    }, 200)
    const handleResize = () => handleResizeThrottle()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const layoutContentElem = document.querySelector('#layout-content')

    const handleScrollThrottle = throttle(() => {
      const currentScrollBottom = (layoutContentElem?.scrollTop || 0) + (layoutContentElem?.clientHeight || 0)
      const containerBottom = (containerRef.current?.offsetTop || 0) + (containerRef.current?.clientHeight || 0)
      const currentScrollTop = layoutContentElem?.scrollTop || 0
      const containerTop = containerRef.current?.offsetTop || 0

      if (!isAnimationActive && currentScrollBottom > containerBottom && currentScrollTop < containerBottom) {
        setIsAnimationActive(true)
      }
      if (isAnimationActive && (currentScrollTop > containerBottom || currentScrollBottom < containerTop)) {
        setIsAnimationActive(false)
      }
    }, 200)
    const handleScroll = () => handleScrollThrottle()

    layoutContentElem?.addEventListener('scroll', handleScroll)

    return () => {
      layoutContentElem?.removeEventListener('scroll', handleScroll)
    }
  }, [isAnimationActive])
  return (
    <StyledSection>
      <OverflowBeaver src="https://static.kolable.com/images/beaver/beaver-03.png" alt="overflow-beaver" />
      <StyledContainer className="container" ref={containerRef}>
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-4 pr-4">
            <StyledSectionTitle className="mb-3">完善的支持系統</StyledSectionTitle>
            <StyledDescription>
              作為未來教育先行者，貍想教育從文學、教學、共學三方面支持教育工作者成為教育創變者。
            </StyledDescription>
          </div>
          <div className="col-12 col-lg-8">
            <StyledWrapper ref={wrapperRef} scale={scale}>
              <div>
                <StyledCircle
                  variant="book"
                  style={{ top: '4px', left: '50%', transform: 'translateX(-50%)' }}
                  onClick={() => setSelectedModal('book')}
                  className={isAnimationActive ? 'scale-in-center' : ''}
                  transformStyle={'translateX(-50%)'}
                >
                  <div>
                    <BookIcon className="mb-1" />
                    <div>文學</div>
                  </div>
                </StyledCircle>
                <StyledCircle
                  variant="growth"
                  style={{ left: '4px', bottom: '4px' }}
                  onClick={() => setSelectedModal('growth')}
                  className={isAnimationActive ? 'scale-in-center' : ''}
                >
                  <div>
                    <GrowthIcon className="mb-1" />
                    <div>教學</div>
                  </div>
                </StyledCircle>
                <StyledCircle
                  variant="share"
                  style={{ right: '4px', bottom: '4px' }}
                  onClick={() => setSelectedModal('share')}
                  className={isAnimationActive ? 'scale-in-center' : ''}
                >
                  <div>
                    <ShareIcon className="mb-1" />
                    <div>共學</div>
                  </div>
                </StyledCircle>
                <StyledArrow position="left">
                  <Arrow1 />
                </StyledArrow>
                <StyledArrow position="bottom">
                  <Arrow2 />
                </StyledArrow>
                <StyledArrow position="right">
                  <Arrow3 />
                </StyledArrow>
                <StyledSlogan>
                  <div>教育創變者</div>
                </StyledSlogan>
              </div>
            </StyledWrapper>
          </div>
        </div>
      </StyledContainer>

      <Modal isCentered isOpen={!!selectedModal} onClose={() => setSelectedModal(null)}>
        <ModalOverlay />
        <StyledModalContent>
          {selectedModal && (
            <StyledCircle variant={selectedModal}>
              {selectedModal === 'book' ? (
                <div>
                  <BookIcon className="mb-1" />
                  <div>文學</div>
                </div>
              ) : selectedModal === 'growth' ? (
                <div>
                  <GrowthIcon className="mb-1" />
                  <div>教學</div>
                </div>
              ) : (
                <div>
                  <ShareIcon className="mb-1" />
                  <div>共學</div>
                </div>
              )}
            </StyledCircle>
          )}

          <CloseButtonWrapper onClick={() => setSelectedModal(null)}>
            <TimesIcon />
          </CloseButtonWrapper>

          <StyledModalBody>
            {selectedModal === 'book' ? (
              <ul>
                <li>築壩月讀共建訂閱專案</li>
                <li>國中思維建構學習單、不插電教案、 讀寫結合學習單</li>
                <li>高中思維工程學習單</li>
                <li>《口語表達》專書</li>
                <li>《河道式作文寫作指南》專書</li>
                <li>《生而為人的這些那些》文化教材讀本</li>
              </ul>
            ) : selectedModal === 'growth' ? (
              <ul>
                <li>入校增能研習</li>
                <li>寒暑假工作坊</li>
                <li>貍想教育概念影片</li>
                <li>自創課堂桌遊：《海貍戰隊》、《尾巴巴呢》</li>
                <li>《貍想誌》教學雜誌</li>
                <li>《這輩子還是國文老師》教育散文集</li>
                <li>《下輩子別當老師》教育散文集</li>
              </ul>
            ) : (
              <ul>
                <li>貍想教育創新學院</li>
                <li>青色組織工作坊</li>
                <li>貍想教育節</li>
                <li>各校共學社群</li>
                <li>《思考如何思考》思維專書</li>
              </ul>
            )}
          </StyledModalBody>
        </StyledModalContent>
      </Modal>
    </StyledSection>
  )
}

export default ExplorationSection
