import { useApp } from 'lodestar-app-element/src/contexts/AppContext'
import { desktopViewMixin } from 'lodestar-app/src/helpers'
import { ReactComponent as AngleLeftIcon } from 'lodestar-app/src/images/angle-left.svg'
import { ReactComponent as AngleRightIcon } from 'lodestar-app/src/images/angle-right.svg'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'

const StyledSection = styled.section`
  margin-bottom: 5rem;
  ${desktopViewMixin(css`
    margin-bottom: 0;
  `)}
`
const StyledImageBlock = styled.div<{ mobileSrc?: string; desktopSrc?: string }>`
  padding-top: 96%;
  background-image: url('${props => props.mobileSrc}');
  background-size: cover;
  background-position: center;
  cursor: pointer;

  ${props =>
    props.desktopSrc &&
    desktopViewMixin(css`
      padding-top: calc(100% / 3);
      background-image: url('${props.desktopSrc}');
    `)}
`
const StyledArrow = styled.div<{ variant: 'left' | 'right' }>`
  && {
    ${props => (props.variant === 'left' ? 'left: 12px;' : 'right: 12px;')}
    height: auto;
    width: auto;
    font-size: 40px;
    color: #ececec;
    z-index: 1;

    ::before {
      content: '';
    }
  }
`

const SliderArrow: React.VFC<{
  variant: 'left' | 'right'
}> = props => {
  return <StyledArrow {...props}>{props.variant === 'left' ? <AngleLeftIcon /> : <AngleRightIcon />}</StyledArrow>
}

const ProjectBanner: React.VFC = () => {
  const history = useHistory()
  const { settings } = useApp()

  const slides: {
    link: string
    isExternal?: boolean
    mobile: string
    desktop: string
  }[] = (() => {
    try {
      return JSON.parse(settings['homepage_cover_slides'])
    } catch {
      return []
    }
  })()

  return (
    <StyledSection>
      <Slider
        dots={false}
        autoplay
        autoplaySpeed={5000}
        prevArrow={<SliderArrow variant="left" />}
        nextArrow={<SliderArrow variant="right" />}
      >
        {slides.map(slide => (
          <StyledImageBlock
            key={slide.mobile}
            mobileSrc={slide.mobile}
            onClick={() => (slide.isExternal ? window.open(slide.link) : history.push(slide.link))}
          />
        ))}
      </Slider>
    </StyledSection>
  )
}

export default ProjectBanner
